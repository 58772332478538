import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {SkeletonTheme} from 'react-loading-skeleton'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import useDarkMode from 'use-dark-mode'

import Footer from './components/Footer'
import Header from './components/Header'
import Page from './components/Page/index'
import Activity from './pages/Activity/index'
import AdvancedSearch from './pages/AdvancedSearch/AdvancedSearch'
import Collection from './pages/CollectionPage/index'
import ConnectWallet from './pages/ConnectWallet/index'
import CreateItem from './pages/CreateItem/CreateItem'
import Gallery from './pages/Gallery/Gallery'
import Home from './pages/Home/index'
import Item from './pages/Item/index'
import NoResultsFound from './pages/NoResultsFound/NoResultsFound'
import Profile from './pages/Profile/index'
import ProfileEdit from './pages/ProfileEdit/index'
import UploadVariants from './pages/UploadVariants/index'
import Connector from './services/walletConnect'
import {Provider, rootStore} from './store/store'

import './styles/app.scss'

function App(): JSX.Element {
  const {value: isDarkMode} = useDarkMode(true)
  return (
    <Provider value={rootStore}>
      <SkeletonTheme
        baseColor={isDarkMode ? '#353945' : '#e6e8ec'}
        highlightColor={isDarkMode ? '#777e91' : '#f4f5f6'}>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LfRLJscAAAAAEsnpB4vjDK-ZZABY6blkCiXk49v"
          language="en">
          <Router>
            <Connector>
              <Page />
              <Header />
              <div className="content">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route
                    path="/upload-variants"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <UploadVariants />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route
                    path="/create-item-single"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <CreateItem isSingle />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route
                    path="/create-item-multiple"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <CreateItem isSingle={false} />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route path="/connect-wallet" component={ConnectWallet} />
                  <Route
                    path="/activity"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <Activity />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route path={['/profile/:userId/:tab', '/profile/:userId']} component={Profile} />
                  <Route
                    path="/profile-edit"
                    render={() => {
                      return localStorage.kephi_nft_token ? (
                        <ProfileEdit />
                      ) : (
                        <Redirect to="/connect-wallet" />
                      )
                    }}
                  />
                  <Route
                    path="/game"
                    component={() => {
                      window.location.href = 'https://play.kephi.io/'
                      return null
                    }}
                  />
                  <Route path="/item/:itemId" component={Item} />
                  <Route path="/gallery/:galleryId" component={Gallery} />
                  <Route path="/collection/:collectionId" component={Collection} />
                  <Route path="/advanced-search" component={AdvancedSearch} />
                  <Route path="/no-results-found" component={NoResultsFound} />
                </Switch>
              </div>
              <Footer />
            </Connector>
          </Router>
        </GoogleReCaptchaProvider>
      </SkeletonTheme>
    </Provider>
  )
}

export default App
