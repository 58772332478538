import {initReactI18next} from 'react-i18next'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'

import translationEn from '../locales/en/translation.json'
import translationEs from '../locales/es/translation.json'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
      es: {
        translation: translationEs,
      },
    },
    fallbackLng: 'es',
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
