/* eslint-disable no-nested-ternary */
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import verifyImg from '../../../assets/img/icons/verify.svg'
import {ITokenDetails} from '../../../types'
import {IHistoryItem} from '../../../typings/Item'
import {IBaseInfo, IOwner} from '../../../typings/UserInfo'
import {getTokenAddressURL} from '../../../utils/blockchain'
import {formatDate} from '../../../utils/date'
import {shorterAddress} from '../../../utils/shorterAddress'

import styles from './Users.module.scss'

interface IUsersProps {
  className?: string
  // items: Array<{ name: string; position: string; avatar: string; reward: string }>;
  owners: any
  creator: IBaseInfo | undefined
  history?: IHistoryItem[]
  bids?: any
  details?: ITokenDetails
  royalty?: number
  collection?: any
  standart?: string
}

const Users: React.FC<IUsersProps> = ({
  className,
  owners,
  creator,
  history,
  bids,
  details,
  collection,
  standart,
}) => {
  const [properties, setProperties] = useState<[string, string][]>([])
  const {t} = useTranslation()

  useEffect(() => {
    if (!!details && !!details.details) setProperties(Object.entries(details.details))
  }, [details])

  return (
    <div className={classNames(styles.users, className)}>
      <div className={styles.list}>
        {owners &&
          (owners.length ? (
            owners.map((owner: IOwner) => (
              <Link to={`/profile/${owner ? owner.id : ''}`} key={nextId()}>
                <div className={styles.item}>
                  <div className={styles.avatar}>
                    <img
                      src={owner ? owner.avatar : ''}
                      className={styles.avatarImg}
                      alt="Avatar"
                    />
                    <div className={styles.reward}>
                      <img src="/images/content/reward-1.svg" alt="Reward" />
                    </div>
                  </div>
                  <div className={styles.details}>
                    <div className={styles.position}>{t('token.owner')}</div>
                    <div className={styles.name}>{owner ? shorterAddress(owner.name) : ''}</div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <Link
              to={`/profile/${owners ? owners.id : ''}`}
              className={styles.item_link}
              key={nextId()}>
              <div className={styles.item} key={nextId()}>
                <div className={styles.avatar}>
                  <img
                    src={owners ? owners.avatar : ''}
                    className={styles.avatarImg}
                    alt="Avatar"
                  />
                  <div className={styles.reward}>
                    <img src="/images/content/reward-1.svg" alt="Reward" />
                  </div>
                </div>
                <div className={styles.details}>
                  <div className={styles.position}>{t('token.owner')}</div>
                  <div className={styles.name}>{owners ? shorterAddress(owners.name) : ''}</div>
                </div>
              </div>
            </Link>
          ))}
        {creator && (
          <Link to={`/profile/${creator?.id}`}>
            <div className={styles.item} key={nextId()}>
              <div className={styles.avatar}>
                <img src={creator?.avatar} className={styles.avatarImg} alt="Avatar" />
                {creator.is_verificated ? (
                  <img src={verifyImg} className={styles.verify} alt="" />
                ) : null}
              </div>
              <div className={styles.details}>
                <div className={styles.position}>{t('token.creator')}</div>
                <div className={styles.name}>{shorterAddress(creator?.name)}</div>
              </div>
            </div>
          </Link>
        )}
        {collection && (
          <>
            <Link to={`/collection/${collection?.id}`}>
              <div className={styles.item} key={nextId()}>
                <div className={styles.avatar}>
                  <img src={collection.avatar} className={styles.avatarImg} alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.position}>{standart}</div>
                  <div className={styles.name}>{collection.name}</div>
                </div>
              </div>
            </Link>
          </>
        )}
        {history &&
          history.map((item: IHistoryItem) => (
            <Link to={`/profile/${item.id}`} key={nextId()}>
              <div className={styles.item}>
                <div className={styles.avatar}>
                  <img src={item.avatar} className={styles.avatarImg} alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={classNames(styles.position, styles.ellipsisLabel)}>
                    {t(`method.${item.method?.toLowerCase()}`)}
                  </div>
                  <div className={classNames(styles.name, styles.ellipsisLabel)}>
                    {shorterAddress(item.name, 4)}
                  </div>
                </div>
                <div className={styles.extraDetails}>
                  <div className={styles.detail}>
                    <div className={styles.position}>{t('price')}</div>
                    <div className={styles.name}>
                      {(item.price && `U$S ${(+item.price).toFixed(2)}`) || '-'}
                    </div>
                  </div>
                  <div className={classNames(styles.detail, styles.amountColumn)}>
                    <div className={styles.position}>{t('tokensLabel')}</div>
                    <div className={styles.name}>{item.amount || '-'}</div>
                  </div>
                  <div className={classNames(styles.detail, styles.dateColumn)}>
                    <div className={styles.position}>{t('tradeDate')}</div>
                    <div className={styles.name}>{formatDate(item.date) || '-'}</div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        {bids &&
          bids.map((bid: any) => (
            <Link to={`/profile/${bid.bidder_id}`} key={nextId()}>
              <div className={styles.item} key={nextId()}>
                <div className={styles.avatar}>
                  <img src={bid.bidder_avatar} className={styles.avatarImg} alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.position}>
                    {(+bid.amount).toFixed(3)} {bid.currency.symbol.toUpperCase()}
                  </div>
                  <div className={styles.name}>{bid.bidder}</div>
                </div>
              </div>
            </Link>
          ))}
        {details && (
          <>
            <a
              href={getTokenAddressURL(details.blockchain, details.token_contract)}
              target="_blank"
              rel="noopener noreferrer">
              <div className={styles.item}>
                <div className={styles.itemDetails}>
                  <div className={styles.position}>{t('contractAddress')}</div>
                  <div className={styles.name}>{shorterAddress(details.token_contract)}</div>
                </div>
              </div>
            </a>
            <div className={styles.item}>
              <div className={styles.itemDetails}>
                <div className={styles.position}>{t('tokenID')}</div>
                <div className={styles.name}>{details.token_id}</div>
              </div>
            </div>
            <div className={classNames(styles.item, !properties?.length && styles.noBorderBottom)}>
              <div className={styles.itemDetails}>
                <div className={styles.position}>{t('blockchain')}</div>
                <div className={styles.name}>{details.blockchain}</div>
              </div>
            </div>
            {!!properties.length && (
              <div className={styles.item}>
                <div className={styles.itemDetails}>
                  <div className={styles.position}>{t('properties')}</div>
                  <div className={styles.properties}>
                    {properties.map(property => (
                      <div key={nextId()} className={styles.property}>
                        <div className={styles.key}>{property[0] || ''}</div>
                        <div className={styles.value}>{property[1] || ''}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Users
