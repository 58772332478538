export default [
  {inputs: [], stateMutability: 'nonpayable', type: 'constructor'},
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'seller',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        indexed: false,
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'sellToken',
        type: 'tuple',
      },
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        indexed: false,
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'buyToken',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'address[]',
        name: 'feeAddresses',
        type: 'address[]',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'feeAmounts',
        type: 'uint256[]',
      },
    ],
    name: 'ExchangeMadeErc1155',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'seller',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        indexed: false,
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'sellToken',
        type: 'tuple',
      },
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        indexed: false,
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'buyToken',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'address[]',
        name: 'feeAddresses',
        type: 'address[]',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'feeAmounts',
        type: 'uint256[]',
      },
    ],
    name: 'ExchangeMadeErc721',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'previousAdminRole',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'newAdminRole',
        type: 'bytes32',
      },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'SIGNER_ROLE',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{internalType: 'bytes32', name: 'role', type: 'bytes32'}],
    name: 'getRoleAdmin',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'account', type: 'address'},
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'account', type: 'address'},
    ],
    name: 'hasRole',
    outputs: [{internalType: 'bool', name: '', type: 'bool'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'idOrder', type: 'bytes32'},
      {internalType: 'address[2]', name: 'SellerBuyer', type: 'address[2]'},
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'tokenToBuy',
        type: 'tuple',
      },
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'tokenToSell',
        type: 'tuple',
      },
      {internalType: 'address[]', name: 'feeAddresses', type: 'address[]'},
      {internalType: 'uint256[]', name: 'feeAmounts', type: 'uint256[]'},
      {internalType: 'bytes', name: 'signature', type: 'bytes'},
    ],
    name: 'makeExchangeERC1155',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'idOrder', type: 'bytes32'},
      {internalType: 'address[2]', name: 'SellerBuyer', type: 'address[2]'},
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'tokenToBuy',
        type: 'tuple',
      },
      {
        components: [
          {internalType: 'address', name: 'tokenAddress', type: 'address'},
          {internalType: 'uint256', name: 'id', type: 'uint256'},
          {internalType: 'uint256', name: 'amount', type: 'uint256'},
        ],
        internalType: 'struct Exchange.NftTokenInfo',
        name: 'tokenToSell',
        type: 'tuple',
      },
      {internalType: 'address[]', name: 'feeAddresses', type: 'address[]'},
      {internalType: 'uint256[]', name: 'feeAmounts', type: 'uint256[]'},
      {internalType: 'bytes', name: 'signature', type: 'bytes'},
    ],
    name: 'makeExchangeERC721',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: '', type: 'address'},
      {internalType: 'address', name: '', type: 'address'},
      {internalType: 'uint256[]', name: '', type: 'uint256[]'},
      {internalType: 'uint256[]', name: '', type: 'uint256[]'},
      {internalType: 'bytes', name: '', type: 'bytes'},
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{internalType: 'bytes4', name: '', type: 'bytes4'}],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: '', type: 'address'},
      {internalType: 'address', name: '', type: 'address'},
      {internalType: 'uint256', name: '', type: 'uint256'},
      {internalType: 'uint256', name: '', type: 'uint256'},
      {internalType: 'bytes', name: '', type: 'bytes'},
    ],
    name: 'onERC1155Received',
    outputs: [{internalType: 'bytes4', name: '', type: 'bytes4'}],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: '', type: 'address'},
      {internalType: 'address', name: '', type: 'address'},
      {internalType: 'uint256', name: '', type: 'uint256'},
      {internalType: 'bytes', name: '', type: 'bytes'},
    ],
    name: 'onERC721Received',
    outputs: [{internalType: 'bytes4', name: '', type: 'bytes4'}],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'account', type: 'address'},
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'account', type: 'address'},
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'}],
    name: 'supportsInterface',
    outputs: [{internalType: 'bool', name: '', type: 'bool'}],
    stateMutability: 'view',
    type: 'function',
  },
]
