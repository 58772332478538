import {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {message} from 'antd'
import cn from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import Actions from '../../../components/Actions'
import Icon from '../../../components/Icon'
import {userApi} from '../../../services/api'

import styles from './Options.module.scss'

interface IOptionsProps {
  className?: string
  isLiked: boolean
  itemId?: string
  standart?: string
  isMayRemoveFromSale: boolean
  isOwner: boolean
  isAuction: boolean
  removeFromSale: () => Promise<any>
  isWrongChain: boolean
  amount: number
  name?: string
  creatorName?: string
  updateTokenData: () => void
}

const Options: React.FC<IOptionsProps> = ({
  className,
  isLiked,
  itemId,
  standart,
  isMayRemoveFromSale,
  isAuction,
  isOwner,
  removeFromSale,
  isWrongChain,
  amount,
  name,
  creatorName,
  updateTokenData,
}) => {
  const [isLike, setIsLike] = useState(isLiked)
  const history = useHistory()
  const handleClose = () => {
    history.goBack()
  }
  const fetchLike = useCallback(() => {
    userApi.like({id: itemId ? +itemId : 0})
  }, [itemId])
  useEffect(() => setIsLike(isLiked), [isLiked])

  const handleCopy = () => {
    copyToClipboard(window.location.toString())
    message.info('Copied to clipboard')
  }

  return (
    <div className={cn(styles.options, className)}>
      <button
        type="button"
        className={cn('button-circle-stroke', styles.button)}
        onClick={handleClose}>
        <Icon name="close" size="14" />
      </button>
      <button
        type="button"
        className={cn(
          'button-circle-stroke',
          isLiked && 'button-circle-stroke-active',
          styles.button,
        )}
        onClick={handleCopy}>
        <Icon name="share" size="24" />
      </button>
      {!!localStorage.kephi_nft_chainName && (
        <button
          type="button"
          className={cn('button-circle-stroke', styles.button, {
            [styles.favorite]: isLike,
          })}
          onClick={() => {
            setIsLike(!isLike)
            fetchLike()
          }}>
          <Icon name="heart-fill" size="24" />
        </button>
      )}
      <Actions
        className={styles.actions}
        isAuction={isAuction}
        standart={standart}
        itemId={itemId}
        isMayRemoveFromSale={isMayRemoveFromSale}
        isOwner={isOwner}
        removeFromSale={removeFromSale}
        isWrongChain={isWrongChain}
        amount={amount}
        tokenName={name}
        creatorName={creatorName}
        updateTokenData={updateTokenData}
      />
    </div>
  )
}

export default Options
