import galleryStyles from '../pages/Gallery/Gallery.module.scss'
import ourGalleriesStyles from '../pages/Home/OurGalleries/OurGalleries.module.scss'

export const ourGalleries = [
  {
    name: 'zurbaranGallery',
    type: 'home.ourGalleries.curatedBy',
    logoUrl: '/images/logos/zurbaran.svg',
    className: ourGalleriesStyles.zurbaranLogo,
    isComingSoon: false,
  },
  {
    name: 'kephiGallery',
    type: 'home.ourGalleries.curatedBy',
    logoUrl: '/images/kephi-logo-light.svg',
    className: ourGalleriesStyles.kephiLogo,
  },
  {
    name: 'openGallery',
    label: 'home.ourGalleries.openGallery',
    type: 'home.ourGalleries.democraticArt',
  },
]

export const galleries = [
  {
    id: 'zurbaranGallery',
    key: 'CURATED_ZURBARAN',
    name: 'Zurbaran',
    type: 'home.ourGalleries.curatedBy',
    logoUrl: '/images/logos/zurbaran.svg',
    className: galleryStyles.zurbaranLogo,
  },
  {
    id: 'kephiGallery',
    key: 'CURATED_KEPHI',
    name: 'Kephi Gallery',
    type: 'home.ourGalleries.curatedBy',
    logoUrl: '/images/kephi-logo-light.svg',
    className: galleryStyles.kephiLogo,
  },
  {
    id: 'openGallery',
    key: 'OPEN',
    name: 'Open Gallery',
    label: 'home.ourGalleries.openGallery',
    type: 'home.ourGalleries.democraticArt',
    className: galleryStyles.openGallery,
  },
]
