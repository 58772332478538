import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import collectionStyles from '../../../../components/Collection/Collection.module.scss'

const HOT_COLLLECTIONS_MIN_LENGTH = 3

const hotCollectionsSkeletons = Array.from(
  {length: HOT_COLLLECTIONS_MIN_LENGTH},
  (_value, index) => index,
)
const tokensSkeletons = Array.from({length: HOT_COLLLECTIONS_MIN_LENGTH}, (_value, index) => index)

export const CollectionSkeleton = ({className = ''}) => (
  <div className={className}>
    <div className={classNames(collectionStyles.gallerySkeleton)}>
      <Skeleton
        className={classNames(collectionStyles.preview, collectionStyles.previewSkeleton)}
      />
      <div className={collectionStyles.tokenSkeletonWrapper}>
        {tokensSkeletons.map(tokenSkeleton => (
          <div key={tokenSkeleton} className={classNames(collectionStyles.tokenSkeletonCard)}>
            <Skeleton className={classNames(collectionStyles.tokenSkeleton)} key={tokenSkeleton} />
          </div>
        ))}
      </div>
    </div>
    <Skeleton className={collectionStyles.subtitle} width={130} height={25} />
    <div className={collectionStyles.line}>
      <div className={collectionStyles.user}>
        <Skeleton className={collectionStyles.avatar} circle />
        <Skeleton className={collectionStyles.author} width={120} height={20} />
      </div>
      <Skeleton className={collectionStyles.collectionItemCounter} width={80} height={25} />
    </div>
  </div>
)

const CollectionsSkeleton = () => {
  return hotCollectionsSkeletons.map(hotCollectionSkeleton => (
    <div key={hotCollectionSkeleton}>
      <CollectionSkeleton />
    </div>
  ))
}

export default CollectionsSkeleton
