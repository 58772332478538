import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import {NavHashLink} from 'react-router-hash-link'
import cn from 'classnames'

import Card from '../../../components/Card'
import CardSkeleton from '../../../components/Card/Skeleton/CardSkeleton'
import {PROFILE_ITEMS_PER_PAGE, PROFILE_TAB_INDEX} from '../../../constants/constants'

import styles from './Items.module.scss'

// TODO: fix any
interface IItemsProps {
  className?: string
  items: any
  isLoading: boolean
  isLoadingMore: boolean
  isFetching: boolean
  activeIndex: number
  isMe: boolean
}

const Items: React.FC<IItemsProps> = ({
  className,
  items,
  isLoading,
  isLoadingMore,
  isFetching,
  activeIndex,
  isMe,
}) => {
  const {t} = useTranslation()

  const getEmptyText = () => {
    switch (activeIndex) {
      case PROFILE_TAB_INDEX.ON_SALE:
        return isMe ? (
          <div className={styles.text}>
            {t('user.noOnSaleMessage')}{' '}
            <NavHashLink smooth to="/#explore" className={styles.textActiveLink}>
              {t('user.here')}
            </NavHashLink>{' '}
            {t('user.to_buy')}
          </div>
        ) : (
          <div className={styles.text}>{t('user.noOnSaleUserMessage')}</div>
        )
      case PROFILE_TAB_INDEX.ITEMS:
        return isMe ? (
          <div className={styles.text}>
            {t('user.noOnSaleMessage')}{' '}
            <NavHashLink smooth to="/#explore" className={styles.textActiveLink}>
              {t('user.here')}
            </NavHashLink>{' '}
            {t('user.to_buy')}
          </div>
        ) : (
          <div className={styles.text}>{t('user.noOnSaleUserMessage')}</div>
        )
      case PROFILE_TAB_INDEX.CREATED:
        return isMe ? (
          <div className={styles.text}>
            {t('user.noCreatedMessage')}{' '}
            <a href="/upload-variants" className={styles.textActiveLink}>
              {t('user.here')}
            </a>{' '}
            {t('user.to_create')}
          </div>
        ) : (
          <div className={styles.text}>{t('user.noCreatedUserMessage')}</div>
        )
      case PROFILE_TAB_INDEX.LIKED:
        return <div className={styles.text}>{t('user.noLikesUserMessage')}</div>
      default:
        return null
    }
  }

  const renderItems = () => {
    return (
      !!items &&
      !!items.length &&
      items.map((item: any) => <Card className={styles.card} item={item} key={nextId()} isOwned />)
    )
  }

  const cardSkeletons = Array.from({length: PROFILE_ITEMS_PER_PAGE}, (_value, index) => index)

  return (
    <div className={cn(styles.items, className)}>
      <div className={styles.list}>
        {(!isLoading || isLoadingMore || isFetching) && renderItems()}
        {(isLoading || isLoadingMore) &&
          cardSkeletons.map(cardSkeleton => (
            <CardSkeleton className={styles.card} key={cardSkeleton} />
          ))}
        {!items.length && !isLoading && getEmptyText()}
      </div>
    </div>
  )
}

export default Items
