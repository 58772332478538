import Skeleton from 'react-loading-skeleton'

import SearchRowSkeleton from '../SearchRowSkeleton/SearchRowSkeleton'

import styles from './SearchResultSkeleton.module.scss'

interface ISearchResultSkeletonProps {
  className?: string
}

const SearchResultSkeleton: React.FC<ISearchResultSkeletonProps> = ({className}) => {
  const skeletonArray = [1, 2, 3]

  return (
    <div className={className}>
      {skeletonArray.map(skeletonElement => (
        <div className={styles.skeletonResult} key={skeletonElement}>
          <Skeleton className={styles.skeletonCategory} width={70} />
          <SearchRowSkeleton />
          <SearchRowSkeleton />
          <SearchRowSkeleton />
        </div>
      ))}
    </div>
  )
}
export default SearchResultSkeleton
