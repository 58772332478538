import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {notification} from 'antd'
import BigNumber from 'bignumber.js/bignumber'
import cn from 'classnames'
import {observer} from 'mobx-react'

import {storeApi} from '../../services/api'
import {useWalletConnectorContext} from '../../services/walletConnect'
import {useMst} from '../../store/store'
import {formatCoinToken} from '../../utils/coins'
import {getBalanceByCoinName} from '../../utils/getBalance'
import Button from '../Button'
import TextInput from '../TextInput'

import styles from './Bid.module.scss'

interface IBidProps {
  className?: string
  id: number
  available: number
  title: string
  creatorName: string
  price: string
  currency: string
  fee: number
  updateTokenData: () => void
  onClose: () => void
}

const Bid: React.FC<IBidProps> = observer(
  ({
    className,
    available = 1,
    title,
    creatorName,
    id,
    currency,
    updateTokenData,
    onClose,
    fee,
    price,
  }) => {
    const {t} = useTranslation()
    const walletConnector = useWalletConnectorContext()
    const {user} = useMst()
    const [bidValue, setBidValue] = useState<string>('')
    const [quantity, setQuantity] = useState<string>(available.toString())
    const [balance, setBalance] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getUserBalance = useCallback(async () => {
      const req = walletConnector.walletService.getTokenBalance(formatCoinToken(currency))

      req.then((data: string | number) => {
        setBalance(getBalanceByCoinName(data, currency))
      })
    }, [walletConnector.walletService, currency])

    const handlePlaceABid = () => {
      setIsLoading(true)
      storeApi
        .createBid(id, +bidValue, +quantity, currency)
        .then(() => {
          onClose()
          updateTokenData()
        })
        .catch(({response}) => {
          notification.error({
            message: 'Error',
            description:
              response.data && response.data.error ? response.data.error : 'Something went wrong',
          })
          console.error('Bid modal createBid', response)
        })
        .finally(() => setIsLoading(false))
    }

    useEffect(() => {
      if (!user.address) return
      getUserBalance()
    }, [getUserBalance, user.address])

    return (
      <div className={cn(className, styles.checkout)}>
        <div className={cn('h4', styles.title)}>{t('token.place_bid')}</div>
        <div className={styles.info}>
          {t('token.checkout.text1')} <strong>{title}</strong> {t('token.checkout.text2')}{' '}
          <strong>{creatorName}</strong>
        </div>
        <div className={styles.stage}>{t('token.bid.text1')}</div>
        <div className={styles.table}>
          <div className={styles.input_row}>
            <TextInput
              name="bid"
              label=""
              type="number"
              positiveOnly
              className={cn(styles.input, styles.col)}
              placeholder={t('token.bid.enterBid')}
              value={bidValue}
              onChange={e => setBidValue(e.target.value)}
            />
            <div className={styles.col}>{currency.toUpperCase()}</div>
          </div>
          {available > 1 && (
            <div className={styles.input_row}>
              <TextInput
                name="bid"
                label=""
                type="number"
                className={styles.input}
                placeholder={t('token.bid.text2')}
                value={quantity.toString()}
                onChange={e => setQuantity(e.target.value)}
                max={available}
                integer
              />
              <div className={styles.col}>Quantity</div>
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.col}>{t('your_balance')}</div>
            <div className={styles.col}>
              {balance} {currency.toUpperCase()}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>{t('service_fee')}</div>
            <div className={styles.col}>{fee}%</div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>{t('token.bid.text3')}</div>
            <div className={styles.col}>
              {bidValue
                ? `${new BigNumber(quantity || 0).multipliedBy(
                    new BigNumber(bidValue),
                  )} ${currency.toUpperCase()}`
                : ''}
            </div>
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            type="button"
            loading={isLoading}
            disabled={
              new BigNumber(quantity)
                .multipliedBy(new BigNumber(bidValue))
                .isGreaterThan(new BigNumber(balance)) ||
              new BigNumber(quantity)
                .multipliedBy(new BigNumber(bidValue))
                .isLessThan(new BigNumber(price)) ||
              !bidValue ||
              (available > 1 && +quantity === 0)
            }
            className={cn('button', styles.button)}
            onClick={handlePlaceABid}>
            {isLoading ? t('pending') : t('token.place_bid')}
          </Button>
          <Button type="button" className={cn('button-stroke', styles.button)} onClick={onClose}>
            {t('cancel')}
          </Button>
        </div>
      </div>
    )
  },
)

export default Bid
