import React from 'react'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useTranslation} from 'react-i18next'
import cn from 'classnames'

import {storeApi} from '../../services/api'
import Button from '../Button'
import TextArea from '../TextArea'

import styles from './Report.module.scss'

interface IReportProps {
  className?: string
  isUser?: boolean
  onClose: () => void
}

const Report: React.FC<IReportProps> = ({className, onClose, isUser = false}) => {
  const {t} = useTranslation()
  const {executeRecaptcha} = useGoogleReCaptcha()
  const link = window.location

  const [reportMessage, setReportMessage] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const onChange = (e: any) => {
    const {value} = e.target
    setReportMessage(value)
  }

  const submitReport = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    setIsLoading(true)
    await executeRecaptcha('report').then((responseToken: string) => {
      storeApi
        .reportPage(link.toString(), reportMessage, responseToken)
        .then(() => {
          setIsLoading(false)
          onClose()
        })
        .catch((error: any) => {
          setIsLoading(false)
          console.log(error, 'report not submitted')
        })
    })
  }, [executeRecaptcha, link, reportMessage, onClose])

  return (
    <div className={cn(className, styles.transfer)}>
      <div className={cn('h4', styles.title)}>{t('user.report.title')}</div>
      <div className={styles.text}>{isUser ? t('user.report.text3') : t('user.report.text1')}</div>
      <TextArea
        className={styles.field}
        label="message"
        onChange={onChange}
        name="Message"
        placeholder={t('user.report.text2')}
        required
      />
      <div className={styles.btns}>
        <Button
          type="button"
          className={cn('button', styles.button)}
          onClick={submitReport}
          loading={isLoading}>
          {t('user.report.btn')}
        </Button>
        <Button
          type="button"
          className={cn('button-stroke', styles.button)}
          onClick={() => onClose()}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  )
}

Report.defaultProps = {
  className: '',
  isUser: false,
}

export default Report
