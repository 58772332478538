import {t} from 'i18next'

import axios from '../../core/axios'
import {ISearchResponse, ITokenDetails} from '../../types'
import {TrackMinerTransactionMethod} from '../../types/trackMinerTransactionMethod'

export default {
  burnToken: (id: string, amount?: string) =>
    axios.post(`store/${id}/burn/?network=${localStorage.kephi_nft_chainName}`, {amount}),
  createToken: (data: any) =>
    axios.post(`store/create_token/?network=${localStorage.kephi_nft_chainName}`, data),
  // createToken: (data: any, details: any) => {
  //   data.details = details;
  //   return axios.post('store/create_token/', data);
  // },
  createCollection: (data: any) =>
    axios.post(`store/create_collection/?network=${localStorage.kephi_nft_chainName}`, data),
  saveToken: (data: any) =>
    axios.post(`store/save_token/?network=${localStorage.kephi_nft_chainName}`, data),
  saveCollection: (data: any) =>
    axios.post(`store/save_collection/?network=${localStorage.kephi_nft_chainName}`, data),
  transferToken: (id: string, address: string, amount?: string) => {
    const data = {address, amount}
    if (!amount) delete data.amount
    return axios.post(`store/transfer/${id}/?network=${localStorage.kephi_nft_chainName}`, data)
  },
  // saveCollection: (data: any, tx_hash: string) => axios.post(`store/save_collection/${tx_hash}`, data),
  getExplore: (page: number, filter: string, sort: string) =>
    axios.get(
      `store/hot/${page}/?network=${localStorage.kephi_nft_chainName}&sort=${sort}${
        filter !== 'all' ? `&tag=${filter}` : ''
      }`,
    ),
  // getTags: () => axios.get(`store/tags/?network=${localStorage.kephi_nft_chainName}`),
  getTags: () => axios.get(`store/tags/?network=${undefined}`),
  // getFavorites: () => axios.get(`store/favorites/?network=${localStorage.kephi_nft_chainName}`),
  getFavorites: () => axios.get(`store/favorites/?network=${undefined}`),
  // getCollections: () =>
  //   axios.get(`store/hot_collections/?network=${localStorage.kephi_nft_chainName}`),
  getCollections: () => axios.get(`store/hot_collections/?network=${undefined}`),
  getHotBids: () => {
    return axios.get(
      `store/hot_bids/${
        localStorage.kephi_nft_chainName ? `?network=${localStorage.kephi_nft_chainName}` : ''
      }`,
    )
  },
  // getCollectionById: (id: number | string, page: number) =>
  //   axios.get(`store/collection/${id}/${page}/?network=${localStorage.kephi_nft_chainName}`),
  // getToken: (id: number | string) =>
  //   axios.get(`store/${id}/?network=${localStorage.kephi_nft_chainName}`),
  getCollectionById: (id: number | string, page: number) =>
    axios.get(`store/collection/${id}/${page}/?network=${undefined}`),
  getToken: (id: number | string) => axios.get(`store/${id}/?network=${undefined}`),
  buyToken: (id: number | string, amount: number, sellerId?: string | number) => {
    const data: any = {
      id,
      tokenAmount: amount,
    }
    if (sellerId) data.sellerId = sellerId
    return axios.post(`/store/buy/?network=${localStorage.kephi_nft_chainName}`, data)
  },
  getCollection: (id: string | number, page: number, criteria: string) =>
    axios.get(`/store/collection/${id}/${page}/${criteria ? `?criteria=${criteria}` : ''}`),
  getLiked: (address: string, page: number) =>
    // axios.get(`store/liked/${address}/${page}/?network=${localStorage.kephi_nft_chainName}`),
    axios.get(`store/liked/${address}/${page}/?network=${undefined}`),
  getCreated: (address: string, page: number) =>
    // axios.get(`store/created/${address}/${page}/?network=${localStorage.kephi_nft_chainName}`),
    axios.get(`store/created/${address}/${page}/?network=${undefined}`),
  getCollectibles: (address: string, page: string | number) =>
    // axios.get(`store/owned/${address}/${page}/?network=${localStorage.kephi_nft_chainName}`),
    axios.get(`store/owned/${address}/${page}/?network=${undefined}`),
  getUserCollections: (address: string) =>
    // axios.get(`store/collections/${address}/?network=${localStorage.kephi_nft_chainName}`),
    axios.get(`store/collections/${address}/?network=${undefined}`),
  getSearchResults: (data: {text: string; page: number; itemsPerPage?: number}, queries: any) => {
    const queriesCopy = {
      ...queries,
      min_price: queries.min_price ? queries.min_price[0] || queries.min_price : undefined,
      max_price: queries.max_price ? queries.max_price[0] || queries.max_price : undefined,
      currency: queries.currency.toLowerCase(),
      tags: queries.tags.toLocaleUpperCase(),
      is_verified: queries.is_verified?.toLocaleUpperCase(),
      gallery: queries.gallery?.toLocaleUpperCase(),
    }
    if (
      queriesCopy.currency.toLowerCase() === t('all').toLowerCase() ||
      queriesCopy.currency.toLowerCase() === 'all' ||
      queriesCopy.currency.toLowerCase() === 'todos'
    ) {
      delete queriesCopy.currency
    }
    if (
      queriesCopy.tags.toLocaleLowerCase() === t('allItems').toLocaleLowerCase() ||
      queriesCopy.tags === 'allItems'.toLocaleUpperCase() ||
      queriesCopy.tags === 'all Items'.toLocaleUpperCase() ||
      queriesCopy.tags === 'todos'.toLocaleUpperCase()
    )
      delete queriesCopy.tags

    switch (queriesCopy.is_verified) {
      case undefined:
      case t('creatorOption.all').toLocaleUpperCase():
      case 'creatorOption.all'.toLocaleUpperCase():
      case 'all'.toLocaleUpperCase():
        delete queriesCopy.is_verified
        break
      case t('creatorOption.verifiedOnly').toLocaleUpperCase():
        queriesCopy.is_verified = true
        break
      case t('creatorOption.unverifiedOnly').toLocaleUpperCase():
        queriesCopy.is_verified = false
        break
      default:
        break
    }

    switch (queriesCopy.order_by) {
      case 'home.discover.filter.recently_added':
      case t('home.discover.filter.recently_added'):
      case 'Newest':
      case 'El más nuevo':
        queriesCopy.order_by = '-date'
        break
      case t('home.discover.filter.long_added'):
        // case 'Oldest':
        // case 'Más antiguo':
        queriesCopy.order_by = 'date'
        break
      case t('home.discover.filter.most_liked'):
        // case 'Most liked':
        // case 'Más gustado':
        queriesCopy.order_by = '-likes'
        break
      case t('home.discover.filter.least_liked'):
        // case 'Least liked':
        // case 'Menos gustado':
        queriesCopy.order_by = 'likes'
        break
      case t('home.discover.filter.highest_price'):
        // case 'Highest price':
        // case 'Precio más alto':
        queriesCopy.order_by = '-price'
        break
      case t('home.discover.filter.the_lowest_price'):
        // case 'Lowest price':
        // case 'El precio más bajo':
        queriesCopy.order_by = 'price'
        break
      default:
        queriesCopy.order_by = 'random'
        break
    }
    // let query = `?network=${localStorage.kephi_nft_chainName}&on_sale=true&`
    let query = `?network=${undefined}&on_sale=true&`
    Object.keys(queriesCopy).forEach((key, index) => {
      // if (!localStorage.kephi_nft_chainName) return;
      if (queriesCopy[key] || queriesCopy[key] === false || queriesCopy[key] === 0) {
        query = query.concat(
          `${key}=${queriesCopy[key]}${index === Object.keys(queriesCopy).length - 1 ? '' : '&'}`,
        )
      }
    })
    return axios.post(
      // `/store/search/${
      //   query === `?network=${localStorage.kephi_nft_chainName}&on_sale=true&`
      //     ? `?network=${localStorage.kephi_nft_chainName}`
      //     : query
      // }`,
      `/store/search/${
        query === `?network=${undefined}&on_sale=true&` ? `?network=${undefined}` : query
      }`,
      {
        text: data.text,
        page: data.page,
        items_per_page: data.itemsPerPage,
      },
    )
  },
  getFee: (currency: string | null) =>
    axios.get(
      `/store/fee/?network=${localStorage.kephi_nft_chainName}${
        currency ? `&currency=${currency}` : ''
      }`,
    ),
  setCollectionCover: (file: any, id: string) => {
    const data = new FormData()
    data.append('id', id)
    data.append('auth_token', localStorage.kephi_nft_token)
    data.append('cover', file)
    return axios.post(`/store/set_cover/?network=${localStorage.kephi_nft_chainName}`, data)
  },
  createBid: (id: string | number, amount: number, quantity: number, currency: string) =>
    axios.post(`/store/bids/make_bid/?network=${localStorage.kephi_nft_chainName}`, {
      token_id: id,
      amount,
      quantity,
      currency,
    }),
  verificateBet: (id: number) =>
    axios.get(`/store/verificate_bet/${id}/?network=${localStorage.kephi_nft_chainName}`),
  endAuction: (id: number) =>
    axios.post(`/store/end_auction/${id}/?network=${localStorage.kephi_nft_chainName}`, {
      token: localStorage.kephi_nft_token,
    }),
  putOnSale: (tokenId: number, price?: number | null, selling?: boolean) => {
    const data: any = {
      selling: true,
      price,
    }
    if (!selling) {
      data.minimal_bid = price
      delete data.price
    }

    return axios.patch(`/store/${tokenId}/?network=${localStorage.kephi_nft_chainName}`, data)
  },
  reportPage: (page: string, reportMessage: string, token: string) =>
    axios.post(`/store/report/?network=${localStorage.kephi_nft_chainName}`, {
      page,
      message: reportMessage,
      token,
    }),
  support: (email: string, message: string, token: string) =>
    axios.post(`/store/support/?network=${localStorage.kephi_nft_chainName}`, {
      email,
      message,
      token,
    }),
  trackTransaction: (tx_hash: string, token: string | number, seller_id: string | number) => {
    const data: any = {
      tx_hash,
      token,
      ownership: seller_id,
    }
    if (!seller_id) delete data.ownership
    return axios.post(`/store/track_transaction/?network=${localStorage.kephi_nft_chainName}`, data)
  },
  trackMinerTransaction: (
    token: string | number,
    tx_hash: string,
    block_number: string | number,
    method: TrackMinerTransactionMethod,
  ) => {
    const data: any = {
      token,
      tx_hash,
      block_number,
      method,
    }
    return axios.post('/store/track_miner_transaction/', data)
  },
  removeFromSale: (id: string | number, price?: null, minimal_bid?: null) => {
    const data: {
      selling: false
      price?: null
      minimal_bid?: null
    } = {
      selling: false,
    }
    if (price === null) {
      data.price = price
    }
    if (minimal_bid === null) {
      data.minimal_bid = minimal_bid
    }
    return axios.patch(`/store/${id}/?network=${localStorage.kephi_nft_chainName}`, data)
  },
  getMaxPrice: (currency: string) => {
    if (currency.toLocaleLowerCase() === t('all').toLocaleLowerCase()) {
      return axios.get(`/store/max_price/`)
    }
    return axios.get(`/store/max_price/?currency=${currency}`)
  },
  rejectTransaction: (data: any) => axios.post('/store/remove-reject/', data),
  quickSearch: async (data: {text: string}) => {
    const response = await axios.post('/store/quick_search/', data, {
      headers: {
        'content-type': 'application/json',
      },
    })
    return response.data as ISearchResponse
  },
  getNftDetails: async (tokenId: number) => {
    const response = await axios.get(`/store/${tokenId}/details`)
    return response.data.details as ITokenDetails
  },
}
