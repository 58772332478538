import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import Image from '../Image'

import styles from './EmptyState.module.scss'

const EmptyState: React.FC = () => {
  const {t} = useTranslation()
  return (
    <div className={classNames('section', styles.section)}>
      <div className={styles.preview}>
        <Image
          // srcSet="/images/content/figures@2x.png 2x"
          // srcSetDark="/images/content/figures-dark@2x.png 2x"
          src="/images/content/ufo.svg"
          // srcDark="/images/content/figures-dark.png"
          alt="Figures"
        />
      </div>
      <div className={styles.wrap}>
        <div className={styles.title}>{t('emptyState.message')}</div>
      </div>
    </div>
  )
}

export default EmptyState
