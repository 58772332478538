import React from 'react'
import {Form} from 'antd'
import cn from 'classnames'
import {FormikProps} from 'formik'
import {t} from 'i18next'

import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import SuccessCreated from '../../../components/SuccessCreated'
import TextInput from '../../../components/TextInput'
import Uploader from '../../../components/Uploader'
import {validateField} from '../../../utils/validate'

import styles from './CreateCollection.module.scss'

export interface ICreateCollection {
  img: any
  tokenName: string
  symbol: string
  descr?: string
  shortUrl: string
  preview?: string
  isLoading: boolean
  showModal: boolean
}

const CreateCollection: React.FC<FormikProps<ICreateCollection>> = ({
  touched,
  errors,
  handleChange,
  handleBlur,
  values,
  handleSubmit,
  setFieldValue,
}) => {
  const onSubmit = () => {
    handleSubmit()
  }
  return (
    <Form name="form-create-coll" className={styles.form} layout="vertical">
      <div className={styles.createCollection}>
        <div className={cn('h4', styles.title)}>{t('createCollection.title')}</div>
        <div className={styles.upload}>
          {values.img ? <img alt="" src={values.preview} /> : <div className={styles.empty} />}
          <div className={styles.wrapper}>
            <span className={styles.text}>{t('createCollection.imageSuggestion')}</span>
            <div className={styles.file}>
              <Form.Item
                name="img"
                className="form-create__item input__field"
                validateStatus={validateField('img', touched, errors)}
                help={!touched.img ? false : errors.img}
                required>
                <Uploader type="img" isButton />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className={styles.fieldset}>
          <Form.Item
            name="tokenName"
            validateStatus={validateField('tokenName', touched, errors)}
            help={!touched.tokenName ? t('createCollection.nameHelp') : errors.tokenName}>
            <div className={styles.field}>
              <TextInput
                label={t('createCollection.name')}
                name="tokenName"
                type="text"
                placeholder={t('createCollection.placeholder.name')}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="symbol"
            validateStatus={validateField('symbol', touched, errors)}
            help={!touched.symbol ? false : errors.symbol}>
            <div className={styles.field}>
              <TextInput
                label={t('createCollection.symbol')}
                name="symbol"
                type="text"
                placeholder={t('createCollection.placeholder.symbol')}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="descr"
            validateStatus={validateField('descr', touched, errors)}
            help={!touched.descr ? false : errors.descr}>
            <div className={styles.field}>
              <TextInput
                label={t('createCollection.description')}
                name="descr"
                type="text"
                placeholder={t('createCollection.placeholder.description')}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="shortUrl"
            validateStatus={validateField('shortUrl', touched, errors)}
            help={!touched.shortUrl ? false : errors.shortUrl}>
            <div className={styles.field}>
              <TextInput
                label={t('createCollection.url')}
                name="shortUrl"
                type="text"
                placeholder={t('createCollection.placeholder.url')}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
        </div>

        <Button
          className={cn('button', styles.button)}
          onClick={onSubmit}
          loading={values.isLoading}>
          <span>{t('createCollection.createButton')}</span>
        </Button>
        <Modal
          visible={values.showModal}
          onClose={() => setFieldValue('showModal', false)}
          closeOnRight>
          <SuccessCreated
            onOk={() => setFieldValue('showModal', false)}
            title={t('createCollection.createSuccess')}
          />
        </Modal>
      </div>
    </Form>
  )
}

export default CreateCollection
