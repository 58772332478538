import classNames from 'classnames'

import styles from './Switch.module.scss'

interface ISwitchProps {
  className?: string
  customClasses?: {
    input?: string
    inner?: string
    box?: string
  }
  value: boolean
  setValue: (foo: boolean) => void
  id?: string
}

const Switch: React.FC<ISwitchProps> = ({className, customClasses = {}, value, setValue, id}) => {
  return (
    <label htmlFor={id} className={classNames(styles.switch, className)}>
      <input
        id={id}
        className={classNames(styles.input, customClasses.input)}
        type="checkbox"
        checked={value}
        onChange={() => setValue(!value)}
      />
      <span className={classNames(styles.inner, customClasses.inner)}>
        <span className={classNames(styles.box, customClasses.box)} />
      </span>
    </label>
  )
}

export default Switch
