import React, {createContext, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation, withRouter} from 'react-router-dom'
import {SignInOption} from '@0xsequence/provider'
import {notification} from 'antd'
import {observer} from 'mobx-react'

import Modal from '../../components/Modal'
import Successfully from '../../components/Successfully/Successfully'
import {PROVIDER} from '../../constants/constants'
import {rootStore} from '../../store/store'
import {BlockchainType} from '../../types/blockchain'
import {ProviderType} from '../../types/provider'
import {getContracts} from '../../utils/contracts'
import {WALLET_CLOSE} from '../wallets/web2Wallet'
import {getWallet, Wallet} from '../walletService'

import styles from './walletConnect.module.scss'

declare global {
  interface Window {
    ethereum: any
    kardiachain: any
  }
}

const walletConnectorContext = createContext<{
  connect: (
    chainName: BlockchainType,
    providerName: ProviderType,
    logInOption?: SignInOption,
  ) => void
  disconnect: () => void
  walletService: Wallet
}>({
  connect: (): void => {},
  disconnect: (): void => {},
  walletService: new Wallet(getWallet()),
})

const Connector: React.FC<any> = observer(({children}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {pathname} = useLocation()
  let provider = new Wallet(getWallet())
  const [visibleSuccessfullyConnected, setVisibleSuccessfullyConnected] = useState(false)

  const connect = async (
    chainName: BlockchainType,
    providerName: ProviderType,
    logInOption?: SignInOption,
  ) => {
    if (providerName === PROVIDER.SEQUENCE) provider = new Wallet(getWallet(PROVIDER.SEQUENCE))

    if (
      window.ethereum ||
      window.kardiachain ||
      providerName === PROVIDER.WALLETCONNECT ||
      providerName === PROVIDER.SEQUENCE
    ) {
      try {
        const successfulConnection = await provider.connectWallet(
          chainName,
          providerName,
          logInOption,
        )

        if (successfulConnection) {
          setVisibleSuccessfullyConnected(true)
          if (pathname === '/connect-wallet') history.goBack()
          setTimeout(handleCloseSuccessfullyConnectedModal, 3000)
        }

        localStorage.kephi_nft_chainName = chainName
        localStorage.kephi_nft_providerName = providerName
        rootStore.contracts.update(getContracts())
        rootStore.user.getMe()
      } catch (error: any) {
        if (error?.message === WALLET_CLOSE) return
        console.log(error)
        disconnect()
      }
    } else {
      notification.error({
        message: `You don't have  ${providerName} wallet installed`,
        description: `For connecting to ${chainName} network in your device please
        ${chainName === 'KardiaChain' ? 'use KardiaChain Wallet' : 'use Wallet Connect'} `,
      })
    }
  }

  const disconnect = async () => {
    provider.disconnect()

    if (pathname !== '/') history.push('/')
  }

  const handleCloseSuccessfullyConnectedModal = () => {
    setVisibleSuccessfullyConnected(false)
  }

  useEffect(() => {
    if (
      window.ethereum ||
      window.kardiachain ||
      localStorage.kephi_nft_providerName === PROVIDER.WALLETCONNECT ||
      localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE
    ) {
      if (localStorage.kephi_nft_chainName && localStorage.kephi_nft_providerName) {
        connect(localStorage.kephi_nft_chainName, localStorage.kephi_nft_providerName)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect, localStorage.kephi_nft_chainName, localStorage.kephi_nft_providerName])

  return (
    <walletConnectorContext.Provider
      value={{
        walletService: provider,
        connect,
        disconnect,
      }}>
      {children}
      <Modal
        visible={visibleSuccessfullyConnected}
        outerClassName={styles.modalSuccessOuter}
        closeButtonHidden>
        <Successfully
          onClose={handleCloseSuccessfullyConnectedModal}
          titleText={t('modalConnect.title')}
          subtitleText={t('modalConnect.subtitle')}
          closeText={t('close')}
        />
      </Modal>
    </walletConnectorContext.Provider>
  )
})

export default withRouter(Connector)

export function useWalletConnectorContext() {
  return useContext(walletConnectorContext)
}
