import nextId from 'react-id-generator'
import {Link} from 'react-router-dom'
import {t} from 'i18next'

import styles from './Collection.module.scss'

interface ICollectionProps {
  collection: any
}

const Collection: React.FC<ICollectionProps> = ({collection}) => {
  return (
    <Link className={styles.collectionHome} to={`/collection/${collection.id}`} key={nextId()}>
      <div className={styles.gallery}>
        <div className={styles.preview} key={nextId()}>
          <img src={collection.media_low_resolution} alt="Collection" />
        </div>
        {collection.tokens.map(
          (y: any, index: number) =>
            index < 3 && (
              <div className={styles.preview} key={nextId()}>
                <img src={y} alt="Collection" />
              </div>
            ),
        )}
      </div>
      <div className={styles.subtitle}>{collection.name}</div>
      <div className={styles.line}>
        <div className={styles.user}>
          <div className={styles.avatar}>
            <img src={collection.creator.avatar} alt="Avatar" />
          </div>
          <div className={styles.author}>
            {t('home.hot_collections.by')}{' '}
            <span>
              <strong>
                {collection.creator.name?.length > 21
                  ? `${collection.creator.name.slice(0, 14)}...${collection.creator.name.slice(-4)}`
                  : collection.creator.name}
              </strong>
            </span>
          </div>
        </div>
        <div className={styles.collectionItemCounter}>
          <span>{collection.total_tokens || 0}</span> {t('home.hot_collections.items')}
        </div>
      </div>
    </Link>
  )
}

export default Collection
