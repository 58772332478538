import classNames from 'classnames'

import Stepper from './components/Stepper/Stepper'
import {steps} from './steps/steps'

import styles from './CreateItem.module.scss'

interface ICreateItem {
  isSingle: boolean
}

const CreateItem: React.FC<ICreateItem> = ({isSingle}) => {
  return (
    <div className={classNames('section', styles.section)}>
      <div className={classNames('container', styles.container)}>
        <Stepper steps={steps} isSingle={isSingle} />
      </div>
    </div>
  )
}

export default CreateItem
