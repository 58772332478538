/* eslint-disable @typescript-eslint/ban-ts-comment */
import {IConnectWallet} from '../types'
import {BlockchainType} from '../types/blockchain'
import Config from '../utils/config'

export const chains = {
  Binance: {
    name: 'binance-testnet',
    network: {
      chainID: Config.isProduction ? 56 : 97,
      chainName: Config.isProduction ? 'Binance Smart Chain' : 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpc: Config.isProduction
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      blockExplorerUrl: Config.isProduction
        ? 'https://bscscan.com/'
        : 'https://testnet.bscscan.com/',
    },
    provider: {
      MetaMask: {name: 'MetaMask'},
      WalletLink: {
        name: 'WalletLink',
        useProvider: 'infura',
        provider: {
          infura: {
            infuraId: 'b939155f8c9941ab8fd0c47fe6ddb601',
          },
        },
      },
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [Config.isProduction ? 56 : 97]: Config.isProduction
                ? 'https://bsc-dataseed.binance.org/'
                : 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            },
            chainId: Config.isProduction ? 56 : 97,
          },
        },
      },
    },
  },
  KardiaChain: {
    wallets: ['kardiachain'],
    // network: {
    //   name: 'KardiaChain',
    //   chainName: 'KardiaChain',
    //   chainID: Config.isProduction ? 24 : 69,
    //   chainId: 24,
    // },
    network: {
      name: 'KardiaChain',
      chainID: Config.isProduction ? 24 : 69,
      chainId: 24,
      chainName: Config.isProduction ? 'KardiaChain' : 'KardiaChain Testnet',
      nativeCurrency: {
        name: 'KAI',
        symbol: 'KAI',
        decimals: 18,
      },
      rpc: Config.isProduction ? 'https://rpc.kardiachain.io' : 'https://rpc.kardiachain.io',
      blockExplorerUrl: Config.isProduction
        ? 'https://explorer.kardiachain.io/'
        : 'https://explorer-dev.kardiachain.io/',
    },
    provider: {
      KardiaChain: {name: 'KardiaChain'},
      MetaMask: {name: 'MetaMask'},
    },
  },
  Polygon: {
    name: 'Polygon',
    network: {
      chainID: Config.isProduction ? 137 : 80001,
      chainName: Config.isProduction ? 'Polygon Mainnet' : 'Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpc: Config.isProduction
        ? 'https://polygon-mainnet.g.alchemy.com/v2/lt03_srIxJa-L8ytL636hW9w4iPsRAEN'
        : 'https://polygon-mumbai.g.alchemy.com/v2/cd6fSNOx4aBs5zIwlqUTr9MKa8Dr-66p',
      blockExplorerUrl: Config.isProduction
        ? 'https://polygonscan.com/'
        : 'https://mumbai.polygonscan.com/',
    },
    provider: {
      MetaMask: {name: 'MetaMask'},
      WalletLink: {
        name: 'WalletLink',
        useProvider: 'infura',
        provider: {
          infura: {
            infuraId: 'b939155f8c9941ab8fd0c47fe6ddb601',
          },
        },
      },
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [Config.isProduction ? 137 : 80001]: Config.isProduction
                ? 'https://polygon-mainnet.g.alchemy.com/v2/lt03_srIxJa-L8ytL636hW9w4iPsRAEN'
                : 'https://polygon-mumbai.g.alchemy.com/v2/cd6fSNOx4aBs5zIwlqUTr9MKa8Dr-66p',
            },
            chainId: Config.isProduction ? 137 : 80001,
          },
        },
      },
    },
  },
  Ethereum: {
    name: 'Ethereum',
    network: {
      chainID: Config.isProduction ? 1 : 5,
      chainName: Config.isProduction ? 'Ethereum Mainnet' : 'Goerli Test Network',
      nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
      },
      rpc: Config.isProduction
        ? 'https://eth-mainnet.g.alchemy.com/v2/j1Wq3AgHB0p7k2boQAK5gwj0UTwMozF6'
        : 'https://eth-goerli.g.alchemy.com/v2/-8VxtDdMruc6xp8fCir9oy85XN9dyH6i',
      blockExplorerUrl: Config.isProduction
        ? 'https://etherscan.io/'
        : 'https://goerli.etherscan.io/',
    },
    provider: {
      MetaMask: {name: 'MetaMask'},
      WalletLink: {
        name: 'WalletLink',
        useProvider: 'infura',
        provider: {
          infura: {
            infuraId: 'b939155f8c9941ab8fd0c47fe6ddb601',
          },
        },
      },
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [Config.isProduction ? 1 : 5]: Config.isProduction
                ? 'https://eth-mainnet.g.alchemy.com/v2/j1Wq3AgHB0p7k2boQAK5gwj0UTwMozF6'
                : 'https://eth-goerli.g.alchemy.com/v2/-8VxtDdMruc6xp8fCir9oy85XN9dyH6i',
            },
            chainId: Config.isProduction ? 1 : 5,
          },
        },
      },
    },
  },
}

export const connectWallet = (chainName: BlockchainType): IConnectWallet => {
  const chain = chains[chainName]

  return {
    wallets: ['MetaMask', 'WalletConnect', 'WalletLink', 'KardiaChain'],
    network: chain.network,
    provider: chain.provider,
    settings: {providerType: true},
  }
}
