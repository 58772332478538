import cn from 'classnames'
import {t} from 'i18next'

import {ReactComponent as Check} from '../../assets/img/icons/check-gradient.svg'
import InfoPanel from '../InfoPanel/InfoPanel'

import styles from './SuccessCreated.module.scss'

interface ISuccessCreated {
  onOk: () => void
  title: string
  btnText?: string
}

const SuccessCreated: React.FC<ISuccessCreated> = ({title, onOk, btnText}) => {
  return (
    <div className={styles.success}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Check />
        </div>
      </div>
      <div className={cn('h4', styles.title)}>{title}</div>
      <InfoPanel className={styles.infoPanel} message={t('token.note')} />
      <button className={cn('button', styles.button)} type="button" onClick={() => onOk()}>
        {btnText || 'OK'}
      </button>
    </div>
  )
}

export default SuccessCreated
