import React from 'react'
import {useTranslation} from 'react-i18next'
import BigNumber from 'bignumber.js/bignumber'
import cn from 'classnames'

import {IItem} from '../../../typings/Item'
import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter'
import {setThousandPoints} from '../../../utils/thousandPoints'

import styles from '../Item.module.scss'

interface INftDetails {
  item: IItem | null
  isLiteVersion?: boolean
}

const NftDetails: React.FC<INftDetails> = ({item, isLiteVersion = false}) => {
  const {t} = useTranslation()
  const isMultiple = item?.standart === 'ERC1155'
  return (
    <>
      <div
        className={cn(styles.title, {
          [styles.titleLite]: isLiteVersion,
        })}>
        {capitalizeFirstLetter(item?.name)}
      </div>
      <div className={styles.cost}>
        {!!item?.price && !item?.highest_bid && !item?.minimal_bid && (
          <div className={styles.costContainer}>
            {/* <span className={styles.counter}>{t('price')}</span> */}
            <div className={styles.costWrapper}>
              <div className={styles.price}>
                {item.price} {item.currency && item.currency.symbol.toUpperCase()}
              </div>
              <div className={styles.price}>
                {item.USD_price > 0.01 ? `U$S ${setThousandPoints(item.USD_price)}` : 'U$S 0.01'}
              </div>
            </div>
          </div>
        )}
        {item?.highest_bid ? (
          <div className={styles.costContainer}>
            {/* <span className={styles.counter}>{t('card.highest_bid')}</span> */}
            <div className={styles.costWrapper}>
              <div className={styles.price}>
                {new BigNumber(item.highest_bid.amount).toString()}{' '}
                {item.highest_bid.currency.symbol.toUpperCase()}
              </div>
              <div className={styles.price}>
                {item.highest_bid_USD > 0.01
                  ? `U$S ${setThousandPoints(item.highest_bid_USD)}`
                  : 'U$S 0.01'}
              </div>
            </div>
          </div>
        ) : (
          !!item?.minimal_bid && (
            <div className={styles.costContainer}>
              {/* <span className={styles.counter}>{t('minimal_bid')}</span> */}
              <div className={styles.costWrapper}>
                <div className={styles.price}>
                  {new BigNumber(item.minimal_bid).toString()} {item.currency.symbol.toUpperCase()}
                </div>
                <div className={styles.price}>
                  {item.USD_price > 0.01
                    ? `U$S ${setThousandPoints(item.minimal_bid_USD)}`
                    : 'U$S 0.01'}
                </div>{' '}
              </div>
            </div>
          )
        )}
        <div className={styles.counter}>
          {`${setThousandPoints(item?.available)}/${setThousandPoints(item?.total_supply)}`}{' '}
          {isMultiple && (item?.available || 0) > 1
            ? t('card.in_stock_plural')
            : t('card.in_stock')}
        </div>
      </div>
      <div
        className={cn(styles.info, {
          [styles.infoLite]: isLiteVersion,
        })}>
        {item?.description}{' '}
      </div>
    </>
  )
}

NftDetails.defaultProps = {
  isLiteVersion: false,
}

export default NftDetails
