import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import cn from 'classnames'
import {observer} from 'mobx-react-lite'

import {storeApi} from '../../services/api'
import {useWalletConnectorContext} from '../../services/walletConnect'
import {useMst} from '../../store/store'
import Button from '../Button'
import Loader from '../Loader'
import TextInput from '../TextInput'

import styles from './Transfer.module.scss'

interface ITransferProps {
  className?: string
  itemId?: string
  standart?: string
  onClose: () => void
  amount: number
  setIsSuccess: (value: boolean) => void
}

const Transfer: React.FC<ITransferProps> = observer(
  ({className, itemId, standart, onClose, amount, setIsSuccess}) => {
    const {t} = useTranslation()
    const {user} = useMst()
    const walletConnector = useWalletConnectorContext()
    const [inputValue, setInputValue] = useState('')
    const [tokenAmount, setTokenAmount] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const transferToken = async () => {
      setIsLoading(true)
      try {
        await storeApi
          .transferToken(itemId || '', inputValue, tokenAmount)
          .then(async ({data}: any) => {
            await walletConnector.walletService
              .sendTransaction(data.initial_tx)
              .then((response: any) => {
                storeApi.trackMinerTransaction(
                  itemId || '',
                  response.transactionHash || response.hash,
                  response.blockNumber,
                  'Transfer',
                )
                onClose()
                setIsSuccess(true)
              })
              .catch((e: any) => console.error('Bid modal sendTranscation', e))
              .finally(() => setIsLoading(false))
          })
      } catch (error: any) {
        console.error('Bid modal transferToken', error)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <div className={cn(className, styles.transfer)}>
        <div className={cn('h4', styles.title)}>{t('token.transfer')}</div>
        <div className={styles.text}>{t('token.transferMessage')}</div>
        <div className={styles.info}>{t('token.receiverAddress')}</div>
        <div className={styles.field}>
          <input
            className={styles.input}
            value={inputValue}
            onChange={(e: any) => setInputValue(e.target.value)}
            type="text"
            name="address"
            placeholder={t('token.pasteAddress')}
          />
        </div>
        {standart === 'ERC1155' && (
          <div className={styles.field}>
            <TextInput
              label=""
              className={styles.input}
              value={tokenAmount}
              onChange={(e: any) => setTokenAmount(e.target.value)}
              type="number"
              name="amount"
              placeholder={t('token.pasteTokensAmount')}
              positiveOnly
              max={amount}
              integer
            />
          </div>
        )}
        <div className={styles.btns}>
          <Button
            type="button"
            className={cn('button', styles.button)}
            onClick={transferToken}
            // loading={isLoading}
            disabled={
              (standart === 'ERC1155' && !tokenAmount) ||
              inputValue.toLowerCase() === user.address.toLowerCase() ||
              isLoading
            }>
            {t('continue')}
          </Button>
          <Button
            type="button"
            className={cn('button-stroke', styles.button)}
            onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </div>

        {isLoading && (
          <div className={styles.parentDisable}>
            <div className={styles.overlayBox}>
              <Loader className={styles.loader} color="white" />
            </div>
          </div>
        )}
      </div>
    )
  },
)

export default Transfer
