export const MIN_PRICE = 'minPrice'
export const MAX_PRICE = 'maxPrice'
export const ART_TYPE = 'artType'
export const ORDER_BY = 'orderBy'

export type FilterFormInputs = {
  orderBy: string
  minPrice: number | undefined | string
  maxPrice: number | undefined | string
  artType: string | number
}
