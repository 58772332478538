import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import cn from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import InfoPanel from '../../../../components/InfoPanel/InfoPanel'

import styles from './SuccessfullyPurchased.module.scss'

const socials: Array<{
  title: 'facebook' | 'twitter' | 'instagram' | 'pinterest'
  url: string
}> = [
  {
    title: 'facebook',
    url: 'https://www.facebook.com/KephiGallery/',
  },
  {
    title: 'twitter',
    url: 'https://twitter.com/KephiGallery',
  },
]
interface ISuccessfullyPurchasedProps {
  className?: string
  transactionHash?: string
  title?: string
  creatorName: string
}

const SuccessfullyPurchased: React.FC<ISuccessfullyPurchasedProps> = ({
  className,
  transactionHash,
  title,
  creatorName,
}) => {
  const {t} = useTranslation()
  const [copyAddress, setCopyAddress] = useState(false)

  const handleCopy = () => {
    copyToClipboard(transactionHash || '')
    setCopyAddress(true)
  }
  return (
    <div className={cn(className, styles.wrap)}>
      <div className={cn('h2', styles.title)}>
        {t('yay')}!{' '}
        <span role="img" aria-label="firework">
          {/* 🎉 */}
          💪
        </span>
      </div>
      <div className={styles.info}>
        {t('token.tokenPurchasedSuccessfully')} <span>{title}</span> {t('from')}{' '}
        {creatorName.length > 21
          ? `${creatorName.slice(0, 14)}...${creatorName.slice(-4)}`
          : creatorName}
      </div>
      <InfoPanel className={styles.note} message={t('token.note')} />
      {!!transactionHash && (
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>{t('status')}</div>
            <div className={styles.col}>{t('transactionId')}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>{t('confirmed')}</div>
            <div className={styles.col}>
              {transactionHash.slice(0, 14)}...{transactionHash.slice(-4)}
            </div>
            <Button
              className={styles.copy}
              onClick={handleCopy}
              onMouseLeave={() => setCopyAddress(false)}>
              <Icon name="copy" size="16" />
              <div className={styles.tooltip}>
                <span className={styles.tooltiptext}>
                  {copyAddress ? `${t('success')}!` : t('copyTransactionHash')}
                </span>
              </div>
            </Button>
          </div>
        </div>
      )}
      <div className={styles.socials}>
        {socials.map(x => (
          <a
            className={styles.social}
            href={x.url}
            target="_blank"
            rel="noopener noreferrer"
            key={nextId()}>
            <Icon name={x.title} size="24" />
          </a>
        ))}
      </div>
    </div>
  )
}

export default SuccessfullyPurchased
