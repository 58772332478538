import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {message} from 'antd'
import classNames from 'classnames'
import {t} from 'i18next'

import Button from '../../../../../../../components/Button'
import Modal from '../../../../../../../components/Modal'
import Successfully from '../../../../../../../components/Successfully/Successfully'
import TextInput from '../../../../../../../components/TextInput'
import Uploader from '../../../../../../../components/Uploader'
import {storeApi} from '../../../../../../../services/api'
import {useWalletConnectorContext} from '../../../../../../../services/walletConnect'
import {capitalizeFirstLetter} from '../../../../../../../utils/capitalizeFirstLetter'

import styles from './CreateCollection.module.scss'

const NAME = 'name'
const IMG = 'img'
const AVATAR = 'avatar'
const SYMBOL = 'symbol'
const DESCRIPTION = 'description'
const SHORT_URL = 'short_url'
const PREVIEW = 'preview'

message.config({
  top: 15,
})

interface ICreateCollectionProps {
  isSingle?: boolean
  onCreateSuccess?: () => void
}

interface ICreateCollectionForm {
  name: string // tokenName
  avatar: string // img
  symbol: string // symbol
  standart: string // ', isSingle ? 'ERC721' : 'ERC1155'
  description: string | undefined // descr
  short_url: string | undefined // shortUrl
  preview: string
}

const CreateCollection: React.FC<ICreateCollectionProps> = ({isSingle, onCreateSuccess}) => {
  const walletConnector = useWalletConnectorContext()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: {errors},
    setError,
  } = useForm<ICreateCollectionForm>({
    defaultValues: {
      name: '',
      avatar: '',
      symbol: '',
      standart: isSingle ? 'ERC721' : 'ERC1155',
      preview: '',
    },
  })

  const [avatar, preview] = watch([AVATAR, PREVIEW])

  const handleApply = (collectionData: ICreateCollectionForm) => {
    if (!avatar) {
      message.error({
        content: t('createCollection.imageUpload'),
        className: styles.errorMessage,
      })
      return
    }
    setLoading(true)

    const collectionFormData = new FormData()

    collectionFormData.append('name', collectionData.name)
    collectionFormData.append('avatar', collectionData.avatar)
    collectionFormData.append('symbol', collectionData.symbol)
    collectionFormData.append('standart', isSingle ? 'ERC721' : 'ERC1155')

    if (collectionData.description) {
      collectionFormData.append('description', collectionData.description)
    }
    if (collectionData.short_url) {
      collectionFormData.append('short_url', collectionData.short_url)
    }

    storeApi
      .createCollection(collectionFormData)
      .then(({data}) => {
        walletConnector.walletService
          .sendTransaction(data.initial_tx)
          .then(() => {
            setShowModal(true)
          })
          .catch((err: any) => {
            console.log(err, 'err')
            storeApi.rejectTransaction({
              type: 'collection',
              id: data.collection.id,
            })
            setLoading(false)
          })
      })
      .catch(({response}) => {
        if (response.data.name) {
          setTimeout(() => {
            setError(NAME, {type: 'custom', message: capitalizeFirstLetter(response.data.name)})
          }, 100)
        }
        if (response.data.symbol) {
          setTimeout(() => {
            setError(SYMBOL, {type: 'custom', message: capitalizeFirstLetter(response.data.symbol)})
          }, 100)
        }
        if (response.data.short_url) {
          setTimeout(() => {
            setError(SHORT_URL, {
              type: 'custom',
              message: capitalizeFirstLetter(response.data.short_url),
            })
          }, 100)
        }
        setLoading(false)
      })
  }

  const onError = () => {
    if (!avatar) {
      message.error({
        content: t('createCollection.imageUpload'),
        className: styles.errorMessage,
      })
    }
  }
  return (
    <form onSubmit={handleSubmit(handleApply, onError)}>
      <div className={styles.createCollection}>
        <div className={styles.title}>{t('createCollection.title')}</div>
        <div className={styles.upload}>
          {avatar ? <img alt="" src={preview} /> : <div className={styles.empty} />}
          <div className={styles.wrapper}>
            <span className={styles.text}>{t('createCollection.imageSuggestion')}</span>
            <Controller
              control={control}
              name={AVATAR}
              rules={{
                required: {
                  value: true,
                  message: t('validate.img'),
                },
              }}
              render={() => (
                <div className={styles.file}>
                  <Uploader
                    type={IMG}
                    isButton
                    setFieldValue={(key: any, value: any) => {
                      if (key === IMG) setValue(AVATAR, value)
                      else setValue(key, value)
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className={styles.fieldsWrapper}>
          <Controller
            control={control}
            name={NAME}
            rules={{
              required: {
                value: true,
                message: t('validate.tokenName'),
              },
            }}
            render={({field: {name, value, onChange, onBlur}}) => (
              <div className={styles.field}>
                <TextInput
                  label={t('createCollection.name')}
                  name={name}
                  value={value}
                  type="text"
                  placeholder={t('createCollection.placeholder.name')}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name={SYMBOL}
            rules={{
              required: {
                value: true,
                message: t('validate.symbol1'),
              },
              maxLength: {
                value: 30,
                message: t('validate.symbol2'),
              },
              validate: value => !!value,
            }}
            render={({field: {name, value, onChange, onBlur}}) => (
              <div className={styles.field}>
                <TextInput
                  label={t('createCollection.symbol')}
                  name={name}
                  value={value}
                  type="text"
                  placeholder={t('createCollection.placeholder.symbol')}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name={DESCRIPTION}
            render={({field: {name, value, onChange, onBlur}}) => (
              <div className={styles.field}>
                <TextInput
                  label={t('createCollection.description')}
                  name={name}
                  value={value}
                  type="text"
                  placeholder={t('createCollection.placeholder.description')}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name={SHORT_URL}
            render={({field: {name, value, onChange, onBlur}}) => (
              <>
                <div className={styles.field}>
                  <TextInput
                    label={t('createCollection.url')}
                    name={name}
                    value={value}
                    type="text"
                    placeholder={t('createCollection.placeholder.url')}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors[name]}
                    helperText={errors[name]?.message}
                  />
                </div>
                <div className={classNames(styles.urlMessage, {[styles.onError]: !!errors[name]})}>
                  {t('createCollection.urlMessage')}
                </div>
              </>
            )}
          />
        </div>

        <Button className={classNames('button', styles.button)} type="submit" loading={isLoading}>
          <span>{t('createCollection.createButton')}</span>
        </Button>
        <Modal
          outerClassName={styles.modalSuccessOuter}
          visible={showModal}
          onClose={() => setShowModal(false)}
          closeButtonHidden>
          <Successfully
            onClose={() => {
              setShowModal(false)
              if (onCreateSuccess) onCreateSuccess()
            }}
            titleText={t('createCollection.createSuccessTitle')}
            subtitleText={t('createCollection.createSuccessSubtitle')}
            infoText={t('token.note')}
            closeText="OK"
          />
        </Modal>
      </div>
    </form>
  )
}

CreateCollection.defaultProps = {
  isSingle: false,
  onCreateSuccess: () => {},
}
export default CreateCollection
