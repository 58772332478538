import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {message, Upload} from 'antd'
import cn from 'classnames'
import {useFormikContext} from 'formik'

import ClearImg from '../../assets/img/icons/uploader-cross.svg'

import styles from './Uploader.module.scss'

const {Dragger} = Upload

message.config({
  top: 85,
})

interface IUploader {
  type: string
  className?: string
  // isLoading: boolean;
  setFormat?: (value: string) => void
  setTag?: (value: string) => void
  name?: string
  isButton?: boolean
  mbytes?: number
  setFieldValue?: (field: string, value: any) => void
}

const Uploader: React.FC<IUploader> = React.forwardRef(
  (
    {
      type,
      className,
      // children,
      setFormat,
      setTag,
      name,
      isButton,
      mbytes = 30,
      setFieldValue,
    },
    ref,
  ) => {
    const {t} = useTranslation()
    const formik =
      !!setFieldValue && setFieldValue.toString() !== (() => {}).toString()
        ? null
        : // eslint-disable-next-line react-hooks/rules-of-hooks
          useFormikContext()
    const [hasImage, setHasImage] = useState(false)
    const getBase64 = (img: any, callback: any) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (type === 'img') settingValue('preview', reader.result)
        if (type === 'cover') settingValue('coverPreview', reader.result)
        setHasImage(true)
        callback(reader.result)
      })
      reader.readAsDataURL(img)
    }
    const beforeUpload = (file: any) => {
      const isValidType =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/webp' ||
        file.type === 'image/gif' ||
        (type === 'img' && file.type === 'video/mp4') ||
        (type === 'img' && file.type === 'audio/mpeg')
      if (!isValidType) {
        message.error({
          content: t('create_form.uploadTypeError'),
          className: styles.errorMessage,
        })
      }
      const isLt2M = file.size / 1024 / 1024 <= mbytes
      if (!isLt2M) {
        message.error({
          content: t('create_form.uploadMaxSizeError'),
          className: styles.errorMessage,
        })
      }
      if (isValidType && isLt2M)
        message.success({
          content: t('create_form.uploadSuccessfully'),
          className: styles.successMessage,
        })
      return isValidType && isLt2M
    }
    const handleChange = ({file}: any) => {
      const isValidType =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/webp' ||
        file.type === 'image/gif' ||
        (type === 'img' && file.type === 'video/mp4') ||
        (type === 'img' && file.type === 'audio/mpeg')
      if (!isValidType) {
        return
      }
      const isLt2M = file.size / 1024 / 1024 < mbytes
      if (!isLt2M) {
        return
      }
      if (type === 'img' && setFormat?.toString() !== (() => {}).toString() && !!setFormat) {
        setFormat(file.type.slice(0, file.type.indexOf('/')))
      }
      if (setTag?.toString() !== (() => {}).toString() && !!setTag) {
        const tag = file.type.slice(file.type.indexOf('/') + 1)
        if (tag === 'mpeg') {
          setTag('mp3')
        } else {
          setTag(tag)
        }
      }
      if (!!setFieldValue && setFieldValue.toString() !== (() => {}).toString())
        setFieldValue(type, file.originFileObj)
      else formik?.setFieldValue(type, file.originFileObj)
      getBase64(file.originFileObj, () => {})
    }

    const settingValue = (key: string, value: any) => {
      if (!!setFieldValue && setFieldValue.toString() !== (() => {}).toString())
        setFieldValue(key, value)
      else formik?.setFieldValue(key, value)
    }

    return (
      <div className={cn(className, !isButton ? styles.uploader : '')}>
        {isButton ? (
          <Upload
            id={name}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            multiple={false}
            showUploadList={false}
            ref={ref}>
            <button type="button" className={cn('button-outlined', styles.button)}>
              {t('upload')}
            </button>
          </Upload>
        ) : (
          <>
            <Dragger
              id={name}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              multiple={false}
              showUploadList={false}
            />
            {hasImage && (
              <div
                className={styles.clear}
                onClick={
                  type === 'img'
                    ? () => settingValue('preview', '')
                    : () => settingValue('coverPreview', '')
                }
                onKeyDown={
                  type === 'img'
                    ? () => settingValue('preview', '')
                    : () => settingValue('coverPreview', '')
                }
                role="button"
                tabIndex={0}>
                <img src={ClearImg} alt="" />
              </div>
            )}
          </>
        )}
      </div>
    )
  },
)

Uploader.defaultProps = {
  name: '',
  className: '',
  setFormat: () => {},
  setTag: () => {},
  isButton: false,
  mbytes: 30,
  setFieldValue: () => {},
}

export default Uploader
