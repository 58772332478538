import React from 'react'
import {useTranslation} from 'react-i18next'
import cn from 'classnames'
import moment from 'moment'

import styles from './ActivityItem.module.scss'

interface IActivityItemProps {
  item: any
  activeIndex: number | string
  onClick: () => void
}

const ActivityItem: React.FC<IActivityItemProps> = ({item, onClick, activeIndex}) => {
  const {t} = useTranslation()

  return (
    <div
      className={+activeIndex === 0 ? styles.item : styles.itemNonread}
      onClick={onClick}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}>
      <div className={styles.preview}>
        <img src={item.from_image || item.to_image} alt="preview" />
        {/* <div className={styles.icon} style={{ backgroundColor: item.color }}>
          <img src={item.token_image || item.to_image} alt="Icon notification" />
          <Icon name={type} isConstructed />
        </div> */}
      </div>
      <div className={styles.details}>
        <div className={cn(styles.subtitle)}>
          {item.to_name?.length > 21
            ? `${item.to_name.slice(0, 14)}...${item.to_name.slice(-4)}`
            : item.to_name}
        </div>
        <div className={styles.description}>
          {item.method === 'follow' ? t('follow') : item.method}
        </div>
        <div className={styles.date}>{moment().from(item.date)}</div>
      </div>
    </div>
  )
}

export default ActivityItem
