import i18n from './i18n'

export const validateForm = ({values, notRequired}: any) => {
  interface IRules {
    [key: string]: (value: any) => void
  }
  const errCopy: any = {}

  const rules: IRules = {
    email: (value: string): void => {
      if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        errCopy.email = i18n.t('validate.email')
      }
    },
    img: (value: string): void => {
      if (!value) {
        errCopy.img = i18n.t('validate.img')
      }
    },
    tokenProperties: (value: any): void => {
      errCopy.tokenProperties = [{}]
      let err = false
      value.forEach((item: any, index: number) => {
        if (item.size && !item.amount) {
          err = true
          errCopy.tokenProperties[index] = {
            size: '',
            amount: i18n.t('validate.propValue'),
          }
        }
        if (item.amount && !item.size) {
          err = true
          errCopy.tokenProperties[index] = {
            amount: '',
            size: i18n.t('validate.propName'),
          }
        }
        if (item.amount && item.size) {
          errCopy.tokenProperties[index] = {
            amount: '',
            size: '',
          }
          errCopy.tokenProperties.push({
            amount: '',
            size: '',
          })
        }
      })
      if (!err) {
        delete errCopy.tokenProperties
      }
    },
    instantSalePriceEth: (value: string): void => {
      if (!value) {
        errCopy.instantSalePriceEth = i18n.t('validate.price1')
      }
      if (+value < 0.001) {
        errCopy.instantSalePriceEth = i18n.t('validate.price2')
      }
    },
    bid: (value: string): void => {
      if (!value || !+value) {
        errCopy.bid = i18n.t('validate.bid')
      }
    },
    videoLink: (value: string): void => {
      if (!value) {
        errCopy.videoLink = i18n.t('validate.video')
      }
    },
    quantity: (value: string): void => {
      if (!value) {
        errCopy.quantity = i18n.t('validate.quantity1')
      }
      if (values.available && +value > +values.available) {
        errCopy.quantity = i18n.t('validate.quantity2')
      }
    },
    about: (value: string): void => {
      if (!value) {
        errCopy.about = i18n.t('validate.about')
      }
    },
    twitter: (value: string): void => {
      if (!value) {
        errCopy.twitter = i18n.t('validate.twitter')
      }
    },
    instagram: (value: string): void => {
      if (!value) {
        errCopy.instagram = i18n.t('validate.inst')
      }
    },

    website: (value: string): void => {
      if (!value) {
        errCopy.instagram = i18n.t('validate.website')
      }
    },

    numberOfCopies: (value: string): void => {
      if (!value || !+value || +value <= 0) {
        errCopy.numberOfCopies = i18n.t('validate.numberOfCopies')
      }
    },

    tokenRoyalties: (value: string): void => {
      if (!value) {
        errCopy.tokenRoyalties = i18n.t('validate.tokenRoyalties1')
      }
      if (+value > 10) {
        errCopy.tokenRoyalties = i18n.t('validate.royaltiesMaxLimitToTenPercent')
      }
      if (!Number.isInteger(+value)) {
        errCopy.tokenRoyalties = i18n.t('validate.tokenRoyalties3')
      }
    },
    symbol: (value: string): void => {
      if (!value) {
        errCopy.symbol = i18n.t('validate.symbol1')
      }
      if (value.length > 30) {
        errCopy.symbol = i18n.t('validate.symbol2')
      }
    },
    tokenName: (value: string): void => {
      if (!value) {
        errCopy.tokenName = i18n.t('validate.tokenName')
      }
    },
    digitalKey: (value: string): void => {
      if (!value) {
        errCopy.digitalKey = i18n.t('validate.digitalKey')
      }
    },
    full_address: (value: string): void => {
      if (!value) {
        errCopy.full_address = i18n.t('validate.full_address')
      }
    },
    username: (value: string): void => {
      if (!value) {
        errCopy.username = i18n.t('validate.username')
      }
    },
    message: (value: string): void => {
      if (!value) {
        errCopy.message = i18n.t('validate.message')
      }
    },
    firstname: (value: string): void => {
      if (!value) {
        errCopy.firstname = i18n.t('validate.firstname')
      }
    },
    lastname: (value: string): void => {
      if (!value) {
        errCopy.lastname = i18n.t('validate.lastname')
      }
    },
  }

  Object.keys(values).forEach(
    (key: any) => rules[key] && !notRequired.includes(key) && rules[key](values[key]),
  )

  return errCopy
}

export const validateField = (key: any, touched: any, errors: any) => {
  if (touched[key]) {
    if (errors[key]) {
      return 'error'
    }
    return 'success'
  }
  return ''
}
