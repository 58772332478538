import classNames from 'classnames'

import Icon from '../Icon'

import styles from './InfoPanel.module.scss'

interface IInfoPanelProps {
  className?: string
  message: string
}

const InfoPanel: React.FC<IInfoPanelProps> = ({className, message}) => (
  <div className={classNames(styles.note, className)}>
    <div className={styles.infoIcon}>
      <Icon name="info-circle" size="15" />
    </div>
    {message}
  </div>
)

export default InfoPanel
