import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {Form, notification} from 'antd'
import cn from 'classnames'
import {FormikProps} from 'formik'
import {observer} from 'mobx-react-lite'

import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import TextArea from '../../../components/TextArea'
import TextInput from '../../../components/TextInput'
import Uploader from '../../../components/Uploader'
import {userApi} from '../../../services/api'
import {useMst} from '../../../store/store'
import {validateField} from '../../../utils/validate'

import styles from './ProfileEdit.module.scss'

export interface IProfile {
  displayName?: string
  customUrl?: string
  bio?: string
  site?: string
  twitter?: string
  instagram?: string
  facebook?: string
  email?: string
  img?: any
  preview?: string
  isLoading: boolean
}

const Profile: React.FC<FormikProps<IProfile>> = observer(
  ({touched, errors, handleChange, handleBlur, values, handleSubmit, resetForm}) => {
    const {t} = useTranslation()
    const {user} = useMst()

    const verifyUser = useCallback(() => {
      userApi
        .verifyMe(values, user.address)
        .then(() => notification.success({message: 'Verification request sent'}))
        .catch(({response}) => notification.error({message: response.data.error}))
    }, [values, user.address])

    const onSubmit = () => {
      handleSubmit()
    }
    return (
      <Form name="form-profile" layout="vertical" className={styles.row}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              {!!values.img || !!user.avatar ? (
                <img alt="" src={values.preview ? values.preview : user.avatar || ''} />
              ) : (
                <Skeleton className={styles.avatar} circle />
              )}
            </div>
            <div className={styles.details}>
              <div className={styles.stage}>{t('profile_edit.photo')}</div>
              <div className={styles.text}>
                {t('profile_edit.text4')}{' '}
                <span role="img" aria-label="hooray">
                  🙌
                </span>
              </div>
              <div className={styles.fileUpload}>
                <Form.Item
                  name="img"
                  validateStatus={validateField('img', touched, errors)}
                  help={!touched.img ? false : errors.img}
                  // required
                >
                  <Uploader type="img" isButton mbytes={5} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.category}>{t('profile_edit.info')}</div>
          <Form.Item name="displayName" className={styles.field} initialValue={values.displayName}>
            <div>
              <TextInput
                className={styles.input}
                label={t('profile_edit.name')}
                name="displayName"
                type="text"
                // required
                value={values.displayName}
                placeholder={t('profile_edit.name_place')}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="customUrl"
            className={styles.field}
            initialValue={values.customUrl}
            validateStatus={validateField('customUrl', touched, errors)}
            help={!touched.customUrl ? false : errors.customUrl}>
            <div>
              <TextInput
                className={styles.input}
                name="customUrl"
                label={t('profile_edit.url')}
                value={values.customUrl}
                type="text"
                placeholder={t('profile_edit.url_place')}
                onChange={handleChange}
                onBlur={handleBlur}
                prefix="app.kephi.io/"
              />
            </div>
          </Form.Item>
          <Form.Item name="bio" className={styles.field} initialValue={values.bio}>
            <div>
              <TextArea
                label="Bio"
                name="bio"
                value={values.bio}
                placeholder={t('profile_edit.bio_place')}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <div className={styles.category}>Social</div>
          <Form.Item name="site" className={styles.field} initialValue={values.site}>
            <div>
              <TextInput
                className={styles.input}
                label={t('profile_edit.website')}
                placeholder={t('profile_edit.website_place')}
                name="site"
                type="text"
                value={values.site}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <Form.Item name="twitter" className={styles.field} initialValue={values.twitter}>
            <div>
              <TextInput
                className={styles.input}
                name="twitter"
                label="twitter"
                type="text"
                value={values.twitter}
                placeholder={t('profile_edit.twitter_place')}
                onChange={handleChange}
                onBlur={handleBlur}
                prefix="@"
              />
            </div>
          </Form.Item>
          <Form.Item name="instagram" className={styles.field} initialValue={values.instagram}>
            <div>
              <TextInput
                className={styles.input}
                name="instagram"
                label="instagram"
                type="text"
                value={values.instagram}
                placeholder={t('profile_edit.inst_place')}
                onChange={handleChange}
                onBlur={handleBlur}
                prefix="@"
              />
            </div>
          </Form.Item>
          <Form.Item name="facebook" className={styles.field} initialValue={values.facebook}>
            <div>
              <TextInput
                className={styles.input}
                name="facebook"
                label="facebook"
                type="text"
                value={values.facebook}
                placeholder={t('profile_edit.fb_place')}
                onChange={handleChange}
                onBlur={handleBlur}
                prefix="@"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="email"
            className={styles.field}
            initialValue={values.email}
            validateStatus={validateField('email', touched, errors)}
            help={!touched.email ? false : errors.email}>
            <div>
              <TextInput
                className={styles.input}
                name="email"
                label="email"
                type="text"
                value={values.email}
                placeholder={t('profile_edit.email_place')}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Form.Item>
          <div className={styles.categorySecond}>{t('profile_edit.verif')}</div>
          <div className={styles.note}>{t('profile_edit.text6')}</div>
          <Button
            className={cn('button', styles.button, styles.verified)}
            onClick={() => {
              verifyUser()
            }}
            disabled={!values.email || !!errors.email}>
            {t('profile_edit.verif_btn')}
          </Button>
          <div className={styles.btns}>
            <Button
              onClick={onSubmit}
              className={cn('button', styles.button)}
              disabled={!!Object.keys(errors).length}
              loading={values.isLoading}>
              {t('profile_edit.btn')}
            </Button>
            <Button onClick={resetForm} className={styles.clear}>
              <Icon name="circle-close" size="24" />
              {t('create_form.clear')}
            </Button>
          </div>
        </div>
      </Form>
    )
  },
)

export default Profile
