import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import {capitalizeFirstLetter} from '../../../../utils/capitalizeFirstLetter'

import styles from './Preview.module.scss'

interface IPreviewProps {
  className?: string
  mediaURL?: string
  currency: string
  onClear?: () => void
  name?: string
  price?: string
  format?: string
  bid?: string
  amount?: string
  avatar?: string
  onCreateNft?: () => void
  isLoading: boolean
}

const Preview: React.FC<IPreviewProps> = ({
  className,
  onClear,
  mediaURL,
  name,
  price,
  currency,
  bid,
  amount,
  avatar,
  onCreateNft,
  isLoading,
}) => {
  const {t} = useTranslation()
  return (
    <div className={classNames(className, styles.wrap)}>
      <div className={styles.inner}>
        <div className={styles.info}>{t('create_form.preview')}</div>
        <div className={styles.card}>
          <div className={styles.preview}>
            <img
              // srcSet="/images/content/card-pic-6.jpg"
              src={mediaURL || '/images/content/card-pic-6@2x.jpg'}
              alt="Card"
            />
          </div>
          <div className={styles.link}>
            <div className={styles.body}>
              <div className={styles.line}>
                <div className={styles.title}>{capitalizeFirstLetter(name) || t('card.title')}</div>
                {!!price && (
                  <div className={styles.price}>
                    <div className={styles.innerPrice}>
                      <span className={styles.innerText}>
                        {price} {currency}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.line}>
                <div className={styles.users}>
                  <div className={styles.avatar}>
                    <img src={avatar || '/images/content/avatar-1.jpg'} alt="Avatar" />
                  </div>
                </div>
                <div className={styles.counter}>
                  {amount || 1} {t('card.in_stock')}
                </div>
              </div>
            </div>
            <div className={styles.foot}>
              {bid && (
                <>
                  <div className={styles.status}>
                    <Icon name="candlesticks-up" size="20" />
                    {t('card.highest_bid')} <span>{`${bid} ${currency}`}</span>
                  </div>
                  <div className={styles.bid}>
                    <span className={styles.bidLabel}>{t('create_form.newBid')}</span>
                    <span className={styles.fire} role="img" aria-label="fire">
                      {' '}
                      🔥
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Button onClick={onClear} className={styles.clear}>
          <Icon name="circle-close" size="24" />
          {t('create_form.clear')}
        </Button>
        <Button
          onClick={onCreateNft}
          className={classNames('button-gradient', styles.create)}
          loading={isLoading}>
          {t('create_form.create')}
        </Button>
      </div>
    </div>
  )
}

export default Preview
