import {useTranslation} from 'react-i18next'
import cn from 'classnames'
import {observer} from 'mobx-react'

import {ProfileForm} from '../../forms'

import styles from './ProfileEdit.module.scss'

const ProfileEdit: React.FC = observer(() => {
  const {t} = useTranslation()
  return (
    <div className={styles.page}>
      <div className={cn('section-pt80', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.top}>
            <h1 className={cn('h2', styles.title)}>{t('profile_edit.title')}</h1>
            <div className={styles.info}>
              {t('profile_edit.text1')} <strong>{t('profile_edit.text2')}</strong>{' '}
              {t('profile_edit.text3')}
            </div>
          </div>
          <ProfileForm />
        </div>
      </div>
    </div>
  )
})

export default ProfileEdit
