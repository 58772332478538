import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, withRouter} from 'react-router-dom'
import {NavHashLink} from 'react-router-hash-link'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import moment from 'moment'

import {activityApi} from '../../services/api'
import {useMst} from '../../store/store'
import DropdownLanguage from '../DropdownLanguage/DropdownLanguage'
import Icon from '../Icon'
import Image from '../Image'
import LogIn, {LoginModal} from '../LogIn/LogIn'
import Searchbar from '../Searchbar/Searchbar'
import Theme from '../Theme'

import User from './User'

import styles from './Header.module.scss'

const Headers: React.FC<any> = observer(({history}) => {
  const [visibleNav, setVisibleNav] = useState(false)
  const {
    user: {address},
  } = useMst()
  const {t, i18n} = useTranslation()
  const [hasNotifications, setHasNotifications] = useState<boolean>(false)
  const [isScrollTop, setIsScrollTop] = useState(false)
  const [activeLink, setActiveLink] = useState<number>()
  const [headerRelative, setHeaderRelative] = useState<boolean>(false)
  const [modalLogInVisible, showModalLogIn] = useState<boolean>(false)

  if (localStorage.getItem('darkMode') !== 'true') localStorage.setItem('darkMode', 'true')
  document.body.className = 'dark-mode'

  const languages = useCallback(
    () => [
      {key: 'EN', label: t('language.english')},
      {key: 'ES', label: t('language.spanish')},
    ],
    [t],
  )

  const [language, setLanguage] = useState(
    localStorage.kephi_lang && localStorage.kephi_lang === 'en' ? languages()[0] : languages()[1],
  )

  const navs = [
    {
      id: 1,
      url: '/',
      title: t('header.menu.explore'),
      navHash: 'explore',
      alwaysActive: true,
    },
    {
      id: 2,
      url: '/',
      title: t('header.menu.create_nft'),
      alwaysActive: true,
      onClick: () => {
        if (!address) showModalLogIn(true)
        else history.push('/upload-variants')
      },
    },
    {
      id: 3,
      url: 'http://academy.kephi.io/',
      title: t('header.menu.help'),
      alwaysActive: true,
      redirect: true,
    },
  ]

  const handleChangeLang = (lang: {key: string; label: string}) => {
    setLanguage(lang)
    if (lang.key === 'EN') {
      i18n.changeLanguage('en')
      localStorage.kephi_lang = 'en'
      moment.locale('en')
    } else {
      i18n.changeLanguage('es')
      localStorage.kephi_lang = 'es'
      moment.locale('es')
    }
  }

  const fetchNotification = useCallback(() => {
    activityApi.getNotification().then(({data}: any) => {
      setHasNotifications(data.length)
    })
  }, [])

  const handleClickLink = (id: number) => {
    if (visibleNav) setVisibleNav(!visibleNav)
    setActiveLink(id)
  }

  useEffect(() => {
    if (address) fetchNotification()
    const time = setInterval(() => {
      if (address) {
        fetchNotification()
      }
    }, 60000)
    return () => clearInterval(time)
  }, [address, fetchNotification])

  useEffect(() => {
    if (localStorage.kephi_lang) {
      i18n.changeLanguage(localStorage.kephi_lang)
      moment.locale(localStorage.kephi_lang)
      setLanguage(localStorage.kephi_lang === 'es' ? languages()[1] : languages()[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, localStorage.kephi_lang])

  useEffect(() => {
    const isHomePage = history.location.pathname === '/'
    const isGalleryPage = history.location.pathname.startsWith('/gallery/')
    if (isGalleryPage) setHeaderRelative(true)
    else setHeaderRelative(false)
    if (!isHomePage) {
      setIsScrollTop(true)
    } else if (isHomePage && window.pageYOffset <= 700) {
      setIsScrollTop(false)
    }
    const handleScroll = () => {
      if (isHomePage && window.pageYOffset > 700 && !isScrollTop) setIsScrollTop(true)
      else if (isHomePage && window.pageYOffset <= 700 && isScrollTop) setIsScrollTop(false)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isScrollTop, history.location.pathname, headerRelative])

  return (
    <>
      <header
        className={classNames(styles.header, {
          [styles.active]: isScrollTop,
          [styles.relative]: headerRelative,
        })}>
        <div className={classNames('container', styles.container)}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/kephi-logo-dark.svg"
              srcSetDark="/images/kephi-logo-light.svg"
              alt="Logo with description"
            />
            <Image className={styles.pic} src="/images/logo.svg" alt="Logo" />
          </Link>
          <div className={classNames(styles.wrapper, {[styles.active]: visibleNav})}>
            <nav className={styles.nav}>
              {navs.map(nav => {
                if (nav.redirect)
                  return (
                    <a key={nav.id} href={nav.url} target="_blank" rel="noopener noreferrer">
                      <div
                        className={classNames(styles.link, {
                          [styles.disabled]: !nav.alwaysActive && !address,
                          [styles.active]: activeLink === nav.id,
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={() => handleClickLink(nav.id)}
                        onKeyDown={() => {}}>
                        {nav.title}
                      </div>
                    </a>
                  )
                return nav.navHash ? (
                  <NavHashLink
                    className={classNames(styles.link, {[styles.active]: activeLink === nav.id})}
                    to={`/#${nav.navHash}`}
                    key={nav.id}
                    onClick={() => handleClickLink(nav.id)}
                    smooth>
                    {nav.title}
                  </NavHashLink>
                ) : (
                  <div
                    className={classNames(styles.link, {
                      [styles.disabled]: !nav.alwaysActive && !address,
                      [styles.active]: activeLink === nav.id,
                    })}
                    onKeyDown={() => {}}
                    tabIndex={0}
                    role="link"
                    key={nav.id}
                    onClick={() => {
                      handleClickLink(nav.id)
                      if (nav.onClick) nav.onClick()
                    }}>
                    {nav.title}
                  </div>
                )
              })}
            </nav>
            {!!address && (
              <>
                <Link className={classNames('button-small', styles.button)} to="/upload-variants">
                  {t('header.upload')}
                </Link>
              </>
            )}
          </div>
          <Searchbar className={classNames(styles.searchBar)} />
          <div className={classNames(styles.col, styles.colMargin)}>
            <div className={classNames(styles.dropdownWrapper)}>
              <DropdownLanguage
                setValue={(selectedStr: {key: string; label: string}) =>
                  handleChangeLang(selectedStr)
                }
                value={language}
                options={languages()}
                customClasses={{
                  head: styles.languageDropdownHead,
                  arrow: styles.languageDropdownArrow,
                }}
              />
            </div>
          </div>
          {!!address && (
            <Link
              to="/activity"
              className={classNames(styles.head, {[styles.active]: hasNotifications})}>
              <Icon name="notification" size="24" />
            </Link>
          )}
          {!address ? (
            <LogIn className={classNames('button-small', styles.loginButton)} withIcon />
          ) : (
            <User className={styles.user} />
          )}
          {!!address && (
            <Link className={classNames('button-small', styles.button)} to="/upload-variants">
              {t('header.create')}
            </Link>
          )}
          <button
            type="button"
            aria-label="Toogle visibility"
            tabIndex={0}
            onKeyDown={() => {}}
            className={classNames(styles.burger, {[styles.active]: visibleNav})}
            onClick={() => setVisibleNav(!visibleNav)}
          />
          <div className={styles.themContainer}>
            <Theme className={styles.theme} />
          </div>
        </div>
      </header>

      <LoginModal modalVisible={modalLogInVisible} showModal={showModalLogIn} />
    </>
  )
})

export default withRouter(Headers)
