import React from 'react'
import cn from 'classnames'

import {NavLinkType} from '../../types/navLink'

import styles from './NavLink.module.scss'

interface INavLinkProps {
  navLinks: NavLinkType[] | string[]
  activeLink: any
  className?: string
  onClick: (value: any) => void
}

const NavLink = React.memo<INavLinkProps>(
  ({className, navLinks, activeLink, onClick}) => {
    return (
      <div className={cn(styles.nav, className)}>
        {!!navLinks?.length &&
          navLinks.map((navLink: any, index) => (
            <button
              type="button"
              className={cn(styles.link, {
                [styles.active]:
                  navLink.id !== undefined
                    ? navLink.id === activeLink
                    : navLink.label === activeLink,
              })}
              onClick={(event: any) => {
                return navLink.id !== undefined
                  ? onClick(navLink.id)
                  : onClick(event.target.innerHTML)
              }}
              key={navLink.id !== undefined ? navLink.id : index}>
              {navLink.label}
            </button>
          ))}
      </div>
    )
  },
  (prevProps, nextProps) => {
    const someOfNavLinksChanged =
      !!prevProps.navLinks?.length &&
      (prevProps.navLinks as any[]).every(prevNavLink =>
        nextProps.navLinks?.some((nextNavLink: any) => prevNavLink.label === nextNavLink?.label),
      )
    return (
      prevProps.activeLink === nextProps.activeLink &&
      prevProps.navLinks?.length === nextProps.navLinks?.length &&
      someOfNavLinksChanged
    )
  },
)

export default NavLink
