import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import Icon from '../../../../../components/Icon'
import TextInput from '../../../../../components/TextInput'
import FilterIcons from '../../../../Gallery/components/FilterIcons/FilterIcons'
import {IProperty} from '../../../types/CreateFormData'
import {ICreateForm} from '../../../types/Stepper'

import Advance from './components/Advance/Advance'

import styles from './ItemDetails.module.scss'

const NAME = 'name'
const DESCRIPTION = 'description'
const TAG = 'tag'
const TOTAL_SUPPLY = 'total_supply'
const TOKEN_PROPERTIES = 'tokenProperties'

export type ItemDetailsForm = {
  name: string
  description: string
  tag: string
  total_supply: number | string // numberOfCopies
  tokenProperties: IProperty[]
}

const ItemDetailsStep: React.FC<ICreateForm> = ({
  lastStep,
  nextStep,
  onSubmit,
  currentData,
  isSingle,
}) => {
  const {t} = useTranslation()
  const [advanceVisible, showAdvance] = useState<boolean | undefined>()

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: {errors},
    clearErrors,
    trigger,
    setError,
  } = useForm<ItemDetailsForm>({
    defaultValues: {
      name: currentData.name,
      description: currentData.description,
      total_supply: currentData.total_supply,
      tokenProperties: currentData.tokenProperties,
      tag: currentData.tag,
    },
  })

  const [tokenProperties] = watch([TOKEN_PROPERTIES])

  const handleApply = (data: ItemDetailsForm) => {
    const details: any = {}
    data.tokenProperties.forEach(item => {
      if (item.size) {
        details[item.size] = item.amount
      }
    })
    const dataParse = {
      ...data,
      details: JSON.stringify(details),
    }
    onSubmit(dataParse)
    nextStep()
  }

  const handleAddProperty = () => {
    trigger('tokenProperties')
    setValue(TOKEN_PROPERTIES, [
      ...tokenProperties,
      {
        size: '',
        amount: '',
      },
    ])
  }
  const handleRemoveProperty = (indexToDelete: number) => {
    trigger('tokenProperties')
    setValue(TOKEN_PROPERTIES, [
      ...tokenProperties.filter((_tokenProperty, index) => index !== indexToDelete),
    ])
  }

  useEffect(() => {
    if (currentData.isDuplicateName)
      setError(NAME, {type: 'custom', message: t('create_form.duplicatedName')})
  }, [currentData.isDuplicateName, setError, t])

  return (
    <form className={styles.container} onSubmit={handleSubmit(handleApply)}>
      <div className={styles.title}>{t('create_form.details')}</div>
      <Controller
        control={control}
        name={NAME}
        rules={{
          required: {value: true, message: t('validate.tokenName')},
          validate: value => value !== '',
        }}
        render={({field: {name, value, onChange, onBlur}}) => (
          <TextInput
            className={styles.item}
            label={t('create_form.item_name')}
            name={name}
            type="text"
            placeholder={t('create_form.text3')}
            value={value}
            onChange={(inputValue: string) => {
              onChange(inputValue)
              clearErrors()
            }}
            onBlur={onBlur}
            error={!!errors[name]}
            helperText={errors[name]?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={DESCRIPTION}
        render={({field: {name, value, onChange, onBlur}}) => (
          <TextInput
            className={styles.item}
            label={t('create_form.descr')}
            name={name}
            type="text"
            placeholder={t('create_form.text4')}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
      {!isSingle && (
        <Controller
          control={control}
          name={TOTAL_SUPPLY}
          rules={{
            required: {value: true, message: t('validate.numberOfCopies')},
            validate: value => !value || !+value || +value >= 0,
          }}
          render={({field: {name, value, onChange, onBlur}}) => (
            <TextInput
              className={styles.item}
              label={t('create_form.item_amount')}
              name={name}
              type="number"
              min={0}
              placeholder={t('create_form.text5')}
              value={value.toString()}
              onChange={inputValue => {
                clearErrors()
                onChange(inputValue)
              }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={errors[name]?.message}
            />
          )}
        />
      )}
      <div className={classNames(styles.item, styles.categoryContainer)}>
        <div className={styles.label}>{t('create_form.category')}</div>
        <Controller
          control={control}
          name={TAG}
          rules={{
            required: {value: true, message: t('validate.category')},
            validate: value => value !== '',
          }}
          render={({field: {name, value, onChange}}) => (
            <>
              <FilterIcons
                className={styles.filtersContainer}
                onChangeFilterIcon={onChange}
                filterIconSelected={value}
                withoutAllItemsIcon
                error={!!errors[name] && !!errors[name]?.message}
              />
              <div
                className={classNames(styles.errorContainer, {
                  [styles.error]: !!errors[name] && !!errors[name]?.message,
                })}>
                <div role="alert" className={styles.errorLabel}>
                  {errors[name]?.message}
                </div>
              </div>
            </>
          )}
        />
      </div>
      <button
        className={classNames(styles.advanceHeader, {[styles.active]: advanceVisible})}
        tabIndex={0}
        type="button"
        onClick={() => showAdvance(!advanceVisible)}>
        <div className={styles.headerLabel}>
          {t('advance')}{' '}
          <div className={styles.optional}>{advanceVisible ? `(${t('optional')})` : ''}</div>
        </div>
        <Icon name="arrow-bottom" size="14" />
      </button>
      <Advance
        control={control}
        errors={errors.tokenProperties}
        className={classNames(
          styles.advanceForm,
          {[styles.visible]: advanceVisible},
          {[styles.hidden]: advanceVisible === false},
        )}
        onAddProperty={handleAddProperty}
        onRemoveProperty={handleRemoveProperty}
        currentProperties={tokenProperties}
        advanceVisible={advanceVisible}
      />
      <div className={styles.buttonsRow}>
        {lastStep && (
          <button
            className={classNames('button-outlined-gradient', styles.backButton)}
            type="button"
            onClick={() => lastStep()}>
            {t('back')}
          </button>
        )}
        <button className={classNames('button-gradient', styles.nextbutton)} type="submit">
          {t('next')}
        </button>
      </div>
    </form>
  )
}

export default ItemDetailsStep
