import {useTranslation} from 'react-i18next'
import {NavHashLink} from 'react-router-hash-link'
import cn from 'classnames'

import Collection from '../../../components/Collection/Collection'
import {PROFILE_ITEMS_PER_PAGE} from '../../../constants/constants'
import {CollectionSkeleton} from '../../Home/Collections/Skeleton/CollectionsSkeleton'

import styles from './UserCollections.module.scss'

interface UserCollectionsProps {
  className?: string
  collections: any
  isLoading: boolean
  isLoadingMore: boolean
  isFetching: boolean
  isMe: boolean
}

const UserCollections: React.FC<UserCollectionsProps> = ({
  className,
  collections,
  isLoading,
  isLoadingMore,
  isFetching,
  isMe,
}) => {
  const {t} = useTranslation()

  const renderCollections = () => {
    return (
      !!collections?.length &&
      collections.map((collection: any) => (
        <div className={styles.card} key={collection.id}>
          <Collection key={collection.id} collection={collection} />
        </div>
      ))
    )
  }

  const collectionsSkeletons = Array.from(
    {length: PROFILE_ITEMS_PER_PAGE},
    (_value, index) => index,
  )

  return (
    <div className={cn(styles.items, className)}>
      <div className={styles.list}>
        {(!isLoading || isLoadingMore || isFetching) && renderCollections()}
        {(isLoading || isLoadingMore) &&
          collectionsSkeletons.map(cardSkeleton => (
            <CollectionSkeleton className={styles.card} key={cardSkeleton} />
          ))}
        {!collections.length &&
          !isLoading &&
          (isMe ? (
            <div className={styles.text}>
              {t('user.noCollectionsMessage')}{' '}
              <NavHashLink smooth to="/#explore" className={styles.textActiveLink}>
                {t('user.here')}
              </NavHashLink>{' '}
              {t('user.to_create')}
            </div>
          ) : (
            <div className={styles.text}>{t('user.noCollectionsMessage')}</div>
          ))}
      </div>
    </div>
  )
}

export default UserCollections
