import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import classNames from 'classnames'

import {ReactComponent as Binance} from '../../../assets/img/icons/binance-icon-logo.svg'
import {ReactComponent as Ethereum} from '../../../assets/img/icons/Ethereum-Logo.svg'
import {ReactComponent as Kardiachain} from '../../../assets/img/icons/kardiachain.svg'
import {ReactComponent as Metamask} from '../../../assets/img/icons/metamask.svg'
import {ReactComponent as Polygon} from '../../../assets/img/icons/polygon-matic-logo.svg'
import {ReactComponent as WalletConnect} from '../../../assets/img/icons/wallet-connect.svg'
import Modal from '../../../components/Modal'
import TermsOfService from '../../../components/TermsOfService'
import {useWalletConnectorContext} from '../../../services/walletConnect'
import {BlockchainType} from '../../../types/blockchain'
import {ProviderType} from '../../../types/provider'

import styles from './ChooseWallet.module.scss'

interface IChainElement {
  name: BlockchainType
  icon: ReactElement
  isNew?: boolean
}
interface IProviderElement {
  title: string
  icon: ReactElement
  chains: IChainElement[]
  provider: ProviderType
  disabledOnMobile?: boolean
  descriptionTranslation?: string
}

const binanceChain: IChainElement = {name: 'Binance', icon: <Binance />}
const polygonChain: IChainElement = {name: 'Polygon', icon: <Polygon />, isNew: true}
const kardiachainChain: IChainElement = {name: 'KardiaChain', icon: <Kardiachain />}
const ethereumChain: IChainElement = {name: 'Ethereum', icon: <Ethereum />, isNew: true}
const chains: Array<IChainElement> = [ethereumChain, polygonChain, binanceChain, kardiachainChain]

const providers: Array<IProviderElement> = [
  {
    title: 'Metamask',
    icon: <Metamask />,
    chains: [ethereumChain, binanceChain, polygonChain, kardiachainChain],
    provider: 'MetaMask',
    disabledOnMobile: true,
  },
  {
    title: 'Wallet Link',
    icon: <WalletConnect />,
    chains: [polygonChain],
    provider: 'WalletLink',
  },
  {
    title: 'Wallet Connect',
    icon: <WalletConnect />,
    chains: [binanceChain, polygonChain, ethereumChain],
    provider: 'WalletConnect',
    descriptionTranslation: 'connect.walletConnectDescription',
  },
  {
    title: 'Kardiachain Wallet',
    icon: <Kardiachain />,
    chains: [kardiachainChain],
    provider: 'KardiaChain',
    descriptionTranslation: 'connect.kardiachainDescription',
  },
]

const MAX_WIDTH_MOBILE = 1179
interface IChooseWalletProps {
  activeChain: BlockchainType
}

const ChooseWallet: React.FC<IChooseWalletProps> = ({activeChain}) => {
  const {t} = useTranslation()
  const {connect} = useWalletConnectorContext()
  const [isVisibleTermsOfService, setIsVisibleTermsOfService] = useState(false)
  const [chainSelected, setChainSelected] = useState<BlockchainType>(activeChain)
  const [filteredProviders, setFilteredProviders] = useState<IProviderElement[]>([])
  const [activeProvider, setActiveProvider] = useState<ProviderType>('MetaMask')
  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )

  const closeTermsOfService = () => setIsVisibleTermsOfService(false)

  const handleConnect = async (chain?: BlockchainType, provider?: ProviderType) => {
    try {
      await connect(chain || chainSelected, provider || activeProvider)
      localStorage.kephi_nft_terms = true
      closeTermsOfService()
    } catch (err) {
      console.log(err, 'connect')
    }
  }

  const openTermsOfService = (chain: BlockchainType, provider: ProviderType) => {
    if (!localStorage.kephi_nft_terms) {
      setActiveProvider(provider)

      setIsVisibleTermsOfService(true)
    } else {
      handleConnect(chain, provider)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])

  useEffect(() => {
    setFilteredProviders(
      providers.filter(provider => provider.chains.some(chain => chain.name === chainSelected)),
    )
  }, [chainSelected])

  return (
    <>
      <div className={styles.head}>
        <div className={styles.back}>
          <div className={styles.stage}>{t('connect.title')}</div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.description}>
          <span>{t('connect.description1')}</span>{' '}
          <a
            className={styles.textActiveLink}
            href="https://kephigallery.medium.com/wallet-tutorial-111d7c7bfd82"
            target="_blank"
            rel="noreferrer">
            {t('connect.wallet')}
          </a>{' '}
          <span>{t('connect.description2')}</span>
        </p>
        <div className={styles.childContainer}>
          <div className={styles.titleContainer}>{t('connect.chooseYourNetwork')}</div>
          <div className={styles.tabs}>
            {chains.map(chain => (
              <button
                key={chain.name}
                type="button"
                onClick={() => setChainSelected(chain.name)}
                className={classNames('button-outlined-gradient', styles.chainButton, {
                  [styles.active]: chain.name === chainSelected,
                })}>
                <div className={styles.tabTitle}>
                  <div className={styles.tabIcon}>{chain.icon}</div>
                  {chain.name}
                  {!!chain.isNew && <div className={styles.new}>NEW</div>}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className={styles.childContainer}>
          <div className={styles.titleContainer}>{t('connect.chooseYourWallet')}</div>
          <div className={styles.menu}>
            {filteredProviders.map(filteredProvider => (
              <div
                className={classNames(styles.linkContainer)}
                key={nextId()}
                hidden={isMobileWidthResolution && filteredProvider.disabledOnMobile}>
                <button
                  className={classNames(styles.link)}
                  type="button"
                  onClick={() => openTermsOfService(chainSelected, filteredProvider.provider)}>
                  <div className={styles.icon}>{filteredProvider.icon}</div>
                  <div className={styles.menuItemText}>
                    <div>{filteredProvider.title}</div>
                    {isMobileWidthResolution && !!filteredProvider.descriptionTranslation && (
                      <div className={styles.menuItemDescription}>
                        {`(${t(filteredProvider.descriptionTranslation)})`}
                      </div>
                    )}
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <p className={styles.disclaimer}>{t('connect.text2')}</p> */}

      <Modal visible={isVisibleTermsOfService} onClose={closeTermsOfService} closeOnRight>
        <TermsOfService
          handleConnect={() => handleConnect(activeChain, activeProvider)}
          handleCancel={closeTermsOfService}
        />
      </Modal>
    </>
  )
}

export default ChooseWallet
