import {t} from 'i18next'

import {ICollection, ISearchResponse, IToken, IUser} from '../../../types'
import SearchResultSkeleton from '../SearchResultSkeleton/SearchResultSkeleton'
import SearchRow from '../SearchRow/SearchRow'

import styles from './SearchResult.module.scss'

interface ISearchResultProps {
  className?: string
  searchResponse: ISearchResponse
  searching: boolean
}

const SearchResult: React.FC<ISearchResultProps> = ({className, searchResponse, searching}) => (
  <div className={className}>
    {searching ? (
      <SearchResultSkeleton className={styles.container} />
    ) : (
      <div className={styles.container}>
        <div className={styles.categoryRow}>{t('collections.name')}</div>
        {searchResponse.collections.length ? (
          searchResponse.collections.map((collection: ICollection) => (
            <SearchRow
              key={collection.id}
              image={collection.avatar}
              name={collection.name}
              address={collection.address}
              url={`/collection/${collection.id}`}
            />
          ))
        ) : (
          <div className={styles.notFoundMessage}>{t('collections.notFound')}</div>
        )}
        <div className={styles.categoryRow}>{t('tokens.name')}</div>
        {searchResponse.tokens.length ? (
          searchResponse.tokens.map((token: IToken) => (
            <SearchRow
              key={token.id}
              image={token.media}
              name={token.name}
              url={`/item/${token.id}`}
            />
          ))
        ) : (
          <div className={styles.notFoundMessage}>{t('tokens.notFound')}</div>
        )}
        <div className={styles.categoryRow}>{t('users.name')}</div>
        {searchResponse.users.length ? (
          searchResponse.users.map((user: IUser) => (
            <SearchRow
              key={user.id}
              image={user.avatar}
              name={user.display_name}
              address={user.address}
              url={`/profile/${user.id}`}
            />
          ))
        ) : (
          <div className={styles.notFoundMessage}>{t('users.notFound')}</div>
        )}
      </div>
    )}
  </div>
)
export default SearchResult
