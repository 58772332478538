import {COIN_KEY, COIN_TYPE, COINS_PER_CHAIN, DEFAULT_COIN_DECIMALS} from '../constants/constants'
import {rootStore} from '../store/store'
import {Coin} from '../types/coinsPerChain'
import {ContractParam} from '../types/contract'

import {capitalizeFirstLetter} from './capitalizeFirstLetter'

// const getCoinUsdTypeValidation = (type: COIN_TYPE, coin: Coin): boolean => {
//   if (type === 'usd' && localStorage.kephi_nft_chainName === BLOCKCHAIN.POLYGON)
//     return coin.name === COIN_KEY.USDC
//   return coin.type === type
// }

export const getCoinNameByType = (type: COIN_TYPE): string =>
  (localStorage.kephi_nft_chainName &&
    rootStore.user?.coins
      .toJSON()
      ?.find(coin => coin.type === type)
      ?.name.toUpperCase()) ||
  ''

export const getCoins = (): Coin[] => rootStore.user?.coins.toJSON() || []

export const getCoinNames = (): string[] =>
  rootStore.user?.coins.toJSON()?.map(coin => capitalizeFirstLetter(coin.name)) || []

export const getAllCoinNames = (): string[] =>
  Object.keys(COIN_KEY).map(key => capitalizeFirstLetter(key))

export const isAnyOfMainCoin = (mainCoinName: string): boolean =>
  COINS_PER_CHAIN.map(
    coinsPerChain => coinsPerChain.coins.filter(coin => coin.type === 'main')[0],
  ).some(mainCoin => mainCoin.name === mainCoinName.toLocaleLowerCase())

export const formatCoinToken = (mainCoinName: string): ContractParam =>
  (mainCoinName.toLocaleLowerCase() === COIN_KEY.KUSD && 'KUSDT') ||
  (mainCoinName.toLocaleUpperCase() as ContractParam)

export const formatCoinName = (coinName: string): string =>
  (coinName.toLocaleLowerCase() === COIN_KEY.KUSD && 'KUSDT') || coinName.toLocaleUpperCase()

export const getCoinDecimalsByType = (coinType: COIN_TYPE): number =>
  rootStore.user?.coins.toJSON()?.filter(coin => coin.type === coinType)[0]?.decimals ||
  DEFAULT_COIN_DECIMALS

export const getCoinDecimalsByName = (coinName: string): number =>
  rootStore.user?.coins
    .toJSON()
    ?.filter(coin => coin.name.toLocaleUpperCase() === formatCoinName(coinName))[0]?.decimals ||
  DEFAULT_COIN_DECIMALS
