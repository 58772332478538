import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import classNames from 'classnames'

import Card from '../../components/Card'
import CardSkeleton from '../../components/Card/Skeleton/CardSkeleton'
import EmptyState from '../../components/EmptyState/EmptyState'
import Icon from '../../components/Icon'
import Modal from '../../components/Modal'
import {GALLERY_ITEMS_PER_PAGE} from '../../constants/constants'
import {galleries} from '../../constants/galleries'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import {storeApi} from '../../services/api'

import Filter from './components/Filter/Filter'
import {FilterFormInputs} from './components/Filter/Model/FilterForm'
import FilterIcons from './components/FilterIcons/FilterIcons'
import MobileFilter from './components/MobileFilter/MobileFilter'

import styles from './Gallery.module.scss'

interface IGalleryId {
  galleryId: string
}

const Gallery = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {galleryId} = useParams<IGalleryId>()
  const [filterModalVisible, showFilterModal] = useState<boolean>(false)
  const [nfts, setNfts] = useState<any>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoadingMore, setLoadingMore] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [time, setTime] = useState<any>(null)
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems)
  const [filterVisible, showFilter] = useState(false)

  const headerOffsetHeight = document.getElementsByTagName('header')[0]?.offsetHeight || 0

  if (localStorage.getItem('darkMode') !== 'true') localStorage.setItem('darkMode', 'true')
  document.body.className = 'dark-mode'

  // Scroll - Dark
  const [isDark, setDark] = useState<boolean>(false)
  useEffect(() => {
    function handleScroll() {
      if (headerOffsetHeight >= document.documentElement.scrollTop && isDark) {
        setDark(false)
      } else if (headerOffsetHeight < document.documentElement.scrollTop && !isDark) {
        setDark(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isDark, headerOffsetHeight])

  // Scroll TOP - Scroll DOWN
  const [scrollDown, setScrollDown] = useState<boolean>(false)
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    function handleScrollDown() {
      if (Math.abs(scrollTop - document.documentElement.scrollTop) <= 30) return
      if (scrollTop < document.documentElement.scrollTop && !scrollDown) {
        setScrollDown(true)
      } else if (scrollTop >= document.documentElement.scrollTop && scrollDown) {
        setScrollDown(false)
      }
      setScrollTop(document.documentElement.scrollTop)
    }

    window.addEventListener('scroll', handleScrollDown)
    return () => window.removeEventListener('scroll', handleScrollDown)
  }, [scrollDown, scrollTop])

  const [currentValues, setCurrentValues] = useState<FilterFormInputs>({
    artType: 'ALL ITEMS',
    minPrice: undefined,
    maxPrice: undefined,
    orderBy: '',
  })

  function fetchMoreListItems() {
    if (
      isLoading ||
      isLoadingMore ||
      !!(Math.ceil(nfts?.length / GALLERY_ITEMS_PER_PAGE) - currentPage)
      // lastPage === currentPage
    )
      return

    setCurrentPage(currentPage + 1)
    setIsFetching(false)
  }

  const getGallerySelected = (id: string): any => galleries.filter(gallery => gallery.id === id)[0]
  const [gallerySelected, setGallerySelected] = useState<any>(getGallerySelected(galleryId))

  const [queries, setQueries] = useState({
    type: 'items',
    order_by: '',
    tags: t('allItems'),
    min_price: undefined,
    max_price: undefined,
    currency: t('all'),
    gallery: getGallerySelected(galleryId)?.key || 'OPEN',
  })

  const onChangeGallery = (id: string): void => {
    if (id === gallerySelected.id) return
    history.replace(`/gallery/${id}`)
    setGallerySelected(getGallerySelected(id))
    handleChange('gallery', getGallerySelected(id).key)
  }

  const onChangeType = (type: string | number): void => {
    if (type === currentValues.artType) return
    setCurrentValues({...currentValues, artType: type})
    handleChange('tags', type)
  }

  const handleChange = (key: string, value: any) => {
    setCurrentPage(1)
    setLoading(true)
    setNfts([])
    setQueries((prevState: any) => ({
      ...prevState,
      [key]: value,
    }))

    if (time) {
      clearTimeout(time)
      setTime(setTimeout(() => fetchSearch({...queries, [key]: value}), 1500))
    } else {
      setTime(setTimeout(() => fetchSearch({...queries, [key]: value}), 1000))
    }
  }

  const fetchSearch = useCallback((queriesObject: any) => {
    setLoading(true)
    setNfts([])
    storeApi
      .getSearchResults({text: '', page: 1}, queriesObject)
      .then(({data}) => {
        setNfts(data.items)
        setLastPage(Math.ceil(data.total_tokens / GALLERY_ITEMS_PER_PAGE) || 1)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const fetchSearchPage = useCallback(() => {
    setLoadingMore(true)
    storeApi
      .getSearchResults({text: '', page: currentPage}, queries)
      .then(({data}) => {
        setNfts(nfts.concat(data.items))
      })
      .finally(() => setLoadingMore(false))
    // eslint-disable-next-line
  }, [currentPage])

  const handleLoadMore = useCallback(() => {
    if (currentPage <= lastPage) {
      fetchSearchPage()
    }
  }, [currentPage, lastPage, fetchSearchPage])

  const handleApply = (data: FilterFormInputs) => {
    setCurrentPage(1)
    setNfts([])
    setLoading(true)
    setQueries((prevState: any) => ({
      ...prevState,
      order_by: data.orderBy,
      tags: data.artType,
      min_price: data.minPrice,
      max_price: data.maxPrice,
    }))
    fetchSearch({
      ...queries,
      order_by: data.orderBy,
      tags: data.artType,
      min_price: data.minPrice ? +data.minPrice : undefined,
      max_price: data.maxPrice ? +data.maxPrice : undefined,
    })
    setCurrentValues(data)
  }

  useEffect(() => {
    fetchSearch(queries)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      handleLoadMore()
    }
  }, [handleLoadMore, currentPage])

  const cardSkeletons = useMemo(
    () => Array.from({length: GALLERY_ITEMS_PER_PAGE}, (_value, index) => index),
    [],
  )

  const renderNfts = useMemo(() => {
    return (
      <div className={styles.list}>
        {!!nfts.length &&
          (!isLoading || isLoadingMore || isFetching) &&
          nfts.map((bid: any) => (
            <Card className={styles.card} item={bid} key={bid.id + bid?.name} />
          ))}
        {(isLoading || isLoadingMore) &&
          cardSkeletons.map(cardSkeleton => (
            <CardSkeleton className={styles.card} key={cardSkeleton} />
          ))}
        {!nfts.length && !isLoading && (
          <div className={styles.noNfts}>
            <EmptyState />
          </div>
        )}
      </div>
    )
  }, [cardSkeletons, isFetching, isLoading, isLoadingMore, nfts])

  return (
    <div className={classNames('section', styles.section)}>
      <div className={classNames(styles.rowSection, {[styles.dark]: isDark})}>
        <div
          className={classNames(styles.tabs, {
            [styles.dark]: isDark,
            [styles.showfloating]: !scrollDown && isDark,
          })}
          id="galleryTabs">
          {galleries.map(gallery => (
            <button
              key={gallery.id}
              type="button"
              onClick={() => onChangeGallery(gallery.id)}
              className={classNames(styles.outlinedButton, styles.galleryButton, {
                [styles.active]: gallery.id === galleryId,
              })}>
              {gallery.label ? t(gallery.label) : `${t(gallery.type)} ${gallery.name}`}
            </button>
          ))}
        </div>
        <div
          className={classNames('container', styles.filterContainer, {[styles.dark]: isDark})}
          id="galleryFilters">
          <div className={classNames(styles.row, {[styles.borderBottomOff]: isDark})}>
            <div className={gallerySelected?.className}>
              {gallerySelected.label ? (
                t(gallerySelected.label)
              ) : (
                <>
                  {t(gallerySelected?.type)} <img src={gallerySelected?.logoUrl} alt="icon" />
                </>
              )}
            </div>
            <FilterIcons
              className={styles.filtersContainer}
              onChangeFilterIcon={onChangeType}
              filterIconSelected={currentValues.artType}
              onlyExistingFilters
            />
            <button
              type="button"
              onClick={() => showFilterModal(true)}
              className={classNames(styles.outlinedButton, styles.filterButton)}>
              <div className={styles.filterLabel}>{t('filter')}</div>{' '}
              <img src="/images/icons/filters/filter-settings.svg" alt="filter" />
            </button>
          </div>
        </div>
      </div>
      <div className={classNames('container', styles.container)}>
        <div className={styles.mobileFilterContainer}>
          <button
            className={classNames(styles.filterHeader, {[styles.active]: filterVisible})}
            tabIndex={0}
            type="button"
            onClick={() => showFilter(!filterVisible)}>
            {t('modalFilter.title')}
            <Icon name="arrow-bottom" size="14" />
          </button>
          <MobileFilter
            className={classNames(styles.mobileFilter, {[styles.visible]: filterVisible})}
            onApply={data => {
              handleApply(data)
              showFilter(!filterVisible)
            }}
            onReset={() => showFilter(!filterVisible)}
            currentValues={currentValues}
            filterVisible={filterVisible}
          />
        </div>
        {renderNfts}
      </div>
      <Modal
        outerClassName={styles.outerContainer}
        containerClassName={styles.modalContainer}
        visible={filterModalVisible}
        onClose={() => showFilterModal(false)}>
        <Filter
          onClose={() => showFilterModal(false)}
          onApply={data => handleApply(data)}
          currentValues={currentValues}
        />
      </Modal>
    </div>
  )
}

export default Gallery
