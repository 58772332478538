import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router'
import {Link} from 'react-router-dom'
import cn from 'classnames'
import {observer} from 'mobx-react'

import Icon from '../../components/Icon'
import NavLink from '../../components/NavLink/NavLink'
import {LOCAL_STORAGE, PROFILE_ITEMS_PER_PAGE, PROFILE_TAB_INDEX} from '../../constants/constants'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import {storeApi, userApi} from '../../services/api'
import {useMst} from '../../store/store'
import myLocalStorage from '../../utils/myLocalStorage'

import UserCollections from './UserCollections/UserCollections'
import Followers from './Followers'
import Items from './Items'
import User from './User'

import styles from './Profile.module.scss'

const Profile: React.FC = observer(() => {
  const {t} = useTranslation()
  const {user} = useMst()
  const history = useHistory()
  const fileRef = useRef<any>()
  const {userId, tab} = useParams<{userId: string; tab: string}>()
  const [activeIndex, setActiveIndex] = useState(PROFILE_TAB_INDEX.ON_SALE)
  const [visible, setVisible] = useState(false)
  const [collectibles, setCollectibles] = useState<any>([])
  const [collections, setCollections] = useState<any>([])
  const [created, setCreated] = useState([])
  const [liked, setLiked] = useState([])
  const [following, setFollowing] = useState([])
  const [followingCount, setFollowingCount] = useState(0)
  const [followers, setFollowers] = useState([])
  const [followersCount, setfollowersCount] = useState(0)
  const [userPhoto, setUserPhoto] = useState('')
  const [userFile, setUserFile] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [shownUser, setShownUser] = useState<{
    id: number | string
    avatar: string
    display_name?: string
    address: string
    cover: string
    followers: Array<any>
    followers_count: number
    follows_count: number
    twitter: string | null
    instagram: string | null
    facebook: string | null
    site: string | null
    bio: string | null
    created_at: any
    is_verificated: boolean
    my_referral_code: string
  }>({
    address: '',
    cover: '',
    id: '',
    avatar: '',
    display_name: '',
    followers: [],
    followers_count: 0,
    follows_count: 0,
    twitter: null,
    instagram: null,
    facebook: null,
    site: null,
    bio: null,
    created_at: '',
    is_verificated: false,
    my_referral_code: '',
  })
  const [isModalShown, setIsModalShown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setLoadingMore] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems)

  function fetchMoreListItems() {
    if (isLoading || isLoadingMore || lastPage === currentPage) return
    handleChangePage(currentPage + 1)
    setIsFetching(false)
  }

  const navLinks = [
    {
      id: PROFILE_TAB_INDEX.ON_SALE,
      label: t('user.onSale'),
    },
    {
      id: PROFILE_TAB_INDEX.COLLECTIONS,
      label: t('user.collections'),
    },
    {
      id: PROFILE_TAB_INDEX.ITEMS,
      label: t('user.items'),
    },
    {
      id: PROFILE_TAB_INDEX.CREATED,
      label: t('user.created'),
    },
    {
      id: PROFILE_TAB_INDEX.LIKED,
      label: t('user.likes'),
    },
    {
      id: PROFILE_TAB_INDEX.FOLLOWING,
      label: t('user.following'),
    },
    {
      id: PROFILE_TAB_INDEX.FOLLOWERS,
      label: t('user.followers'),
    },
  ]

  const getFollowers = useCallback(async () => {
    const followersRes: any = await userApi.getFollowers(userId, 1)
    setfollowersCount(followersRes.data.total_items)
    const followingRes = await userApi.getFollowing(userId, 1)
    setFollowingCount(followingRes.data.total_items)
  }, [userId])

  const fetchCollectibles = useCallback(
    async (page: number) => {
      if (shownUser.address) {
        const refresh = page === 1
        try {
          if (refresh) setIsLoading(true)
          else setLoadingMore(true)
          let data: any
          let setData: any
          switch (activeIndex) {
            case PROFILE_TAB_INDEX.ON_SALE: {
              const resData = await storeApi.getCollectibles(shownUser.address, page)
              const filteredItems = resData.data.items.filter(
                (item: any) =>
                  (item.standart === 'ERC721' && (item.is_selling || item.is_auc_selling)) ||
                  item.sellers
                    .map((seller: any) => seller.id.toString())
                    .includes(userId.toString()),
              )
              data = {
                data: {
                  items: [...filteredItems],
                  total_items: resData.data.total_items,
                },
              }
              setData = setCollectibles
              break
            }
            case PROFILE_TAB_INDEX.ITEMS:
              data = await storeApi.getCollectibles(shownUser.address, page)
              setData = setCollectibles
              break
            case PROFILE_TAB_INDEX.CREATED:
              data = await storeApi.getCreated(shownUser.address, page)
              setData = setCreated
              break
            case PROFILE_TAB_INDEX.LIKED:
              data = await storeApi.getLiked(shownUser.address, page)
              setData = setLiked
              break
            case PROFILE_TAB_INDEX.FOLLOWING:
              data = await userApi.getFollowing(userId, page)
              setData = setFollowing
              break
            case PROFILE_TAB_INDEX.COLLECTIONS: {
              const collectionsData = await storeApi.getUserCollections(shownUser.address)
              data = {
                data: {
                  items: collectionsData.data,
                  total_items: collectionsData.data.total_items || collectionsData.data.length,
                },
              }
              setData = setCollections
              break
            }
            default:
              data = await userApi.getFollowers(userId, page)
              setData = setFollowers
              break
          }
          if (refresh) {
            setData(data.data.items)
          } else {
            setData((prev: any) => [...prev, ...data.data.items])
          }
          setLastPage(Math.ceil(data.data.total_items / PROFILE_ITEMS_PER_PAGE) || 1)
        } finally {
          if (refresh) setIsLoading(false)
          else setLoadingMore(false)
        }
      }
    },
    [activeIndex, shownUser.address, userId],
  )

  const getUser = useCallback(() => {
    userApi.getUser({id: userId}).then(({data}: any) => setShownUser(data))
  }, [userId])

  const setUserCover = useCallback(() => {
    userApi.setUserCover(userFile).then(() => setVisible(false))
  }, [userFile])

  const handleChangePhoto = (value: any) => {
    const isValidType =
      value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'image/webp'
    if (!isValidType) {
      return
    }
    const isLt2M = value.size / 1024 / 1024 < 1
    if (!isLt2M) {
      return
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setUserPhoto(typeof reader.result === 'string' ? reader.result : '')
    })
    reader.readAsDataURL(value)
    setUserFile(value)
  }

  useEffect(() => {
    setCollectibles([])
  }, [userId, shownUser.address])

  useEffect(() => {
    if (userId) {
      getUser()
    } else {
      history.push('/')
    }
  }, [getUser, userId, history])

  useEffect(() => {
    setCurrentPage(1)
    fetchCollectibles(1)
    const interval = setInterval(() => fetchCollectibles(1), 300000)
    return () => clearInterval(interval)
  }, [fetchCollectibles])

  useEffect(() => {
    getFollowers()
    const interval = setInterval(getFollowers, 60000)
    return () => clearInterval(interval)
  }, [getFollowers])

  useEffect(() => {
    if (tab === 'items') setActiveIndex(PROFILE_TAB_INDEX.ON_SALE)
    if (tab === 'following') setActiveIndex(PROFILE_TAB_INDEX.FOLLOWING)
    if (tab === 'created') setActiveIndex(PROFILE_TAB_INDEX.CREATED)
  }, [tab])

  useEffect(() => {
    setCollectibles([])
    setCreated([])
    setLiked([])
    setFollowing([])
    setFollowers([])
    setCollections([])
    setCurrentPage(1)
    // fetchCollectibles(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const isMe = useMemo(() => {
    if (!user.id) {
      return false
    }
    if (typeof user.id === 'number') {
      return user.id === +userId
    }
    if (typeof user.id === 'string') {
      return user.id === userId
    }
    return false
  }, [user.id, userId])

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    fetchCollectibles(page)
  }

  const getItemsByIndex = (activeTab: number) => {
    let items = []
    if (activeTab === PROFILE_TAB_INDEX.ON_SALE)
      items = collectibles.filter(
        (item: any) =>
          (item.standart === 'ERC721' && (item.is_selling || item.is_auc_selling)) ||
          item.sellers.map((seller: any) => seller.id.toString()).includes(userId.toString()),
      )
    if (activeTab === PROFILE_TAB_INDEX.COLLECTIONS) items = collections
    if (activeTab === PROFILE_TAB_INDEX.ITEMS) items = collectibles
    if (activeTab === PROFILE_TAB_INDEX.CREATED) items = created
    if (activeTab === PROFILE_TAB_INDEX.LIKED) items = liked
    return items
  }

  const renderTabItems = () => {
    if (activeIndex === PROFILE_TAB_INDEX.FOLLOWING || activeIndex === PROFILE_TAB_INDEX.FOLLOWERS)
      return (
        <Followers
          className={styles.followers}
          following={following}
          followers={followers}
          isShowButtons={!!user.address}
          activeIndex={activeIndex}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          isFetching={isFetching}
          isMyPage={isMe}
        />
      )
    if (activeIndex === PROFILE_TAB_INDEX.COLLECTIONS)
      return (
        <UserCollections
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          isFetching={isFetching}
          className={styles.items}
          collections={collections}
          isMe={isMe}
        />
      )
    return (
      <Items
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        isFetching={isFetching}
        activeIndex={activeIndex}
        className={styles.items}
        items={getItemsByIndex(activeIndex)}
        isMe={isMe}
      />
    )
  }

  useEffect(() => {
    if (
      !!localStorage.kephi_nft_chainName &&
      isMe &&
      !myLocalStorage.get(LOCAL_STORAGE.REFERRAL_CODE_SHOWN) &&
      !isModalShown
    )
      setIsModalShown(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMe])

  return (
    <div className={styles.profile}>
      <div
        className={cn(styles.head, {[styles.active]: visible})}
        style={{
          backgroundImage: userPhoto
            ? `url(${userPhoto})`
            : `url(${shownUser.cover || '/images/bg/profile-bg2.png'})`,
        }}>
        {isMe && (
          <div className={cn('container', styles.container)}>
            <div className={styles.btns}>
              <button
                type="button"
                className={cn('button-stroke button-small', styles.button)}
                onClick={() => setVisible(true)}>
                <span>{t('user.edit_cover')}</span>
                <Icon name="image" size="16" />
              </button>
              <Link className={cn('button-stroke button-small', styles.button)} to="/profile-edit">
                <span>{t('user.edit_profile')}</span>
                <Icon name="edit" size="16" />
              </Link>
            </div>
            <div className={styles.file}>
              <input
                type="file"
                ref={fileRef}
                // eslint-disable-next-line
                // @ts-ignore
                onChange={e => handleChangePhoto(e.target.files[0])}
              />
              <div
                className={styles.file_wrapper}
                role="button"
                onKeyDown={() => {}}
                tabIndex={-1}
                onClick={() => {
                  if (fileRef.current) {
                    fileRef.current.click()
                  }
                }}
                onDragOver={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                onDrop={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (fileRef.current) {
                    handleChangePhoto(e.dataTransfer.files[0])
                  }
                }}>
                {' '}
              </div>
              <div className={styles.wrap}>
                <Icon name="upload-file" size="48" />
                <div className={styles.info}>{t('user.text1')}</div>
                <div className={styles.text}>{t('user.text2')}</div>
              </div>
              <button
                type="button"
                className={cn('button-small', styles.button)}
                onClick={() => setUserCover()}>
                {t('user.save_photo')}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={styles.body}>
        <div className={cn('container', styles.container)}>
          <User
            className={styles.user}
            isFollow={
              following.filter((i: any) => i.id.toString() === user.id.toString()).length > 0
            }
            handleUpdateFollowers={getFollowers}
            userData={shownUser}
            followersCount={followersCount}
            followingCount={followingCount}
          />
          <div className={styles.wrapper}>
            <NavLink
              className={styles.nav}
              navLinks={navLinks}
              onClick={(value: any) => setActiveIndex(value)}
              activeLink={activeIndex}
            />
            <div className={styles.group}>
              <div className={styles.item}>{renderTabItems()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Profile
