import Button from '../../../Button'
import Image from '../../../Image'

import styles from './LogInOption.module.scss'

interface ILogInOption {
  logoUrl: string
  label: string
  onClick: () => void
}

const LogInOption: React.FC<ILogInOption> = ({logoUrl, label, onClick}) => {
  return (
    <Button className={styles.card} onClick={onClick}>
      <Image src={logoUrl} alt="LoginOption" />
      <div className={styles.label}>{label}</div>
    </Button>
  )
}

export default LogInOption
