import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import cn from 'classnames'
import moment from 'moment'

import 'moment/locale/es'

import DropdownLanguage from '../DropdownLanguage/DropdownLanguage'
import Icon, {IIcons} from '../Icon'
import Image from '../Image'
import Theme from '../Theme'

import styles from './Footer.module.scss'

const socials: {title: IIcons; url: string; viewBox: string; size: string}[] = [
  {
    title: 'facebook-fill',
    url: 'https://www.facebook.com/KephiGallery/',
    viewBox: '0 0 10 20',
    size: '22',
  },
  {
    title: 'twitter-fill',
    url: 'https://twitter.com/KephiGallery',
    viewBox: '0 0 25 21',
    size: '22',
  },
  {
    title: 'youtube-fill',
    url: 'https://www.youtube.com/channel/UCgQtaGud_JYKWPzu05NxLhA',
    viewBox: '0 0 22 22',
    size: '22',
  },
  {
    title: 'github-fill',
    url: 'https://github.com/orgs/kephi-gallery/',
    viewBox: '0 0 22 22',
    size: '22',
  },
  {
    title: 'linkedin-fill',
    url: 'https://www.linkedin.com/company/kephi-gallery/',
    viewBox: '0 0 22 22',
    size: '22',
  },
  {
    title: 'discord-fill',
    url: 'https://discord.com/invite/YMgwGeWQ',
    viewBox: '0 0 22 14',
    size: '22',
  },
  {
    title: 'telegram-fill',
    url: 'https://t.me/KephiGallery',
    viewBox: '3 3 22 22',
    size: '36',
  },
]

const Footers: React.FC = () => {
  const {t, i18n} = useTranslation()

  if (localStorage.getItem('darkMode') !== 'true') localStorage.setItem('darkMode', 'true')
  document.body.className = 'dark-mode'

  const languages = useCallback(
    () => [
      {key: 'EN', label: t('language.english')},
      {key: 'ES', label: t('language.spanish')},
    ],
    [t],
  )

  const [language, setLanguage] = useState(
    localStorage.kephi_lang && localStorage.kephi_lang === 'en' ? languages()[0] : languages()[1],
  )

  const handleChangeLang = (lang: {key: string; label: string}) => {
    setLanguage(lang)
    if (lang.key === 'EN') {
      i18n.changeLanguage('en')
      localStorage.kephi_lang = 'en'
      moment.locale('en')
    } else {
      i18n.changeLanguage('es')
      localStorage.kephi_lang = 'es'
      moment.locale('es')
    }
  }

  useEffect(() => {
    if (localStorage.kephi_lang) {
      i18n.changeLanguage(localStorage.kephi_lang)
      moment.locale(localStorage.kephi_lang)
      setLanguage(localStorage.kephi_lang === 'es' ? languages()[1] : languages()[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, localStorage.kephi_lang])

  return (
    <footer className={styles.footer}>
      <div className={cn('container', styles.container)}>
        <div className={cn(styles.row, styles.wrapper)}>
          <div className={styles.col}>
            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <Link to="/">
                  <Image
                    src="/images/kephi-logo-dark.svg"
                    srcSetDark="/images/kephi-logo-light.svg"
                    alt="Footer logo"
                  />
                </Link>
              </div>
              <div className={styles.slogan}>{t('footer.slogan')}</div>
            </div>
            <div className={styles.themeContainer}>
              <div className={styles.themeText}>{t('darkTheme')}</div>
              <Theme className={styles.theme} />
            </div>
          </div>

          <div className={styles.linkGroups}>
            <div className={styles.dropdownWrapper}>
              <DropdownLanguage
                setValue={(selectedStr: {key: string; label: string}) =>
                  handleChangeLang(selectedStr)
                }
                value={language}
                options={languages()}
                customClasses={{
                  head: styles.languageDropdownHead,
                  arrow: styles.languageDropdownArrow,
                }}
              />
            </div>
            <div className={cn(styles.col, styles.colMargin)}>
              <div className={styles.socials}>
                {socials.map(social => (
                  <a
                    key={JSON.stringify(social)}
                    className={styles.social}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon name={social.title} size={social.size} viewBox={social.viewBox} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.divider} />
        <div className={styles.rowFooter}>
          <div className={styles.copyright}>{t('footer.copy')}</div>
          <span className={styles.poweredBy}>
            {t('footer.poweredBy')}
            <Image src="/images/logos/blockchain-solutions.svg" alt="Footer logo" />
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footers
