import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as ApplePay} from '../../../../assets/img/icons/applePay.svg'
import {ReactComponent as Mastercard} from '../../../../assets/img/icons/mastercard.svg'
import {ReactComponent as Visa} from '../../../../assets/img/icons/visa.svg'

import styles from './PaymentMethod.module.scss'

interface IBuyTokenProps {
  className?: string
  onPurchase: (isMultiChain?: boolean, isCreditCard?: boolean) => void
}

const PaymentMethod: React.FC<IBuyTokenProps> = ({className, onPurchase}) => {
  const {t} = useTranslation()
  const [isWalletMethod, setWalletMethod] = useState(true)

  return (
    <div className={classNames(className, styles.modal)}>
      <div className={styles.title}>{t('token.paymentMethod.title')}</div>
      <div className={styles.label}>{t('token.paymentMethod.label')}</div>

      <div className={styles.rowPaymentMethodButtons}>
        <button
          type="button"
          onClick={() => setWalletMethod(true)}
          className={classNames('button-outlined', styles.paymentMethodButton, {
            [styles.selected]: isWalletMethod,
          })}>
          {t('token.paymentMethod.wallet')}
        </button>
        <button
          type="button"
          onClick={() => setWalletMethod(false)}
          className={classNames('button-outlined', styles.paymentMethodButton, {
            [styles.selected]: !isWalletMethod,
          })}>
          <Visa className={styles.visaIcon} />
          <Mastercard className={styles.visaIcon} />
          <ApplePay className={styles.visaIcon} />
        </button>
      </div>

      <button
        type="button"
        className={classNames('button', styles.button)}
        onClick={() => onPurchase(false, !isWalletMethod)}>
        {t('token.purchase')}
      </button>
    </div>
  )
}

export default PaymentMethod
