import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import Skeleton from 'react-loading-skeleton'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'
import copyToClipboard from 'copy-to-clipboard'
import {observer} from 'mobx-react'
import moment from 'moment'

import verifyImg from '../../../assets/img/icons/verify.svg'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Modal from '../../../components/Modal'
import Report from '../../../components/Report'
import {userApi} from '../../../services/api'
import {useMst} from '../../../store/store'

import styles from './User.module.scss'

// TODO: fix any
interface IUserProps {
  className?: string
  isFollow: boolean
  handleUpdateFollowers?: () => void
  userData: {
    id: number | string
    avatar: string
    display_name?: string
    address: string
    followers: Array<any>
    followers_count: number
    follows_count: number
    twitter: string | null
    instagram: string | null
    facebook: string | null
    site: string | null
    bio: string | null
    created_at: any
    is_verificated: boolean
    my_referral_code: string
  }
  followersCount: number
  followingCount: number
}

const User: React.FC<IUserProps> = observer(
  ({className, isFollow, handleUpdateFollowers, userData, followersCount, followingCount}) => {
    const {t} = useTranslation()
    const [visible, setVisible] = useState(isFollow)
    const [copyAddress, setCopyAddress] = useState(false)
    const [visibleShare, setVisibleShare] = useState(false)
    const [visibleModalReport, setVisibleModalReport] = useState(false)
    const {user} = useMst()

    const handleCopy = () => {
      copyToClipboard(userData.address)
      setCopyAddress(true)
    }

    const follow = useCallback(async () => {
      await userApi.follow({id: userData.id})
      if (handleUpdateFollowers) {
        await handleUpdateFollowers()
      }
    }, [userData.id, handleUpdateFollowers])

    const unfollow = useCallback(async () => {
      await userApi.unfollow({id: userData.id})
      if (handleUpdateFollowers) {
        await handleUpdateFollowers()
      }
    }, [userData.id, handleUpdateFollowers])

    useEffect(() => {
      if (userData.followers.length) {
        setVisible(userData.followers.filter((i: any) => i.id === user.id).length > 0)
      }
    }, [userData.followers, user.id])

    return (
      <>
        <div className={cn(styles.user, className)}>
          <div className={styles.avatar}>
            {userData.avatar ? (
              <img src={userData.avatar} className={styles.avatarImg} alt="Avatar" />
            ) : (
              <Skeleton className={styles.avatar} circle />
            )}
            {userData.is_verificated ? (
              <img src={verifyImg} className={styles.verify} alt="" />
            ) : null}
          </div>
          <div className={styles.name}>{userData.display_name || 'User'}</div>
          <div className={styles.code}>
            <div className={styles.number}>
              {userData.address.slice(0, 14)}...{userData.address.slice(-4)}
            </div>
            <Button
              className={styles.copy}
              onClick={handleCopy}
              onMouseLeave={() => setCopyAddress(false)}>
              <Icon name="copy" size="16" />
              <div className={styles.tooltip}>
                <span className={styles.tooltiptext}>
                  {copyAddress ? `${t('success')}!` : t('copy_adress')}
                </span>
              </div>
            </Button>
          </div>
          {userData.bio ? <div className={styles.info}>{userData.bio}</div> : ''}
          {userData.site ? (
            <a
              className={styles.site}
              href={`http://${userData.site}`}
              target="_blank"
              rel="noreferrer">
              <Icon name="globe" size="16" />
              <span>{userData.site.length > 25 ? t('visit_website') : userData.site}</span>
            </a>
          ) : (
            ''
          )}
          <div className={styles.control}>
            <div className={styles.btns}>
              {user.address && userData.id !== user.id && (
                <button
                  type="button"
                  className={cn(
                    'button button-small',
                    {
                      'button-stroke': visible,
                    },
                    {[styles.active]: visible},
                    styles.button,
                  )}
                  onClick={() => setVisible(!visible)}>
                  <span tabIndex={0} onKeyDown={() => {}} role="button" onClick={follow}>
                    {t('follow')}
                  </span>
                  <span tabIndex={0} onKeyDown={() => {}} role="button" onClick={unfollow}>
                    {t('unfollow')}
                  </span>
                </button>
              )}
              {user.twitter || user.facebook || user.instagram ? (
                <button
                  type="button"
                  className={cn('button-circle-stroke button-small', styles.button)}
                  onClick={() => setVisibleShare(!visibleShare)}>
                  <Icon name="share" size="20" />
                </button>
              ) : (
                ''
              )}
              {user.address && userData.id !== user.id ? (
                <button
                  type="button"
                  className={cn('button-circle-stroke button-small', styles.button)}
                  onClick={() => setVisibleModalReport(true)}>
                  <Icon name="report" size="20" />
                </button>
              ) : null}
            </div>
            <div className={styles.followers}>
              <div className={styles.wrapper}>
                <span className={styles.text}>{t('followers')}:</span>
                <div className={styles.circle}>{followersCount}</div>
              </div>
              <div className={styles.wrapper}>
                <span className={styles.text}>{t('following')}:</span>
                <div className={styles.circle}>{followingCount}</div>
              </div>
            </div>
            <OutsideClickHandler onOutsideClick={() => setVisibleShare(false)}>
              <div className={cn(styles.box, {[styles.active]: visibleShare})}>
                <div className={styles.stage}>{t('user.share_link')}</div>
                <div className={styles.share}>
                  {user.twitter ? (
                    <a
                      href={`https://twitter.com/intent/tweet?url=https://twitter.com/${user.twitter}`}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.direction}>
                      <span>
                        <Icon name="twitter" size="20" />
                      </span>
                    </a>
                  ) : (
                    ''
                  )}
                  {user.facebook ? (
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=https://www.facebook.com/${user.facebook}`}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.direction}>
                      <span>
                        <Icon name="facebook" size="20" />
                      </span>
                    </a>
                  ) : (
                    ''
                  )}
                  {user.instagram ? (
                    <a
                      href={`https://www.instagram.com/?url=https://instagram.com/${user.instagram}/`}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.direction}>
                      <span>
                        <Icon name="instagram" size="20" />
                      </span>
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </OutsideClickHandler>
          </div>
          {userData.facebook || userData.instagram || userData.twitter ? (
            <div className={styles.socials}>
              {userData.twitter ? (
                <a
                  className={styles.social}
                  href={`https://twitter.com/${userData.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={nextId()}>
                  <Icon name="twitter" size="20" />
                </a>
              ) : (
                ''
              )}
              {userData.instagram ? (
                <a
                  className={styles.social}
                  href={`https://instagram.com/${userData.instagram}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={nextId()}>
                  <Icon name="instagram" size="20" />
                </a>
              ) : (
                ''
              )}
              {userData.facebook ? (
                <a
                  className={styles.social}
                  href={`https://facebook.com/${userData.facebook}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={nextId()}>
                  <Icon name="facebook" size="20" />
                </a>
              ) : (
                ''
              )}
            </div>
          ) : null}
          <div className={styles.note}>
            {t('member_since')} {moment(userData.created_at).format('MMM DD, YYYY')}
          </div>
        </div>
        <Modal
          visible={visibleModalReport}
          onClose={() => setVisibleModalReport(false)}
          closeOnRight>
          <Report onClose={() => setVisibleModalReport(false)} isUser />
        </Modal>
      </>
    )
  },
)

export default User
