import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

import Burn from '../Burn'
import Icon from '../Icon'
import Modal from '../Modal'
import RemoveSale from '../RemoveSale'
import Report from '../Report'
import SuccessTransfered from '../SuccessTransfered'
import Transfer from '../Transfer'

import styles from './Actions.module.scss'

interface IActionsProps {
  className?: string
  itemId?: string
  isMayRemoveFromSale: boolean
  standart?: string
  isOwner: boolean
  isAuction: boolean
  removeFromSale: () => Promise<any>
  isWrongChain: boolean
  amount: number
  tokenName?: string
  creatorName?: string
  updateTokenData: () => void
}

const Actions: React.FC<IActionsProps> = ({
  className,
  itemId,
  standart,
  isMayRemoveFromSale,
  isOwner,
  removeFromSale,
  isWrongChain,
  amount,
  tokenName,
  creatorName,
  updateTokenData,
}) => {
  const {t} = useTranslation()
  const [visible, setVisible] = useState(false)
  const [visibleModalTransfer, setVisibleModalTransfer] = useState(false)
  const [visibleModalRemoveSale, setVisibleModalRemoveSale] = useState(false)
  const [visibleModalBurn, setVisibleModalBurn] = useState(false)
  const [visibleModalReport, setVisibleModalReport] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const items: Array<{
    title: string
    icon: 'coin' | 'arrow-right-square' | 'close-circle' | 'info-circle'
    action: () => void
    condition?: boolean
  }> = useMemo(
    () => [
      {
        title: t('token.transfer'),
        icon: 'arrow-right-square',
        action: () => setVisibleModalTransfer(true),
        condition: isOwner && !isWrongChain,
      },
      {
        title: t('token.remove_sale'),
        icon: 'close-circle',
        action: () => setVisibleModalRemoveSale(true),
        condition: isMayRemoveFromSale && !isWrongChain,
      },
      {
        title: t('token.burn'),
        icon: 'close-circle',
        action: () => setVisibleModalBurn(true),
        condition: isOwner && !isWrongChain,
      },
      {
        title: t('token.report'),
        icon: 'info-circle',
        action: () => setVisibleModalReport(true),
        condition: true,
      },
    ],
    [t, isMayRemoveFromSale, isOwner, isWrongChain],
  )

  const handleRemoveFromSale = () => {
    removeFromSale().then(() => {
      setVisibleModalRemoveSale(false)
    })
  }

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div
          className={cn(styles.actions, className, {
            [styles.active]: visible,
          })}>
          <button
            type="button"
            className={cn('button-circle-stroke', styles.button)}
            onClick={() => setVisible(!visible)}>
            <Icon name="more" size="24" />
          </button>
          <div className={styles.body}>
            {items.map(x => {
              return (
                !!x.condition && (
                  <div
                    tabIndex={0}
                    onKeyDown={() => {}}
                    role="button"
                    className={styles.item}
                    key={nextId()}
                    onClick={x.action}>
                    <Icon name={x.icon} size="20" />
                    <span>{x.title}</span>
                  </div>
                )
              )
            })}
          </div>
        </div>
      </OutsideClickHandler>
      <Modal
        visible={visibleModalTransfer}
        onClose={() => setVisibleModalTransfer(false)}
        closeOnRight>
        <Transfer
          itemId={itemId}
          standart={standart}
          onClose={() => setVisibleModalTransfer(false)}
          amount={amount}
          setIsSuccess={setIsSuccess}
        />
      </Modal>
      <Modal
        visible={visibleModalRemoveSale}
        onClose={() => setVisibleModalRemoveSale(false)}
        closeOnRight>
        <RemoveSale
          removeFromSale={handleRemoveFromSale}
          onClose={() => setVisibleModalRemoveSale(false)}
        />
      </Modal>
      <Modal visible={visibleModalBurn} onClose={() => setVisibleModalBurn(false)} closeOnRight>
        <Burn
          itemId={itemId}
          standart={standart}
          onClose={() => setVisibleModalBurn(false)}
          amount={amount}
        />
      </Modal>
      <Modal visible={visibleModalReport} onClose={() => setVisibleModalReport(false)} closeOnRight>
        <Report onClose={() => setVisibleModalReport(false)} />
      </Modal>
      <Modal
        visible={isSuccess}
        onClose={() => {
          setIsSuccess(false)
          updateTokenData()
        }}>
        <SuccessTransfered tokenName={tokenName} creatorName={creatorName} />
      </Modal>
    </>
  )
}

export default Actions
