// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-param-reassign
import React from 'react'
import {notification} from 'antd'
import {withFormik} from 'formik'
import {observer} from 'mobx-react-lite'

import {storeApi} from '../../../services/api'
import {getCoinNameByType} from '../../../utils/coins'
// import { useMst } from '../../../store/store';
import {validateForm} from '../../../utils/validate'
import CreateForm, {ICreateForm} from '../component'

export default observer(({isSingle, walletConnector}: any) => {
  // const { modals } = useMst();
  const FormWithFormik = withFormik<any, ICreateForm>({
    enableReinitialize: true,
    mapPropsToValues: () => ({
      img: '',
      cover: '',
      preview: '',
      coverPreview: '',
      putOnSale: true,
      instantSalePrice: true,
      unlockOncePurchased: false,
      format: 'image',
      instantSalePriceEth: '',
      digitalKey: '',
      tokenName: '',
      tag: '',
      tokenDescr: '',
      tokenRoyalties: '10',
      numberOfCopies: '',
      tokenProperties: [
        {
          size: '',
          amount: '',
        },
      ],
      isLoading: false,
      collectionId: '16',
      currency: getCoinNameByType('main'),
      bid: '',
      showModal: false,
    }),
    validate: (values: any) => {
      const notRequired: string[] = ['tokenDescr', 'preview']
      if (
        !values.putOnSale ||
        (!values.instantSalePrice && !notRequired.includes('instantSalePriceEth'))
      ) {
        notRequired.push('instantSalePriceEth')
      }
      if (!values.unlockOncePurchased && !notRequired.includes('digitalKey')) {
        notRequired.push('digitalKey')
      }
      if (isSingle) {
        notRequired.push('numberOfCopies')
      }
      if (!values.putOnSale || values.instantSalePrice) {
        notRequired.push('bid')
      }
      if (values.format !== 'image' && !values.cover) {
        notRequired.push('cover')
      }
      const errors = validateForm({values, notRequired})

      return errors
    },
    handleSubmit: (values, {setFieldValue, setFieldError}) => {
      setFieldValue('isLoading', true)

      const formData = new FormData()
      formData.append('media', values.img)
      if (values.cover) formData.append('cover', values.cover)
      formData.append('name', values.tokenName)
      formData.append('total_supply', isSingle ? '1' : values.numberOfCopies.toString())
      formData.append('description', values.tokenDescr)
      if (values.instantSalePrice && values.putOnSale) {
        formData.append('price', values.instantSalePriceEth.toString())
      }
      if (!values.instantSalePrice && values.putOnSale) {
        formData.append('minimal_bid', values.bid.toString())
      }
      if (values.putOnSale) {
        formData.append('available', values.numberOfCopies.toString())
      } else {
        formData.append('available', '0')
      }
      // const currencyForAuc = localStorage.kephi_nft_chainName === 'KardiaChain' ? 'K-USDT' : 'BUSD';
      formData.append('creator_royalty', values.tokenRoyalties)
      formData.append('standart', isSingle ? 'ERC721' : 'ERC1155')
      formData.append('collection', values.collectionId)
      formData.append('currency', values.currency)
      formData.append('format', values.format.toUpperCase())
      formData.append('tag', values.tag.toUpperCase())
      formData.append('selling', values.putOnSale.toString())
      if (values.unlockOncePurchased) {
        formData.append('digital_key', values.digitalKey)
      }

      if (values.tokenProperties[0].size) {
        const details: any = {}
        values.tokenProperties.forEach(item => {
          if (item.size) {
            details[item.size] = item.amount
          }
        })

        formData.append('details', JSON.stringify(details))
      }
      storeApi
        .createToken(formData)
        .then(({data}) => {
          walletConnector.walletService
            .sendTransaction(data.initial_tx)
            .then((response: any) => {
              setFieldValue('showModal', true)
              setFieldValue('isLoading', false)
              storeApi.trackMinerTransaction(
                data.token.id,
                response.transactionHash,
                response.blockNumber,
                'Mint',
              )
            })
            .catch((err: any) => {
              console.log(err, 'err', 'data', data)
              // modals.info.setMsg('Something went wrong', 'error');
              storeApi.rejectTransaction({type: 'token', id: data.token.id})
              setFieldValue('isLoading', false)
            })
        })
        .catch(({response}) => {
          setFieldValue('isLoading', false)
          if (response.data && response.data.name) {
            setFieldError('tokenName', response.data.name)
            notification.error({
              message: response.data.name,
              description: 'Please choose unique name for your Token',
            })
          }
          if (response.data) {
            // modals.info.setMsg(Object.values(response.data).join(', '), 'error');
          }
        })
    },
    displayName: 'ChangePasswordForm',
  })(CreateForm)
  return <FormWithFormik isSingle={isSingle} />
})
