import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import cn from 'classnames'
import {observer} from 'mobx-react'

import Icon from '../../components/Icon'
import NavLink from '../../components/NavLink/NavLink'
import {ACTIVITY_TAB_INDEX} from '../../constants/constants'
import {activityApi} from '../../services/api'
import {useMst} from '../../store/store'
import {NavLinkType} from '../../types/navLink'

import Items from './Items'

import styles from './Activity.module.scss'

const Activity: React.FC = observer(() => {
  const {t} = useTranslation()
  const {user} = useMst()
  const history = useHistory()
  const [activeIndex, setActiveIndex] = useState<any>(null)
  const [visible, setVisible] = useState(false)
  const [items, setItems] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  const [formData, setFormData] = useState<any>({
    address: '',
    page: 1,
  })

  const navLinks: NavLinkType[] = useMemo(
    () => [
      {id: ACTIVITY_TAB_INDEX.MY_ACTIVITY, label: t('activity.my')},
      {id: ACTIVITY_TAB_INDEX.FOLLOWING, label: t('header.menu.following')},
      {id: ACTIVITY_TAB_INDEX.ALL, label: t('activity.all')},
    ],
    [t],
  )

  const handleChange = (key: string, value: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const fetchActivity = useCallback(
    (address: string, tab: number) => {
      setIsLoaded(false)
      activityApi
        .getActivity(address, formData.page, '')
        .then(({data}: any) => {
          setItems(tab === 0 ? data.filter((item: any) => !item.is_viewed) : data)
        })
        .finally(() => setIsLoaded(true))
    },
    [formData.page],
  )

  const handleNavLinks = useCallback(
    (value: any) => {
      switch (value) {
        case 0:
          if (!user.address) return
          handleChange('address', user.address)
          fetchActivity(user.address, value)
          break
        case 1:
          handleChange('address', `${user.address}/following`)
          fetchActivity(`${user.address}/following`, value)
          break
        case 2:
          handleChange('address', '')
          fetchActivity('', value)
          break
        default:
          break
      }
      setActiveIndex(value)
      // setIsLoading(true);
    },
    [fetchActivity, user.address],
  )

  const readAllNotifications = useCallback(() => {
    activityApi.readNotification({activity_id: 0, method: 'all'}).then((data: any) => {
      if (data.statusText === 'OK') history.push('/activity')
      handleNavLinks(0)
    })
  }, [history, handleNavLinks])

  useEffect(() => {
    handleNavLinks(0)
  }, [handleNavLinks])

  return (
    <div className={cn('section', styles.body)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.top}>
          <h2 className={cn('h2', styles.title)}>{t('activity.title')}</h2>
          {activeIndex === 0 && (
            <button
              type="button"
              className={cn('button-stroke button-small', styles.button)}
              onClick={() => readAllNotifications()}>
              {t('activity.read')}
            </button>
          )}
          <button
            type="button"
            className={cn('button-circle-stroke button-small tablet-show', styles.toggle, {
              [styles.active]: visible,
            })}
            onClick={() => setVisible(!visible)}>
            <Icon name="filter" size="24" />
            <Icon name="close" size="14" />
          </button>
        </div>
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <NavLink
              className={styles.nav}
              navLinks={navLinks}
              onClick={value => handleNavLinks(value)}
              activeLink={activeIndex}
            />
            <Items items={items} activeIndex={activeIndex} isLoaded={isLoaded} />
          </div>
          {activeIndex === 0 && (
            <button
              type="button"
              className={cn('button-stroke button-small mobile-show', styles.button)}
              onClick={() => readAllNotifications()}>
              {t('activity.read')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
})

export default Activity
