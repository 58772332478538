import {useEffect, useState} from 'react'

const useInfiniteScroll = (callback: any): any => {
  const [isFetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => {
    function handleScroll() {
      if (
        document.documentElement.offsetHeight -
          window.innerHeight -
          document.documentElement.scrollTop >
          500 ||
        isFetching
      )
        return
      setIsFetching(true)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isFetching])

  useEffect(() => {
    if (!isFetching) return
    callback(() => {
      // console.log('called back')
    })
  }, [callback, isFetching])

  return [isFetching, setIsFetching]
}

export default useInfiniteScroll
