import axios from 'axios'

import Config from '../utils/config'

axios.defaults.baseURL = Config.baseUrlApi

axios.interceptors.request.use(
  config => {
    config.headers.common = {
      ...config.headers.common,
      Authorization: `${
        localStorage.getItem('kephi_nft_token')
          ? `Token ${localStorage.getItem('kephi_nft_token')}`
          : ''
      }`,
    }
    return config
  },
  error => {
    console.log('error', error)
    return Promise.reject(error)
  },
)

export default axios
