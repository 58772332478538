import {bep20Abi, nftAbi, wbnbAbi, wethAbi, wkaiAbi} from '../config/abi'
import {contractAddress} from '../constants/contracts'
import {IContracts} from '../types'
import {BlockchainType} from '../types/blockchain'
import {ContractParam, ContractType} from '../types/contract'

import Config from './config'

export const getContractType = (): ContractType => {
  return Config.isProduction ? 'mainnet' : 'testnet'
}

export const getAddressByContractParam = (contractParam: ContractParam): string => {
  return contractAddress[contractParam][getContractType()][
    localStorage.kephi_nft_chainName as BlockchainType
  ]
}

export const getContracts = (): IContracts => {
  return {
    type: getContractType(),
    names: ['Token', 'Staking', 'Presale', 'UsdtToken'],
    decimals: 18,
    params: {
      BEP20: {
        mainnet: {
          address: getAddressByContractParam('BEP20'),
          abi: bep20Abi,
        },
        testnet: {
          address: getAddressByContractParam('BEP20'),
          abi: bep20Abi,
        },
      },
      NFT: {
        mainnet: {
          address: getAddressByContractParam('NFT'),
          abi: nftAbi,
        },
        testnet: {
          address: getAddressByContractParam('NFT'),
          abi: nftAbi,
        },
      },
      EXCHANGE: {
        mainnet: {
          address: getAddressByContractParam('EXCHANGE'),
          abi: [],
        },
        testnet: {
          address: getAddressByContractParam('EXCHANGE'),
          abi: [],
        },
      },
      BUSD: {
        mainnet: {
          address: getAddressByContractParam('BUSD'),
          abi: wethAbi,
        },
        testnet: {
          address: getAddressByContractParam('BUSD'),
          abi: wethAbi,
        },
      },
      WBNB: {
        mainnet: {
          address: getAddressByContractParam('WBNB'),
          abi: wbnbAbi,
        },
        testnet: {
          address: getAddressByContractParam('WBNB'),
          abi: wethAbi,
        },
      },
      WMATIC: {
        mainnet: {
          address: getAddressByContractParam('WMATIC'),
          abi: wbnbAbi,
        },
        testnet: {
          address: getAddressByContractParam('WMATIC'),
          abi: wbnbAbi,
        },
      },
      USDT: {
        mainnet: {
          address: getAddressByContractParam('USDT'),
          abi: wethAbi,
        },
        testnet: {
          address: getAddressByContractParam('USDT'),
          abi: wethAbi,
        },
      },
      USDC: {
        mainnet: {
          address: getAddressByContractParam('USDC'),
          abi: wethAbi,
        },
        testnet: {
          address: getAddressByContractParam('USDC'),
          abi: wethAbi,
        },
      },
      KPHI: {
        mainnet: {
          address: getAddressByContractParam('KPHI'),
          abi: wethAbi,
        },
        testnet: {
          address: getAddressByContractParam('KPHI'),
          abi: wethAbi,
        },
      },
      KUSDT: {
        mainnet: {
          address: getAddressByContractParam('KUSDT'),
          abi: wethAbi,
        },
        testnet: {
          address: getAddressByContractParam('KUSDT'),
          abi: wethAbi,
        },
      },
      WKAI: {
        mainnet: {
          address: getAddressByContractParam('WKAI'),
          abi: wkaiAbi,
        },
        testnet: {
          address: getAddressByContractParam('WKAI'),
          abi: wethAbi,
        },
      },
      WETH: {
        mainnet: {
          address: getAddressByContractParam('WETH'),
          abi: wethAbi,
        },
        testnet: {
          address: getAddressByContractParam('WETH'),
          abi: wethAbi,
        },
      },
    },
  } as IContracts
}
