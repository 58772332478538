import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {notification} from 'antd'
import BigNumber from 'bignumber.js/bignumber'
import cn from 'classnames'

import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import Switch from '../../../../components/Switch'
import TextInput from '../../../../components/TextInput'
import {storeApi} from '../../../../services/api'
import {useWalletConnectorContext} from '../../../../services/walletConnect'
import {useMst} from '../../../../store/store'
import {formatCoinToken, isAnyOfMainCoin} from '../../../../utils/coins'
import {getBalanceByCoinName} from '../../../../utils/getBalance'

import styles from './PutSale.module.scss'

interface IPutSaleProps {
  className?: string
  tokenId?: string
  currency: string
  updateTokenData: () => void
  onClose: () => void
  royalty: number
  approve: () => Promise<any>
  fee: number
  isCreator: boolean
}

const PutSale: React.FC<IPutSaleProps> = ({
  className,
  tokenId,
  currency,
  updateTokenData,
  onClose,
  royalty,
  approve,
  fee,
  isCreator,
}) => {
  const {t} = useTranslation()
  const walletConnector = useWalletConnectorContext()
  const [balance, setBalance] = useState<string>('')
  const {user} = useMst()
  const [price, setPrice] = useState(true)
  const [priceValue, setPriceValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const getUserBalance = useCallback(async () => {
    const req = walletConnector.walletService.getTokenBalance(formatCoinToken(currency))

    req.then((data: string | number) => {
      setBalance(getBalanceByCoinName(data, currency))
    })
  }, [walletConnector.walletService, currency])

  const fetchStore = useCallback(() => {
    setIsLoading(true)
    approve()
      .then(() => {
        storeApi
          .putOnSale(tokenId ? +tokenId : 0, priceValue ? +priceValue : 0, price)
          .then(() => {
            onClose()
            updateTokenData()
          })
          .catch((err: any) => {
            notification.error({
              message: 'Error',
              description: 'Something went wrong',
            })
            console.error(err)
          })
      })
      .catch((err: any) => {
        notification.error({
          message: 'Error',
          description: 'Something went wrong',
        })
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }, [tokenId, priceValue, price, updateTokenData, onClose, approve])

  useEffect(() => {
    if (!user.address) return
    getUserBalance()
  }, [getUserBalance, user.address])

  return (
    <div className={cn(className, styles.sale)}>
      <div className={cn('h4', styles.title)}>{t('token.put_on_sale')}</div>
      <div className={styles.line}>
        <div className={styles.icon}>
          <Icon name="coin" size="24" />
        </div>
        <div className={styles.details}>
          <div className={styles.info}>{t('token.inst_sale_price')}</div>
          <div className={styles.text}>{t('token.put_sale.text1')}</div>
        </div>
        {!isAnyOfMainCoin(currency) && (
          <Switch className={styles.switch} value={price} setValue={setPrice} />
        )}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <TextInput
            label=""
            type="number"
            className={cn(styles.input, styles.col)}
            placeholder={price ? t('token.put_sale.text2') : t('token.put_sale.text3')}
            value={priceValue}
            onChange={e => setPriceValue(e.target.value)}
            positiveOnly
          />
          <div className={styles.col}>{currency}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>{t('your_balance')}</div>
          <div className={styles.col}>
            {balance} {currency}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>{t('service_fee')}</div>
          <div className={styles.col}>{fee}%</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>{t('token.you_recieve')}</div>
          {priceValue ? (
            <div className={styles.col}>
              {new BigNumber(
                (
                  (parseFloat(priceValue) * (100 - fee - (isCreator ? 0 : royalty))) /
                  100
                ).toString(),
              ).toString(10)}{' '}
              {currency}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={styles.btns}>
        <Button
          onClick={fetchStore}
          className={cn('button', styles.button)}
          loading={isLoading}
          disabled={!priceValue}>
          {t('continue')}
        </Button>
        <button
          type="button"
          className={cn('button-stroke', styles.button)}
          onClick={() => onClose()}>
          {t('cancel')}
        </button>
      </div>
    </div>
  )
}

export default PutSale
