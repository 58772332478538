import {createContext, useContext} from 'react'
import {
  Instance,
  // onSnapshot,
  types,
} from 'mobx-state-tree'

import {getContracts} from '../utils/contracts'

import {Contracts} from './contracts'
import {User} from './User'

const RootModel = types.model({
  user: User,
  contracts: Contracts,
})
export const Store = RootModel.create({
  user: {
    address: '',
    is_verificated: false,
    display_name: '',
    // balance: {main: '0', usd: '0', wrap: '0', kphi: '0'},
    follows_count: 0,
    followers_count: 0,
    search: '',
    is_searching: false,
    my_referral_code: '',
  },
  contracts: getContracts(),
})

export const rootStore = Store

// onSnapshot(rootStore, snapshot => {
//   console.log('Snapshot: ', snapshot)
// })

export type RootInstance = Instance<typeof RootModel>
const RootStoreContext = createContext<null | RootInstance>(null)

export const {Provider} = RootStoreContext

export function useMst() {
  const store = useContext(RootStoreContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }
  return store
}
