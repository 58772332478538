import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import ControlSkeleton from '../Control/Skeleton/ControlSkeleton'
import UserSkeletons from '../Users/Skeleton/UserSkeletons'

import itemStyles from '../Item.module.scss'
import skeletonStyles from './ItemSkeleton.module.scss'

const ItemSkeleton = () => (
  <>
    <div className={itemStyles.bg}>
      <Skeleton className={skeletonStyles.previewSkeleton} />
      <div className={skeletonStyles.optionsMobile}>
        <Skeleton className={skeletonStyles.options} width={170} height={55} />
      </div>
    </div>
    <div className={itemStyles.details}>
      <Skeleton className={classNames(itemStyles.title, skeletonStyles.title)} width="60%" />
      <div className={skeletonStyles.prices}>
        <Skeleton width={80} height={20} />
        <Skeleton width={80} height={20} />
        <Skeleton width={70} height={15} />
      </div>
      <Skeleton className={skeletonStyles.description} />
      <div className={itemStyles.navWrapper}>
        <div className={classNames(itemStyles.nav, skeletonStyles.nav)}>
          <Skeleton className={skeletonStyles.navItem} />
          <Skeleton className={skeletonStyles.navItem} />
          <Skeleton className={skeletonStyles.navItem} />
          <Skeleton className={skeletonStyles.navItem} />
        </div>
      </div>
      <UserSkeletons count={3} />
      <ControlSkeleton userClassName={itemStyles.control} />
    </div>
    <div className={itemStyles.options}>
      <Skeleton className={itemStyles.options} />
    </div>
  </>
)

export default ItemSkeleton
