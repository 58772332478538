import {chains} from '../config'
import {BLOCKCHAIN} from '../constants/constants'
import {BlockchainType} from '../types/blockchain'

export const getItemBlockchain = (network: string): BlockchainType => {
  if (network.toLowerCase().match(BLOCKCHAIN.BINANCE.toLowerCase())) return BLOCKCHAIN.BINANCE
  if (network.toLowerCase().match(BLOCKCHAIN.POLYGON.toLowerCase())) return BLOCKCHAIN.POLYGON
  if (network.toLowerCase().match(BLOCKCHAIN.ETHEREUM.toLowerCase())) return BLOCKCHAIN.ETHEREUM
  return BLOCKCHAIN.KARDIACHAIN
}

export const getTokenAddressURL = (blockchain: BlockchainType, tokenContract: string): string => {
  // eslint-disable-next-line no-nested-ternary
  const blockChainParsed: BlockchainType = blockchain
    .toLocaleLowerCase()
    .includes(BLOCKCHAIN.BINANCE.toLocaleLowerCase())
    ? BLOCKCHAIN.BINANCE
    : blockchain.toLocaleLowerCase().includes(BLOCKCHAIN.KARDIACHAIN.toLocaleLowerCase())
    ? BLOCKCHAIN.KARDIACHAIN
    : blockchain
  return `${chains[blockChainParsed]?.network?.blockExplorerUrl}address/${tokenContract}`
}
