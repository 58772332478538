import React from 'react'
import {Control, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as PropAddImg} from '../../../../../../../assets/img/icons/prop-add.svg'
import TextInput from '../../../../../../../components/TextInput'
import {IProperty} from '../../../../../types/CreateFormData'
import {ItemDetailsForm} from '../../ItemDetails'

import styles from './Advance.module.scss'

interface IAdvanceProps {
  control: Control<ItemDetailsForm, any>
  errors: any
  className?: string
  onAddProperty: () => void
  onRemoveProperty: (index: number) => void
  currentProperties: IProperty[]
  advanceVisible?: boolean
}

const Advance = React.memo<IAdvanceProps>(
  ({
    className,
    control,
    errors,
    onAddProperty,
    onRemoveProperty,
    currentProperties,
    advanceVisible,
  }) => {
    const {t} = useTranslation()

    return (
      <div className={classNames(styles.container, className, {[styles.visible]: advanceVisible})}>
        <div className={styles.rowContainer}>
          {currentProperties.map((tokenProperty: IProperty, index) => (
            <div
              className={styles.columnRows}
              // eslint-disable-next-line react/no-array-index-key
              key={`tokenProperties.${index}.size`}>
              <div className={styles.rowInputs}>
                <Controller
                  control={control}
                  name={`tokenProperties.${index}.size`}
                  rules={{
                    required: {
                      value: !!tokenProperty.amount,
                      message: t('validate.propName'),
                    },
                  }}
                  render={({field: {name, value, onChange, onBlur}}) => (
                    <TextInput
                      className={styles.item}
                      labelClassName={styles.label}
                      label={t('create_form.type')}
                      name={name}
                      type="string"
                      min={0}
                      placeholder={t('create_form.typePlaceholder')}
                      value={value.toString()}
                      onChange={inputValue => {
                        onChange(inputValue)
                      }}
                      onBlur={onBlur}
                      error={!!errors && !!errors[index]?.size}
                      helperText={errors ? errors[index]?.size?.message : ''}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`tokenProperties.${index}.amount`}
                  rules={{
                    required: {
                      value: !!tokenProperty.size,
                      message: t('validate.propValue'),
                    },
                  }}
                  render={({field: {name, value, onChange, onBlur}}) => (
                    <TextInput
                      className={styles.item}
                      labelClassName={styles.label}
                      label={t('create_form.detail')}
                      name={name}
                      type="string"
                      min={0}
                      placeholder={t('create_form.detailPlaceholder')}
                      value={value.toString()}
                      onChange={inputValue => {
                        onChange(inputValue)
                      }}
                      onBlur={onBlur}
                      error={!!errors && !!errors[index]?.amount}
                      helperText={errors ? errors[index]?.amount?.message : ''}
                    />
                  )}
                />
                <div
                  className={classNames(styles.btn, styles.btn_remove, {
                    [styles.visible]: index !== 0,
                  })}
                  onClick={() => onRemoveProperty(index)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}>
                  <PropAddImg />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.rowButtons}>
          {currentProperties.length < 10 && (
            <button
              type="button"
              className={classNames(styles.btn, styles.btn_add)}
              onClick={onAddProperty}
              disabled={currentProperties.some(current => !current.size && !current.amount)}>
              <PropAddImg />
            </button>
          )}
        </div>
      </div>
    )
  },
)

export default Advance
