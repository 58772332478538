import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Clock} from '../../../assets/img/icons/clock.svg'
import {TokenStatus} from '../../../types/tokenStatus'

import styles from './PendingStatus.module.scss'

interface IPendingStatusProps {
  className?: string
  pendingStatus: TokenStatus
}

const PENDING_STATUS_MATCH: {[paramIndex in TokenStatus]: string} = {
  pending: 'mint',
  transfer_pending: 'transfer',
  exchange_pending: 'buy',
  burn_pending: 'burn',
  burned: '',
  committed: '',
}

const PendingStatus: React.FC<IPendingStatusProps> = ({className, pendingStatus}) => {
  const {t} = useTranslation()

  return (
    <div className={classNames(styles.container, className)}>
      <Clock />
      <div className={styles.status}>{t('pendingStatus.title')}</div>
      <div className={styles.description}>
        {t(`methodStatus.pending.${PENDING_STATUS_MATCH[pendingStatus]}`)}
      </div>
      <div className={styles.reloadLabel}>{t('pendingStatus.reloadPage')}</div>
    </div>
  )
}

export default PendingStatus
