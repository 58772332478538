import {ChangeEvent, useEffect, useRef, useState} from 'react'
import BigNumber from 'bignumber.js/bignumber'
import classNames from 'classnames'

import styles from './TextInput.module.scss'

interface ITextInputProps {
  className?: string
  label?: string
  name?: string
  type: string
  placeholder: string
  required?: boolean
  prefix?: string
  suffix?: any
  suffixClassName?: string
  onChange?: (value: any) => void
  onBlur?: (value: any) => void
  value?: string
  integer?: boolean
  positiveOnly?: boolean
  max?: number
  min?: number
  error?: boolean
  helperText?: string
  labelClassName?: string
}

const TextInput: React.FC<ITextInputProps> = ({
  integer = false,
  positiveOnly = false,
  className,
  label,
  prefix,
  suffix,
  onChange,
  value,
  suffixClassName,
  name,
  placeholder,
  max,
  min,
  type,
  error,
  helperText,
  labelClassName,
  ...props
}) => {
  const ref = useRef(null)
  const [elWidth, setElWidth] = useState(0)
  const prefixElement = (
    <div ref={ref} className={styles.prefix}>
      {prefix}
    </div>
  )

  const getRegex = () => {
    if (integer) {
      return positiveOnly ? /^[+]?[0-9]\d*$/ : /^[-+]?[0-9]\d*$/
    }
    return positiveOnly ? /^[+]?([.]\d+|\d+[.]?\d*)$/ : /^[-+]?([.]\d+|\d+[.]?\d*)$/
  }
  const checkMin = (comparingValue: string) => {
    const arrayedComparingValue = Array.from(String(comparingValue), Number)
    const arrayedMin = Array.from(String(min), Number)
    if (new BigNumber(min ?? 0).isLessThanOrEqualTo(comparingValue)) return true
    for (let i = 0; i < arrayedComparingValue.length; i += 1) {
      if (
        !(
          (
            new BigNumber(arrayedMin[i]).isLessThanOrEqualTo(
              new BigNumber(arrayedComparingValue[i]),
            ) || // every symbol should be more or equal to min value
            (Number.isNaN(arrayedMin[i]) && Number.isNaN(arrayedComparingValue[i])) || // '.' elements
            (arrayedComparingValue[i] !== undefined && arrayedMin[i] === undefined)
          ) // if arrayedComparingValue longer than arrayedMin
        )
      ) {
        return false
      }
    }
    return true
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const reg = getRegex()
    const inputValue = e.target.value
    if (onChange) {
      if (
        (!Number.isNaN(inputValue) && reg.test(inputValue)) ||
        (!positiveOnly && inputValue === '-')
      ) {
        if (max && min) {
          if (
            checkMin(inputValue) &&
            (new BigNumber(inputValue).isLessThan(new BigNumber(max)) ||
              new BigNumber(inputValue).isEqualTo(new BigNumber(max)))
          )
            onChange(e)
        } else if (max) {
          if (
            new BigNumber(inputValue).isLessThan(new BigNumber(max)) ||
            new BigNumber(inputValue).isEqualTo(new BigNumber(max))
          )
            onChange(e)
        } else if (min) {
          if (checkMin(inputValue)) onChange(e)
        } else onChange(e)
        // if (max) {
        //   if (new BigNumber(inputValue) <= new BigNumber(max)) onChange(e);
        // } else onChange(e);
      }
      if (inputValue === '') onChange(e)
    }
  }

  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line
      // @ts-ignore
      setElWidth(ref.current.offsetWidth)
    }
  }, [])
  return (
    <div className={classNames(styles.field, className)}>
      {label && <div className={classNames(styles.label, labelClassName)}>{label}</div>}
      <div className={styles.wrap}>
        {prefixElement}
        <input
          id={name}
          value={value}
          className={classNames(styles.input, {[styles.error]: error})}
          onChange={type === 'number' ? e => handleChange(e) : onChange}
          onWheel={e => e.currentTarget.blur()}
          placeholder={placeholder}
          type="text"
          {...props}
          style={
            elWidth
              ? {
                  paddingLeft: `${elWidth + 24}px`,
                  paddingRight: suffix ? '26px' : '',
                }
              : {paddingRight: suffix ? '26px' : ''}
          }
        />
        <div className={classNames(styles.suffix, suffixClassName)}>{suffix}</div>
      </div>
      <div className={classNames(styles.errorContainer, {[styles.error]: error})}>
        <div role="alert" className={styles.errorLabel}>
          {helperText}
        </div>
      </div>
    </div>
  )
}

export default TextInput
