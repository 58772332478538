import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import cn from 'classnames'

import styles from './Sellers.module.scss'

interface ISellersProps {
  className?: string
  owners: any
  openCheckout: (value: string) => void
  user: string | number
}

const Sellers: React.FC<ISellersProps> = ({className, owners, openCheckout, user}) => {
  const {t} = useTranslation()
  return (
    <div className={cn(className, styles.sellers)}>
      <div className={cn('h4', styles.title)}>{t('token.nav.owners')}</div>
      <div className={styles.table}>
        {owners
          .filter((owner: any) => owner.id !== user && owner.price)
          .map((ownerData: any) => (
            <div className={styles.item} key={nextId()}>
              <div className={styles.user}>
                <div className={styles.avatar}>
                  <img src={ownerData.avatar} alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.name}>
                    {ownerData.name.length > 21
                      ? `${ownerData.name.slice(0, 14)}...${ownerData.name.slice(-4)}`
                      : ownerData.name}
                  </div>
                  <div className={styles.amount}>{ownerData.quantity} token</div>
                </div>
              </div>
              <div className={styles.actions}>
                <div className={styles.balance}>
                  <div className={cn('text-gradient', styles.price)}>{ownerData.price}</div>
                  <div className={cn('text-gradient', styles.currency)}>
                    {ownerData.currency.symbol.toUpperCase()}
                  </div>
                </div>
                <button
                  type="button"
                  className={cn('button', styles.button)}
                  onClick={() => openCheckout(ownerData.id)}>
                  {t('token.purchase')}
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Sellers
