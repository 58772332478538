import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import cn from 'classnames'
import {observer} from 'mobx-react'

import iconSwap from '../../../../assets/img/icons/arrows-swap.svg'
import {useWalletConnectorContext} from '../../../../services/walletConnect'
import {Wallet} from '../../../../services/walletService'
import {useMst} from '../../../../store/store'
import {MainCoinType, WrapCoinType} from '../../../../types/coin'
import {getCoinDecimalsByName, getCoinNameByType} from '../../../../utils/coins'
import Button from '../../../Button'
import TextInput from '../../../TextInput'

import styles from './Swap.module.scss'

interface ISwapProps {
  className?: string
  close: () => void
  handleUpdateBalance: () => Promise<any>
}

const Swap: React.FC<ISwapProps> = observer(({className, close, handleUpdateBalance}) => {
  const {t} = useTranslation()
  const {
    user: {balances},
  } = useMst()
  const mainCurrency = getCoinNameByType('main') as MainCoinType
  const wrapCurrency = getCoinNameByType('wrap') as WrapCoinType
  const [swappingCurrency, setSwappingCurrency] = useState<Array<'main' | 'wrap'>>(['main', 'wrap'])
  const [isLoading, setLoading] = useState<boolean>(false)
  const walletConnector = useWalletConnectorContext()
  const [payInput, setPayInput] = useState('')
  const handleConvert = (): void => {
    if (swappingCurrency[0] === 'main') {
      setSwappingCurrency(['wrap', 'main'])
    } else {
      setSwappingCurrency(['main', 'wrap'])
    }
    setPayInput('')
  }
  const handleSubmitConvert = (): void => {
    const weiValue = Wallet.calcTransactionAmount(
      payInput,
      getCoinDecimalsByName(swappingCurrency[0] === 'main' ? mainCurrency : wrapCurrency),
    )
    setLoading(true)
    if (swappingCurrency[0] === 'main') {
      walletConnector.walletService
        .createTransaction('deposit', [], wrapCurrency, '', '', '', weiValue)
        .then(async () => {
          await handleUpdateBalance()
          setLoading(false)
          close()
        })
        .catch((err: any) => {
          setLoading(false)
          console.log('error', err)
        })
    } else {
      walletConnector.walletService
        .createTransaction('withdraw', [weiValue], wrapCurrency)
        .then(async () => {
          await handleUpdateBalance()
          setLoading(false)
          close()
        })
        .catch((err: any) => {
          setLoading(false)
          console.log('error', err)
        })
    }
  }

  const currentBalance = React.useMemo(() => {
    if (swappingCurrency[0] === 'main') {
      return balances.toJSON().find(balance => balance.type === 'main')?.amount || '0'
    }
    return balances.toJSON().find(balance => balance.type === 'wrap')?.amount || '0'
  }, [swappingCurrency, balances])

  return (
    <div className={cn(className, styles.swap)}>
      <div className={cn('h4', styles.title)}>{t('convert')}</div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.label}>{t('you_pay')}</span>
          <span className={styles.amount}>
            {t('max_amount')} {currentBalance}
          </span>
        </div>
        <TextInput
          className={styles.field}
          type="number"
          name="Pay input"
          label=""
          placeholder={t('enter_amount')}
          value={payInput}
          onChange={e => setPayInput(e.target.value)}
          suffix={swappingCurrency[0] === 'main' ? mainCurrency : wrapCurrency}
          suffixClassName={styles.suffix}
          positiveOnly
        />
      </div>
      <div className={styles.iconWrapper}>
        <div
          className={styles.icon}
          onClick={handleConvert}
          onKeyDown={() => {}}
          tabIndex={0}
          role="button">
          <img src={iconSwap} alt="Swap" />
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.label}>{t('you_recieve')}</span>
        </div>
        <TextInput
          className={styles.field}
          type="number"
          name="Pay input"
          label=""
          placeholder={t('amount_receive')}
          value={payInput}
          onChange={e => setPayInput(e.target.value)}
          suffix={swappingCurrency[1] === 'main' ? mainCurrency : wrapCurrency}
          suffixClassName={styles.suffix}
          positiveOnly
        />
      </div>
      <div className={styles.btns}>
        <Button
          className={cn('button', styles.button)}
          onClick={handleSubmitConvert}
          loading={isLoading}
          disabled={!payInput || (!!payInput && +payInput > +currentBalance)}>
          {t('convert')}
        </Button>
      </div>
    </div>
  )
})

export default Swap
