import {Fragment, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import OutsideClickHandler from 'react-outside-click-handler'
import {Link} from 'react-router-dom'
import {OpenWalletIntent, Settings} from '@0xsequence/provider'
import {sequence} from '0xsequence'
import BigNumber from 'bignumber.js/bignumber'
import cn from 'classnames'
import copyToClipboard from 'copy-to-clipboard'
import {observer} from 'mobx-react'

import {BLOCKCHAIN, PROVIDER} from '../../../constants/constants'
// import {ratesApi} from '../../../services/api'
import {useWalletConnectorContext} from '../../../services/walletConnect'
import {Wallet} from '../../../services/walletService'
import {useMst} from '../../../store/store'
import {Balance} from '../../../store/User'
import {formatCoinToken, getCoinNameByType, getCoins} from '../../../utils/coins'
import Button from '../../Button'
import Icon from '../../Icon'
import Loader from '../../Loader'
import Modal from '../../Modal'
import Theme from '../../Theme'

import Swap from './Swap'

import styles from './User.module.scss'

interface IUserProps {
  className?: string
}

const User: React.FC<IUserProps> = observer(({className}) => {
  const {t} = useTranslation()
  const [visible, setVisible] = useState(false)
  // const [rates, setRates] = useState<any>([])
  const [visibleModal, setVisibleModal] = useState(false)
  const [copyAddress, setCopyAddress] = useState(false)
  const walletConnector = useWalletConnectorContext()
  const {
    user: {
      id,
      custom_url,
      address,
      avatar,
      balances,
      setBalances,
      refreshRates,
      rates,
      isLoadingBalance,
      display_name,
      coins,
    },
  } = useMst()

  const items: Array<{
    title: string
    icon?: 'user' | 'image' | 'bulb' | 'exit' | 'wallet'
    url?: string
    onClick?: () => void
  }> = [
    {
      title: t('profile'),
      icon: 'user',
      url: '/profile',
    },
    // {
    //   title: t('darkTheme'),
    //   icon: 'bulb',
    //   url: '',
    // },
    {
      title: t('disconnect'),
      icon: 'exit',
      onClick: () => walletConnector.disconnect(),
    },
  ]

  if (localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE) {
    items.splice(1, 0, {
      title: t('openWallet'),
      icon: 'wallet',
      onClick: () => {
        if (visible) setVisible(!visible)
        const wallet = sequence.getWallet()
        if (!wallet.isConnected()) {
          console.error('Connect first')
          return
        }
        const settings: Settings = {
          theme: 'mauveDark',
          bannerUrl: `${window.location.origin}/images/kephi-wallet-background.png`,
          includedPaymentProviders: ['wyre'],
          defaultFundingCurrency:
            localStorage.kephi_nft_chainName === BLOCKCHAIN.POLYGON ? 'matic' : 'eth',
          lockFundingCurrencyToDefault: false,
        }
        const intent: OpenWalletIntent = {
          type: 'openWithOptions',
          options: {
            settings,
          },
        }
        wallet.openWallet('', intent)
      },
    })
  }

  const handleCopy = () => {
    copyToClipboard(address)
    setCopyAddress(true)
  }

  const fetchBalance = useCallback(async () => {
    try {
      let promises: Array<Promise<any>> = []

      promises = coins.map(coin =>
        walletConnector.walletService.getTokenBalance(formatCoinToken(coin.name)),
      )

      Promise.allSettled(promises).then((data: any) => {
        let balancesArray: Balance[] = []
        coins.forEach((coin, index) => {
          balancesArray = [
            ...balancesArray,
            {
              amount:
                data[index]?.status === 'fulfilled'
                  ? Wallet.weiToEth(data[index].value, coin.decimals)
                  : '0',
              symbol: coin.name,
              type: coin.type,
            } as Balance,
          ]
        })
        setBalances(balancesArray)
      })
    } catch (error) {
      // console.log('error', error)
    }
  }, [coins, walletConnector.walletService, setBalances])

  const fetchRates = useCallback(() => {
    try {
      // ratesApi.getRates().then(({data}: any) => {
      //   // setRates(data)
      //   setRates(data)
      // })
      refreshRates()
    } catch (error) {
      // console.log('error', error)
    }
  }, [refreshRates])

  useEffect(() => {
    let interval: any | null = null
    if (address) {
      setTimeout(() => fetchBalance(), 500)
      interval = setInterval(fetchBalance, 30000)
      fetchRates()
    } else if (interval) {
      clearInterval(interval)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [fetchBalance, fetchRates, address])

  const getCoinRateImage = (symbol: string) => {
    return rates
      ?.toJSON()
      ?.find((rate: any) => rate.symbol.toLocaleLowerCase() === symbol.toLocaleLowerCase())?.image
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className)}>
        <div
          tabIndex={0}
          onKeyDown={() => {}}
          role="button"
          className={styles.head}
          onClick={() => {
            setVisible(!visible)
          }}>
          <div className={styles.avatar}>
            {avatar ? <img src={avatar} alt="Avatar" /> : <Loader />}
          </div>
          <div className={styles.wallet}>
            {isLoadingBalance ? (
              <Skeleton width={70} height={17} />
            ) : (
              <>
                {(+(
                  balances.toJSON().find(balance => balance.type === 'main')?.amount || '0'
                )).toFixed(3)}{' '}
                <span className={styles.currency}>{getCoinNameByType('main')}</span>
              </>
            )}
          </div>
        </div>
        {visible && (
          <div className={styles.body}>
            <div className={styles.name}>{display_name || t('user_text')}</div>
            <div className={styles.code}>
              <div className={styles.number}>
                {address.slice(0, 14)}...{address.slice(-4)}
              </div>
              <Button
                className={styles.copy}
                onClick={handleCopy}
                onMouseLeave={() => setCopyAddress(false)}>
                <Icon name="copy" size="16" />
                <div className={styles.tooltip}>
                  <span className={styles.tooltiptext}>
                    {copyAddress ? 'Success!' : 'Copy adress'}
                  </span>
                </div>
              </Button>
            </div>
            <div className={styles.wrap}>
              {!!localStorage.kephi_nft_chainName &&
                getCoins().map(
                  coin =>
                    !!getCoinRateImage(coin.name) && (
                      <div className={styles.line} key={coin.name}>
                        <div className={styles.preview}>
                          <img src={getCoinRateImage(coin.name)} alt="" />
                        </div>
                        <div className={styles.details}>
                          <div className={styles.info}>{t('balance')}</div>
                          <div className={styles.price}>
                            <span>
                              {new BigNumber(
                                balances.toJSON().find(balance => balance.symbol === coin.name)
                                  ?.amount || '0',
                                // balance[coin.type as keyof typeof Balance.properties],
                              ).toFixed()}{' '}
                            </span>
                            {coin.name.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    ),
                )}
            </div>
            <div className={styles.swapWrapper}>
              <Button
                className={cn('button-stroke', styles.swap)}
                onClick={() => setVisibleModal(true)}>
                {t('convert')}
              </Button>
            </div>
            <div className={styles.menu}>
              {items.map(item => (
                <Fragment key={item.title}>
                  {item.url ? (
                    <Link
                      className={styles.item}
                      to={`${item.url}/${custom_url || id}`}
                      onClick={() => setVisible(!visible)}
                      key={item.title}
                      replace>
                      {item.icon && (
                        <div className={styles.icon}>
                          <Icon name={item.icon} size="20" />
                        </div>
                      )}
                      <div className={styles.text}>{item.title}</div>
                    </Link>
                  ) : (
                    <div
                      tabIndex={0}
                      className={styles.item}
                      key={item.title}
                      {...(!!item.onClick && {onClick: item.onClick})}
                      role="button"
                      onKeyDown={() => {}}>
                      {item.icon && (
                        <div className={styles.icon}>
                          <Icon name={item.icon} size="20" />
                        </div>
                      )}
                      <div className={styles.text}>{item.title}</div>
                      {item.title === t('darkTheme') && <Theme className={styles.theme} />}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        <Modal visible={visibleModal} onClose={() => setVisibleModal(false)} closeOnRight>
          <Swap close={() => setVisibleModal(false)} handleUpdateBalance={fetchBalance} />
        </Modal>
      </div>
    </OutsideClickHandler>
  )
})

export default User
