import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {Form, notification} from 'antd'
import BigNumber from 'bignumber.js/bignumber'
import cn from 'classnames'
import {FieldArray, FormikProps} from 'formik'
import {observer} from 'mobx-react-lite'

import {ReactComponent as PropAddImg} from '../../../assets/img/icons/prop-add.svg'
import {ReactComponent as PropRemoveImg} from '../../../assets/img/icons/prop-delete.svg'
import ClearImg from '../../../assets/img/icons/uploader-cross.svg'
import Button from '../../../components/Button'
import DropdownWithImage from '../../../components/DropdownWithImage'
import Icon from '../../../components/Icon'
import Loader from '../../../components/Loader'
import Modal from '../../../components/Modal'
import SuccessCreated from '../../../components/SuccessCreated'
import Switch from '../../../components/Switch'
import TextInput from '../../../components/TextInput'
import Uploader from '../../../components/Uploader'
import {ratesApi, storeApi} from '../../../services/api'
import {useMst} from '../../../store/store'
import {getCoinNameByType} from '../../../utils/coins'
import {validateField} from '../../../utils/validate'

import ChooseCollection from './ChooseCollection'
import Preview from './Preview'

import styles from './CreateCollectibleDetails.module.scss'

interface IProperti {
  size: string | number
  amount: string | number
}

export interface ICreateForm {
  img: any
  preview: string
  coverPreview: string
  putOnSale: boolean
  instantSalePrice: boolean
  instantSalePriceEth: string
  cover: any
  tokenName: string
  tokenDescr: string
  tokenRoyalties: string
  numberOfCopies: number | string
  tokenProperties: IProperti[]
  isSingle?: boolean
  isLoading: boolean
  collectionId: string
  currency: string
  bid: string
  format: string
  tag: string
  showModal: boolean
  unlockOncePurchased: boolean
  digitalKey: string
}

const CreateForm: React.FC<FormikProps<ICreateForm> & ICreateForm> = observer(
  ({
    setFieldValue,
    resetForm,
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSingle,
  }) => {
    const {t} = useTranslation()
    const history = useHistory()
    const {user} = useMst()
    const mainCurrency = getCoinNameByType('main').toLocaleLowerCase()
    const wrapCurrency = getCoinNameByType('wrap').toLocaleLowerCase()
    const [rates, setRates] = useState<
      Array<{
        rate: string
        symbol: string
      }>
    >([])
    const [serviceFee, setServiceFee] = useState(0) // TODO: remove after get service fee request

    const onSubmit = () => {
      if (!values.img) {
        notification.error({message: t('create_form.uploadChooseFile')})
        return
      }
      if ((values.format === 'video' || values.format === 'audio') && !values.cover) {
        notification.error({message: t('create_form.uploadChooseFileCover')})
        return
      }
      handleSubmit()
    }
    const handleChangeProperty = (e: any, index: any, type: any) => {
      const localProperties = [...values.tokenProperties]

      if (type === 'size') {
        localProperties[index].size = e.target.value
      }
      if (type === 'amount') {
        localProperties[index].amount = e.target.value
      }
      setFieldValue('tokenProperties', localProperties)
      handleChange(e)
    }

    const handleAddProperty = useCallback(() => {
      setFieldValue('tokenProperties', [
        ...values.tokenProperties,
        {
          size: '',
          amount: '',
        },
      ])
    }, [setFieldValue, values.tokenProperties])

    const handleRemoveProperty = useCallback(
      (elemIndex: number) => {
        const newValue = values.tokenProperties.filter((_, index) => index !== elemIndex)

        setFieldValue('tokenProperties', newValue)
      },
      [setFieldValue, values.tokenProperties],
    )

    const fetchRates = useCallback(() => {
      ratesApi.getRates().then(({data}: any) => {
        setRates(data)
      })
    }, [])

    const fetchFee = useCallback(() => {
      storeApi.getFee(values.currency === 'kphi' ? 'kphi' : '').then(({data}: any) => {
        setServiceFee(data)
      })
    }, [values.currency])

    const currentCurrPrice = React.useMemo(() => {
      if (values.instantSalePriceEth) {
        return (parseFloat(values.instantSalePriceEth) * (100 - serviceFee)) / 100
      }
      if (values.bid) {
        return (parseFloat(values.bid) * (100 - serviceFee)) / 100
      }
      return ''
    }, [values.instantSalePriceEth, serviceFee, values.bid])

    const renderCover = () => {
      return (
        <div className={styles.item}>
          {values.cover ? (
            <div className={styles.previewImg}>
              <img src={values.coverPreview} alt="Preview" />

              <div
                className={styles.clear}
                onClick={() => {
                  setFieldValue('cover', '')
                  setFieldValue('coverPreview', '')
                }}
                onKeyDown={() => {
                  setFieldValue('cover', '')
                  setFieldValue('coverPreview', '')
                }}
                role="button"
                tabIndex={0}>
                <img src={ClearImg} alt="" />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.category}>{t('create_form.upload_preview')}</div>
              <div className={styles.note}>{t('create_form.upload_drag')}</div>
              <div className={styles.file}>
                <Form.Item
                  name="cover"
                  className={styles.load}
                  validateStatus={validateField('cover', touched, errors)}
                  help={!touched.cover ? false : errors.cover}
                  required>
                  <Uploader
                    type="cover"
                    name="cover"
                    setFormat={(value: string) => setFieldValue('format', value)}
                  />
                </Form.Item>
                <div className={styles.icon}>
                  <img alt="" src="/images/content/icon-upload.svg" />
                </div>
                <div className={styles.format}>{t('create_form.upload_text2')}</div>
              </div>
            </>
          )}
        </div>
      )
    }

    const renderImg = () => {
      return (
        <div className={styles.item}>
          {values.img ? (
            <div className={styles.previewImg}>
              {values.format === 'image' && <img src={values.preview} alt="Media" />}
              {values.format === 'video' && (
                <video controls>
                  <source src={values.preview} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                  <track kind="captions" />
                </video>
              )}
              {values.format === 'audio' && (
                <audio controls>
                  <source src={values.preview} />
                  <track kind="captions" />
                </audio>
              )}
              <div
                className={styles.clear}
                onClick={() => {
                  setFieldValue('img', '')
                  setFieldValue('preview', '')
                }}
                onKeyDown={() => {
                  setFieldValue('img', '')
                  setFieldValue('preview', '')
                }}
                role="button"
                tabIndex={0}>
                <img src={ClearImg} alt="" />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.category}>{t('create_form.upload_file')}</div>
              <div className={styles.note}>{t('create_form.upload_drag')}</div>
              <div className={styles.file}>
                <Form.Item
                  name="img"
                  className={styles.load}
                  validateStatus={validateField('img', touched, errors)}
                  help={!touched.img ? false : errors.img}
                  required>
                  <Uploader
                    type="img"
                    name="img"
                    setFormat={(value: string) => setFieldValue('format', value)}
                    setTag={(value: string) => setFieldValue('tag', value)}
                  />
                </Form.Item>
                <div className={styles.icon}>
                  <img alt="" src="/images/content/icon-upload.svg" />
                </div>
                <div className={styles.format}>{t('create_form.upload_text')}</div>
              </div>
            </>
          )}
        </div>
      )
    }

    const onClear = useCallback(() => {
      resetForm()
      setFieldValue('tokenProperties', [
        {
          size: '',
          amount: '',
        },
      ])
    }, [resetForm, setFieldValue])

    useEffect(() => {
      if (values.instantSalePrice) {
        setFieldValue('currency', mainCurrency)
      } else {
        setFieldValue('currency', wrapCurrency)
      }
    }, [values.instantSalePrice, setFieldValue, mainCurrency, wrapCurrency])

    useEffect(() => {
      fetchRates()
      fetchFee()
    }, [fetchRates, fetchFee, values.currency])

    useEffect(() => {
      setFieldValue('instantSalePriceEth', '')
      setFieldValue('bid', '')
    }, [setFieldValue, values.putOnSale, values.instantSalePrice])

    return (
      <div className={styles.formWrapper}>
        <Form name="form-create" className={styles.form} layout="vertical">
          <div className={styles.list}>
            {(values.format === 'video' || values.format === 'audio') && renderCover()}
            {renderImg()}
            <div className={styles.options}>
              <div className={styles.option}>
                <div className={styles.box}>
                  <div className={styles.flexBox}>
                    <div className={styles.category}>{t('token.put_on_sale')}</div>
                    <div className={styles.tooltipInfo}>
                      <p>{t('tooltipInfo.putOnSale')}</p>
                    </div>
                  </div>
                  <div className={styles.text}>{t('create_form.text1')}</div>
                </div>
                <Switch
                  id="putOnSale"
                  value={values.putOnSale}
                  setValue={value => setFieldValue('putOnSale', value)}
                />
              </div>
            </div>
            {values.putOnSale ? (
              <div className={styles.options}>
                <div className={styles.option}>
                  <div className={styles.box}>
                    <div className={styles.flexBox}>
                      <div className={styles.category}>{t('token.inst_sale_price')}</div>
                      <div className={styles.tooltipInfo}>
                        <p>{t('tooltipInfo.instSalePrice')}</p>
                      </div>
                    </div>
                    <div className={styles.text}>{t('create_form.text2')}</div>
                  </div>
                  <Switch
                    id="instantSalePrice"
                    value={values.instantSalePrice}
                    setValue={value => setFieldValue('instantSalePrice', value)}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            {values.putOnSale && (
              <>
                <Form.Item
                  name={values.instantSalePrice ? 'instantSalePriceEth' : 'bid'}
                  validateStatus={
                    values.instantSalePrice
                      ? validateField('instantSalePriceEth', touched, errors)
                      : validateField('bid', touched, errors)
                  }
                  help={
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line
                    values.instantSalePrice
                      ? !touched.instantSalePriceEth
                        ? false
                        : errors.instantSalePriceEth
                      : !touched.bid
                      ? false
                      : errors.bid
                  }>
                  <div style={{position: 'relative'}} className={styles.fieldset}>
                    <TextInput
                      name={values.instantSalePrice ? 'instantSalePriceEth' : 'bid'}
                      className={cn(styles.field, styles.priceBid)}
                      label={
                        values.instantSalePrice
                          ? t('create_form.enter_price')
                          : t('create_form.minimal_bid')
                      }
                      value={
                        values.instantSalePrice ? values.instantSalePriceEth.toString() : values.bid
                      }
                      positiveOnly
                      placeholder={
                        values.instantSalePrice
                          ? t('create_form.enter_price_place')
                          : t('create_form.minimal_bid_place')
                      }
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!!rates.length && (
                      <DropdownWithImage
                        className={styles.cryptocurrenciesDropdown}
                        value={values.currency.toUpperCase()}
                        options={(!values.instantSalePrice
                          ? [
                              ...rates.filter(
                                (rate: any) => rate.symbol.toLocaleLowerCase() !== mainCurrency,
                              ),
                            ]
                          : rates
                        ).map(rate => rate.symbol.toUpperCase())}
                        setValue={(value: string) => setFieldValue('currency', value)}
                        customClasses={{
                          head: styles.cryptocurrenciesDropdownHead,
                          selection: styles.cryptocurrenciesDropdownSelection,
                          arrow: styles.cryptocurrenciesDropdownArrow,
                        }}
                      />
                    )}
                  </div>
                </Form.Item>
                <div className={styles.fee}>
                  <span className={styles.feeItem}>
                    {t('service_fee')}{' '}
                    <span className={cn(styles.fieldSuggestion, styles.serviceFee)}>
                      {serviceFee}%
                    </span>
                  </span>
                  {values.instantSalePriceEth || values.bid ? (
                    <span className={styles.feeItem}>
                      {t('token.you_recieve')}{' '}
                      <span className={cn(styles.fieldSuggestion, styles.recieve)}>
                        {currentCurrPrice} {values.currency.toUpperCase()}
                      </span>{' '}
                      <div className={styles.recieveD}>
                        U$S{' '}
                        {new BigNumber(currentCurrPrice)
                          .multipliedBy(
                            rates.filter(
                              (rate: any) =>
                                rate.symbol.toLocaleLowerCase() ===
                                values.currency.toLocaleLowerCase(),
                            )[0].rate,
                          )
                          .toString()}
                      </div>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}
          </div>
          <div className={styles.list}>
            <div className={styles.options}>
              <div className={styles.option}>
                <div className={styles.box}>
                  <div className={styles.flexBox}>
                    <div className={styles.category}>{t('create_form.unlock1')}</div>
                    <div className={styles.tooltipInfo}>
                      <p>{t('tooltipInfo.unlockOncePurchased')}</p>
                    </div>
                  </div>
                  <div className={styles.text}>{t('create_form.unlock2')}</div>
                </div>
                <Switch
                  id="unlockOncePurchased"
                  value={values.unlockOncePurchased}
                  setValue={value => setFieldValue('unlockOncePurchased', value)}
                />
              </div>
            </div>
            {values.unlockOncePurchased && (
              <Form.Item
                name="digitalKey"
                validateStatus={validateField('digitalKey', touched, errors)}
                help={!touched.digitalKey ? false : errors.digitalKey}>
                <div style={{position: 'relative'}} className={styles.fieldset}>
                  <TextInput
                    name="digitalKey"
                    className={cn(styles.field, styles.priceBid)}
                    label={t('create_form.unlock3')}
                    value={values.digitalKey}
                    placeholder={t('create_form.unlock4')}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Form.Item>
            )}
          </div>
          <div className={styles.list}>
            {user.address ? (
              <div style={{marginBottom: '60px'}}>
                <ChooseCollection isSingle={isSingle} />
              </div>
            ) : (
              ''
            )}
            <div className={styles.item}>
              <div className={styles.category}>{t('create_form.details')}</div>
              <div className={styles.fieldset}>
                <Form.Item
                  name="tokenName"
                  className={styles.field}
                  validateStatus={validateField('tokenName', touched, errors)}
                  help={!touched.tokenName ? false : errors.tokenName}>
                  <div>
                    <TextInput
                      label={t('create_form.item_name')}
                      name="tokenName"
                      type="text"
                      placeholder={t('create_form.text3')}
                      value={values.tokenName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  className={styles.field}
                  name="tokenDescr"
                  validateStatus={validateField('tokenDescr', touched, errors)}
                  help={!touched.tokenDescr ? false : errors.tokenDescr}>
                  <div>
                    <TextInput
                      label={t('create_form.descr')}
                      name="tokenDescr"
                      type="text"
                      placeholder={t('create_form.text4')}
                      value={values.tokenDescr}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // required
                    />
                  </div>
                </Form.Item>
                {!isSingle && (
                  <Form.Item
                    className={styles.field}
                    name="numberOfCopies"
                    validateStatus={validateField('numberOfCopies', touched, errors)}
                    help={!touched.numberOfCopies ? false : errors.numberOfCopies}>
                    <div>
                      <TextInput
                        className={styles.field}
                        label={t('create_form.item_amount')}
                        name="numberOfCopies"
                        type="number"
                        placeholder={t('create_form.text5')}
                        value={values.numberOfCopies.toString()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                    </div>
                  </Form.Item>
                )}
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={cn(styles.royaltiesBox, styles.padding)}>
                      <div className={styles.royaltiesLabel}>{t('create_form.royalt')}</div>
                      <div className={cn(styles.tooltipInfo, styles.royaltiesTooltip)}>
                        <p>{t('tooltipInfo.royaltiesInfo')}</p>
                      </div>
                    </div>
                    <Form.Item
                      className={styles.field}
                      name="tokenRoyalties"
                      validateStatus={validateField('tokenRoyalties', touched, errors)}
                      help={!touched.tokenRoyalties ? false : errors.tokenRoyalties}>
                      <div>
                        <TextInput
                          className={styles.field}
                          name="tokenRoyalties"
                          type="number"
                          placeholder={t('create_form.text6')}
                          value={values.tokenRoyalties}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          suffix="%"
                          positiveOnly
                          required
                        />
                      </div>
                    </Form.Item>
                  </div>

                  <div className={styles.colBig}>
                    <div className={styles.rowSmall}>
                      <div className={cn(styles.colSmall, styles.label)}>
                        <div>{t('create_form.property')}</div>
                      </div>
                      <div className={cn(styles.colSmall, styles.label)}>
                        <div>{t('create_form.value')}</div>
                      </div>
                    </div>
                    <FieldArray
                      name="tokenProperties"
                      render={() => {
                        return values.tokenProperties?.map((item: any, index: any) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div className={styles.rowSmall} key={`tokenProperty${index}`}>
                            <div className={styles.colSmall}>
                              <Form.Item
                                className={styles.field}
                                name={`tokenProperties[${index}].size`}
                                validateStatus={validateField(`tokenProperties`, touched, errors)}
                                help={(() => {
                                  return errors.tokenProperties &&
                                    errors.tokenProperties[index] &&
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    // eslint-disable-next-line no-param-reassign
                                    errors.tokenProperties[index].size
                                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      // eslint-disable-next-line no-param-reassign
                                      errors.tokenProperties[index].size
                                    : false
                                })()}>
                                <div>
                                  <TextInput
                                    type="text"
                                    name={`size${index}`}
                                    placeholder={t('create_form.text7')}
                                    onChange={e => handleChangeProperty(e, index, 'size')}
                                    onBlur={handleBlur}
                                    value={item.size}
                                  />
                                </div>
                              </Form.Item>
                            </div>

                            <div className={styles.colSmall}>
                              <Form.Item
                                className={styles.field}
                                name={`tokenProperties[${index}].amount`}
                                validateStatus={validateField(`tokenProperties`, touched, errors)}
                                help={(() => {
                                  return errors.tokenProperties &&
                                    errors.tokenProperties[index] &&
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    // eslint-disable-next-line no-param-reassign
                                    errors.tokenProperties[index].amount
                                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      // eslint-disable-next-line no-param-reassign
                                      errors.tokenProperties[index].amount
                                    : false
                                })()}>
                                <div>
                                  <TextInput
                                    name="Amount"
                                    type="text"
                                    placeholder="e. g. M"
                                    onChange={e => handleChangeProperty(e, index, 'amount')}
                                    onBlur={handleBlur}
                                    value={item.amount}
                                  />
                                </div>
                              </Form.Item>
                            </div>
                            <div className={styles.btns}>
                              {values.tokenProperties.length !== 1 ? (
                                <div
                                  className={cn(styles.btn, styles.btn_remove)}
                                  onClick={() => handleRemoveProperty(index)}
                                  role="button"
                                  tabIndex={0}
                                  onKeyDown={() => {}}>
                                  <PropRemoveImg />
                                </div>
                              ) : null}
                              {values.tokenProperties.length === index + 1 &&
                              values.tokenProperties.length < 10 ? (
                                <div
                                  className={cn(styles.btn, styles.btn_add)}
                                  onClick={handleAddProperty}
                                  role="button"
                                  tabIndex={0}
                                  onKeyDown={() => {}}>
                                  <PropAddImg />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button className={cn('button', styles.button)} onClick={onSubmit}>
            <span>{t('create_form.btn')}</span>
            <Icon name="arrow-next" size="10" />
          </Button>
        </Form>
        {rates.length ? (
          <Preview
            className={cn(styles.preview)}
            onClose={() => {}}
            mediaURL={values.format === 'image' ? values.preview : values.coverPreview}
            name={values.tokenName}
            price={values.instantSalePriceEth.toString()}
            format={values.format}
            onClear={onClear}
            currency={values.currency.toUpperCase()}
            bid={values.bid}
            amount={values.numberOfCopies.toString()}
            avatar={user.avatar || ''}
          />
        ) : (
          ''
        )}

        <Modal visible={values.showModal} onClose={() => setFieldValue('showModal', false)}>
          <SuccessCreated
            onOk={() => {
              setFieldValue('showModal', false)
              history.push('/upload-variants')
            }}
            title={t('token.mintSuccess')}
            btnText={t('token.mintAnotherOne')}
          />
        </Modal>

        {values.isLoading && (
          <div className={styles.parentDisable}>
            <div className={styles.overlayBox}>
              <Loader className={styles.loader} color="white" />
            </div>
          </div>
        )}
      </div>
    )
  },
)

export default CreateForm
