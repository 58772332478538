import {useState} from 'react'
import nextId from 'react-id-generator'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from 'classnames'

// import { useTranslation } from 'react-i18next';
import Icon from '../Icon'

import styles from './Dropdown.module.scss'

interface IDropdownProps {
  className?: string
  value: string
  setValue: (str: string) => void
  options: Array<any>
  isWithImage?: boolean
  isWritable?: boolean
  name?: string
  placeholder?: string
}

const Dropdown: React.FC<IDropdownProps> = ({
  className,
  value,
  setValue,
  options,
  isWithImage,
  isWritable,
  name,
  placeholder,
}) => {
  // const { t } = useTranslation();
  const [visible, setVisible] = useState(false)

  const handleClick = (str: string) => {
    setValue(str)
    setVisible(false)
  }

  const showPlaceholder = placeholder && !value

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={classNames(styles.dropdown, className, {[styles.active]: visible})} id={name}>
        <div
          onKeyDown={() => {}}
          tabIndex={0}
          role="button"
          className={styles.head}
          onClick={() => setVisible(!visible)}>
          {isWritable ? (
            <input value={value} className={styles.input} />
          ) : (
            <div className={classNames(styles.selection, {[styles.placeholder]: showPlaceholder})}>
              {showPlaceholder ? placeholder : value}
            </div>
          )}
          <div className={styles.arrow}>
            <Icon name="arrow-bottom" size="10" />
          </div>
        </div>
        {!isWithImage ? (
          <div className={styles.bodyContainer}>
            <div className={styles.body}>
              {typeof options[0] === 'string'
                ? options.map(option => (
                    <div
                      onKeyDown={() => {}}
                      tabIndex={0}
                      role="button"
                      className={classNames(styles.option, {
                        [styles.selectioned]: option === value,
                      })}
                      onClick={() => handleClick(option)}
                      key={nextId()}>
                      {option}
                    </div>
                  ))
                : options.map(option => (
                    <div
                      onKeyDown={() => {}}
                      tabIndex={0}
                      role="button"
                      className={classNames(
                        styles.option,
                        {
                          [styles.selectioned]: option.text === value,
                        },
                        option.text === value ? 'text-gradient' : '',
                      )}
                      onClick={() => handleClick(option.text)}
                      key={nextId()}>
                      {option.icon}
                      <span className={styles.text}>{option.text}</span>
                    </div>
                  ))}
            </div>
          </div>
        ) : (
          <div className={styles.bodyContainer}>
            <div className={styles.body}>
              {options.map((option: any) => (
                <div
                  onKeyDown={() => {}}
                  tabIndex={0}
                  role="button"
                  className={classNames(
                    styles.option,
                    {
                      [styles.selectioned]: option.symbol === value,
                    },
                    option.symbol === value ? 'text-gradient' : '',
                  )}
                  onClick={() => handleClick(option.symbol)}
                  key={nextId()}>
                  <img alt="" className={styles.image} src={option.image} />
                  <span className={styles.text}>{option.symbol}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default Dropdown
