import Skeleton from 'react-loading-skeleton'

import styles from './SearchRowSkeleton.module.scss'

const SearchRowSkeleton: React.FC = () => {
  return (
    <div className={styles.rowContainer}>
      <div className={styles.image}>
        <Skeleton circle className={styles.image} />
      </div>
      <div className={styles.description}>
        <Skeleton className={styles.name} />
        <Skeleton className={styles.address} />
      </div>
    </div>
  )
}

export default SearchRowSkeleton
