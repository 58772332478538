import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import controlStyles from '../Control.module.scss'

const ControlSkeleton = ({userClassName = {}}) => (
  <div className={classNames(controlStyles.control, userClassName)}>
    <div className={controlStyles.head}>
      <div className={controlStyles.details}>
        <div className={controlStyles.cost}>
          <Skeleton className={classNames(controlStyles.price)} width={90} height={30} />
          <Skeleton className={classNames(controlStyles.price)} width={90} height={30} />
        </div>
      </div>
    </div>
    <div className={classNames(controlStyles.btns, controlStyles.btnsSkeleton)}>
      <Skeleton
        className={classNames('button', controlStyles.button, controlStyles.buttonSkeleton)}
      />
      <Skeleton
        className={classNames(
          controlStyles.outlinedButton,
          controlStyles.button,
          controlStyles.buttonSkeleton,
        )}
      />
    </div>
    <Skeleton className={controlStyles.text} width="80%" count={2} />
  </div>
)

export default ControlSkeleton
