import ChooseCollection from '../components/StepsForm/ChooseCollection/ChooseCollection'
import ItemDetailsStep from '../components/StepsForm/ItemDetails/ItemDetails'
import TypeOfSaleStep from '../components/StepsForm/TypeOfSale/TypeOfSale'
import UploadFileStep from '../components/StepsForm/UploadFile/UploadFile'
import WeAreReadyStep from '../components/StepsForm/WeAreReady/WeAreReady'
import {IStepType} from '../types/Step'

export const steps: IStepType[] = [
  {
    number: 1,
    form: (lastStep, nextStep, onSubmit, currentData) => (
      <UploadFileStep
        lastStep={lastStep}
        nextStep={nextStep}
        onSubmit={onSubmit}
        currentData={currentData}
      />
    ),
  },
  {
    number: 2,
    form: (lastStep, nextStep, onSubmit, currentData, isSingle) => (
      <ItemDetailsStep
        lastStep={lastStep}
        nextStep={nextStep}
        onSubmit={onSubmit}
        currentData={currentData}
        isSingle={isSingle}
      />
    ),
  },
  {
    number: 3,
    form: (lastStep, nextStep, onSubmit, currentData) => (
      <TypeOfSaleStep
        lastStep={lastStep}
        nextStep={nextStep}
        onSubmit={onSubmit}
        currentData={currentData}
      />
    ),
  },
  {
    number: 4,
    form: (lastStep, nextStep, onSubmit, currentData, isSingle) => (
      <ChooseCollection
        lastStep={lastStep}
        nextStep={nextStep}
        onSubmit={onSubmit}
        currentData={currentData}
        isSingle={isSingle}
      />
    ),
  },
  {
    number: 5,
    form: (lastStep, nextStep, onSubmit, currentData) => (
      <WeAreReadyStep
        lastStep={lastStep}
        nextStep={nextStep}
        onSubmit={onSubmit}
        currentData={currentData}
      />
    ),
  },
]
