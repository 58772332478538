import {useCallback, useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import Dropdown from '../../../../components/Dropdown'
import FilterIcons from '../FilterIcons/FilterIcons'

import {FilterFormInputs} from './Model/FilterForm'
import * as filterForm from './Model/FilterForm'

import styles from './Filter.module.scss'

interface IFilterProps {
  className?: string
  onClose: () => void
  onApply: (data: FilterFormInputs) => void
  currentValues: FilterFormInputs
}

const Filter: React.FC<IFilterProps> = ({className, onClose, onApply, currentValues}) => {
  const {t} = useTranslation()
  const dateOptions = useCallback(
    () => [
      t('home.discover.filter.recently_added'),
      t('home.discover.filter.long_added'),
      t('home.discover.filter.highest_price'),
      t('home.discover.filter.the_lowest_price'),
      t('home.discover.filter.most_liked'),
      t('home.discover.filter.least_liked'),
    ],
    [t],
  )

  const {handleSubmit, watch, register, control, reset} = useForm<FilterFormInputs>({
    defaultValues: currentValues,
  })

  const [minPriceValue, maxPriceValue] = watch(['minPrice', 'maxPrice'])

  useEffect(() => {
    // console.log(minPriceValue, maxPriceValue)
  }, [minPriceValue, maxPriceValue])

  const handleApply = (data: FilterFormInputs) => {
    onApply(data)
    onClose()
  }

  return (
    <form className={classNames(styles.container, className)} onSubmit={handleSubmit(handleApply)}>
      <div className={styles.rowContainer}>
        <div className={styles.title}>{t('modalFilter.title')}</div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.columnRows}>
          <div className={styles.rowFlex}>
            <div className={styles.priceContainer}>
              <div className={styles.rangeLabel}>{t('modalFilter.priceRange')}</div>
              <div className={styles.priceAverage}>{t('modalFilter.averagePrice')}</div>
            </div>
            <Controller
              control={control}
              name={filterForm.ORDER_BY}
              render={({field}) => (
                <Dropdown
                  className={styles.dropdown}
                  value={field.value}
                  name={filterForm.ORDER_BY}
                  setValue={field.onChange}
                  options={dateOptions()}
                  placeholder={`${t('modalFilter.ordering')}...`}
                />
              )}
            />
          </div>
          <div className={styles.rowInputs}>
            <div className={styles.inputContainer}>
              <div className={styles.priceLabel}>{t('modalFilter.minPrice')}</div>
              <div className={classNames('button-outlined', styles.inputWrapper)}>
                <div className={styles.usd}>U$D</div>
                <input
                  className={styles.input}
                  type="number"
                  min={0}
                  max={maxPriceValue}
                  placeholder="0"
                  id={filterForm.MIN_PRICE}
                  {...register(filterForm.MIN_PRICE)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.priceLabel}>{t('modalFilter.maxPrice')}</div>
              <div className={classNames('button-outlined', styles.inputWrapper)}>
                <div className={styles.usd}>U$D</div>
                <input
                  className={styles.input}
                  type="number"
                  min={minPriceValue || 0}
                  // max={100}
                  placeholder="1000"
                  id={filterForm.MAX_PRICE}
                  {...register(filterForm.MAX_PRICE)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.columnRows}>
          <div className={styles.artTypeLabel}>{t('modalFilter.artType')}</div>
          <Controller
            control={control}
            name={filterForm.ART_TYPE}
            render={({field}) => (
              <FilterIcons
                className={styles.filtersContainer}
                onChangeFilterIcon={field.onChange}
                filterIconSelected={field.value}
                onlyExistingFilters
              />
            )}
          />
        </div>
      </div>
      <div className={styles.rowButtons}>
        <button
          type="button"
          className={classNames('button-outlined', styles.button)}
          onClick={() =>
            reset({
              artType: 'ALL ITEMS',
              minPrice: '',
              maxPrice: '',
              orderBy: '',
            })
          }>
          {t('reset')}
        </button>
        <button type="submit" className={classNames('button-outlined-gradient', styles.button)}>
          {t('apply')}
        </button>
      </div>
    </form>
  )
}

export default Filter
