export const PAYMENT_STATUS = {
  PENDING: 'pending',
  PROGRESS: 'progress',
  CANCELED: 'canceled',
  FAILED: 'failed',
  SUCCESS: 'success',
  FAILOVER: 'failover',
}
export interface IPaymentStatus {
  status: string
  payment_id: string
  order_id: string
  tx_id: string // if available
}
