import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import cn from 'classnames'
import {observer} from 'mobx-react-lite'

import Loader from '../../../components/Loader'
import {PROFILE_TAB_INDEX} from '../../../constants/constants'
import {userApi} from '../../../services/api'
import {useMst} from '../../../store/store'

import styles from './Followers.module.scss'

interface IFollowersProps {
  className?: string
  isShowButtons: boolean
  following?: Array<{
    name: string
    followers_count: number
    avatar: string
    id: string
    buttonClass: string
    tokens: string[]
  }>
  followers?: Array<{
    name: string
    followers_count: number
    avatar: string
    id: string
    buttonClass: string
    tokens: string[]
  }>
  activeIndex: number
  isLoading: boolean
  isLoadingMore: boolean
  isFetching: boolean
  isMyPage: boolean
}

const Followers: React.FC<IFollowersProps> = ({
  className,
  following = [],
  followers = [],
  isShowButtons,
  activeIndex,
  isLoading,
  isLoadingMore,
  isFetching,
  isMyPage,
}) => {
  const {user} = useMst()
  const {t} = useTranslation()
  const [followingIds, setFollowingIds] = useState<Array<any>>([])

  const follow = useCallback((id: string) => {
    userApi.follow({id}).then(() => setFollowingIds(prev => [...prev, id]))
  }, [])

  const unfollow = useCallback((id: string) => {
    userApi
      .unfollow({id})
      .then(() => setFollowingIds(prev => [...prev.filter((i: any) => i !== id)]))
  }, [])

  useEffect(() => {
    if (isMyPage) {
      setFollowingIds([...following.map((i: any) => i.id)])
    } else {
      setFollowingIds([...user.follows.map((i: any) => i.id)])
    }
  }, [isMyPage, following, user.follows])

  const isMe = useCallback(
    id => {
      if (!user.id) {
        return false
      }
      if (typeof user.id === 'number') {
        return user.id === +id
      }
      if (typeof user.id === 'string') {
        return user.id === id
      }
      return false
    },
    [user.id],
  )

  const isHasFollow = useCallback(
    id => {
      return followingIds.includes(id)
    },
    [followingIds],
  )

  const renderFollowing = (items: any) => {
    return (
      !!items.length &&
      items.map((item: any) => (
        <div className={styles.item} key={nextId()}>
          <div className={styles.follower}>
            <a
              href={`/profile/${item.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.avatar}>
              <img src={item.avatar} alt="Avatar" />
            </a>
            <div className={styles.details}>
              <a
                href={`/profile/${item.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.title}>
                {item.name}
              </a>
              <div className={styles.counter}>{`${item.followers_count} ${
                item.followers_count > 1 || item.followers_count === 0
                  ? t('followers')
                  : t('follower')
              }`}</div>
              {isShowButtons && !isMe(item.id) && (
                <button
                  type="button"
                  className={cn(
                    'button-small',
                    {
                      'button-stroke': followingIds.includes(item.id),
                    },
                    styles.button,
                    {
                      [styles.followingButton]: followingIds.includes(item.id),
                    },
                  )}
                  onClick={isHasFollow(item.id) ? () => unfollow(item.id) : () => follow(item.id)}>
                  {isHasFollow(item.id) ? t('unfollow') : t('follow')}
                </button>
              )}
            </div>
          </div>
        </div>
      ))
    )
  }

  const renderFollowers = (items: any) => {
    return (
      !!items.length &&
      items.map((x: any) => (
        <div className={styles.item} key={nextId()}>
          <div className={styles.follower}>
            <a
              href={`/profile/${x.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.avatar}>
              <img src={x.avatar} alt="Avatar" />
            </a>
            <div className={styles.details}>
              <a
                href={`/profile/${x.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.title}>
                {x.name}
              </a>
              <div className={styles.counter}>{`${x.followers_count} ${
                x.followers_count > 1 || x.followers_count === 0 ? t('followers') : t('follower')
              }`}</div>
              {isShowButtons && !isMe(x.id) && (
                <button
                  type="button"
                  className={cn(
                    'button-small',
                    {
                      'button-stroke': followingIds.includes(x.id),
                    },
                    styles.button,
                    {
                      [styles.followingButton]: followingIds.includes(x.id),
                    },
                  )}
                  onClick={isHasFollow(x.id) ? () => unfollow(x.id) : () => follow(x.id)}>
                  {isHasFollow(x.id) ? t('unfollow') : t('follow')}
                </button>
              )}
            </div>
          </div>
        </div>
      ))
    )
  }

  const renderItems = () => {
    return activeIndex === PROFILE_TAB_INDEX.FOLLOWING
      ? renderFollowing(following)
      : renderFollowers(followers)
  }

  return (
    <div className={cn(styles.followers, className)}>
      {(!isLoading || isLoadingMore || isFetching) && (
        <div className={styles.list}>{renderItems()}</div>
      )}
      {(isLoading || isLoadingMore) && <Loader className={styles.loader} />}
      {activeIndex === PROFILE_TAB_INDEX.FOLLOWING
        ? !following.length &&
          !isLoading && <div className={styles.text}>{t('user.noFollowingMessage')}</div>
        : !followers.length &&
          !isLoading && <div className={styles.text}>{t('user.noFollowersMessage')}</div>}
    </div>
  )
}

export default observer(Followers)
