import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import userStyles from '../Users.module.scss'

const UserSkeletons = ({count = 1}) => {
  const userSkeletons = Array.from({length: count}, (_value, index) => index)
  return (
    <div className={userStyles.list}>
      {userSkeletons.map(userSkeleton => (
        <div
          className={classNames(userStyles.item, count === 1 ? userStyles.uniqueItem : '')}
          key={userSkeleton}>
          <div className={userStyles.avatar}>
            <Skeleton className={userStyles.avatarImg} circle />
          </div>
          <div className={userStyles.details}>
            <Skeleton className={userStyles.position} width={40} height={13} />
            <Skeleton className={userStyles.name} width={100} height={15} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default UserSkeletons
