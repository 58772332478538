import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import cn from 'classnames'

import {storeApi} from '../../services/api'
import {useWalletConnectorContext} from '../../services/walletConnect'
import Button from '../Button'
import TextInput from '../TextInput'

import styles from './Burn.module.scss'

interface IBurnProps {
  className?: string
  itemId?: string
  standart?: string
  onClose: () => void
  amount: number
}

const Burn: React.FC<IBurnProps> = ({className, itemId, standart, onClose, amount}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const walletConnector = useWalletConnectorContext()
  const [isLoading, setIsLoading] = useState(false)
  const [tokenAmount, setTokenAmount] = useState('')
  const burnToken = useCallback(() => {
    setIsLoading(true)
    storeApi
      .burnToken(itemId || '', tokenAmount)
      .then(({data}: any) => {
        return walletConnector.walletService
          .sendTransaction(data.initial_tx)
          .then((response: any) => {
            storeApi.trackMinerTransaction(
              itemId || '',
              response.transactionHash || response.hash,
              response.blockNumber,
              'Burn',
            )
            onClose()
            history.push('/')
          })
      })
      .catch((e: any) => console.error('Bid modal sendTranscation', e))
      .finally(() => setIsLoading(false))
  }, [itemId, tokenAmount, walletConnector.walletService, onClose, history])

  return (
    <div className={cn(className, styles.transfer)}>
      <div className={cn('h4', styles.title)}>{t('token.burn')}</div>
      <div className={styles.text}>{t('token.burnQuestion')}</div>
      {standart === 'ERC1155' && (
        <div className={styles.field}>
          <TextInput
            label=""
            className={styles.input}
            value={tokenAmount}
            onChange={(e: any) => setTokenAmount(e.target.value)}
            type="number"
            name="amount"
            placeholder={t('token.burnPasteTokenAmout')}
            positiveOnly
            integer
          />
        </div>
      )}
      <div className={styles.btns}>
        <Button
          disabled={amount < +tokenAmount || (standart === 'ERC1155' && +tokenAmount === 0)}
          className={cn('button-pink', styles.button)}
          onClick={burnToken}
          loading={isLoading}>
          {t('continue')}
        </Button>
        <button
          type="button"
          className={cn('button-stroke', styles.button)}
          onClick={() => onClose()}>
          {t('cancel')}
        </button>
      </div>
    </div>
  )
}

export default Burn
