import React from 'react'
import {withTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import cn from 'classnames'
import {connect} from 'formik'
import SwiperCore, {Navigation} from 'swiper'

import Icon from '../../../../components/Icon'
import Loader from '../../../../components/Loader'
import Modal from '../../../../components/Modal'
import {userApi} from '../../../../services/api'
import {CreateCollection} from '../../../index'

import styles from './ChooseCollection.module.scss'

SwiperCore.use([Navigation])

class ChooseCollection extends React.Component<any, any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      activeCollection: '',
      collections: [],
      visibleModal: false,
      isLoading: false,
    }

    this.changeCollection = this.changeCollection.bind(this)
    this.getCollections = this.getCollections.bind(this)
  }

  componentDidMount() {
    if (!this.state.collections.length) {
      this.getCollections()
    }
  }

  getCollections() {
    this.setState({isLoading: true})
    userApi
      .getSingleCollections()
      .then(({data}) => {
        const collections = data.collections.filter((coll: any) => {
          if (this.props.isSingle) {
            return coll.standart === 'ERC721'
          }
          return coll.standart === 'ERC1155'
        })

        if (collections.length) this.changeCollection(collections[0].id)

        this.setState({
          collections: [
            {
              title: this.props.t('create.collection'),
              color: 'rgb(35, 38, 47)',
            },
            ...collections,
          ],
        })
      })
      .catch(err => console.log(err, 'get single'))
      .finally(() => this.setState({isLoading: false}))
  }

  changeCollection(id: string | number) {
    if (this.props.formik.values.collectionId !== id) {
      this.setState({
        activeCollection: id,
      })

      this.props.formik.setFieldValue('collectionId', id)
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            marginTop: '40px',
            paddingTop: '40px',
          }}
          className={styles.category}>
          {this.props.t('choose_coll')}
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => this.getCollections()}
            style={{cursor: 'pointer'}}>
            <img alt="refresh icon" src="/images/refresh.svg" />
          </span>
        </div>
        <div className={styles.text}>{this.props.t('create_form.text8')}</div>
        <div className={styles.cards}>
          {!this.state.isLoading ? (
            this.state.collections?.map((x: any) => (
              <div
                className={cn(styles.card, {
                  [styles.active]: this.state.activeCollection === x.id,
                })}
                key={nextId()}
                tabIndex={0}
                onKeyDown={() => {}}
                role="button"
                onClick={
                  x.title === 'Create collection' || x.title === 'Crear colección'
                    ? () =>
                        this.setState({
                          visibleModal: true,
                        })
                    : () => this.changeCollection(x.id)
                }>
                {x.title === 'Create collection' || x.title === 'Crear colección' ? (
                  <>
                    <div className={styles.plus}>
                      <Icon name="plus" size="24" />
                    </div>
                    <div className={styles.subtitle}>{x.title}</div>
                  </>
                ) : (
                  <>
                    <div
                      className={styles.plus}
                      style={{
                        backgroundImage: `url(${x.avatar})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                    <div className={styles.subtitle}>{x.name}</div>
                  </>
                )}
              </div>
            ))
          ) : (
            <Loader className={styles.load} />
          )}
          <Modal
            visible={this.state.visibleModal}
            onClose={() => this.setState({visibleModal: false})}
            closeOnRight>
            <CreateCollection isSingle={this.props.isSingle} />
          </Modal>
        </div>
      </>
    )
  }
}

export default withTranslation()(connect(ChooseCollection))
