import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import Skeleton from 'react-loading-skeleton'
import Zoom from 'react-medium-image-zoom'
import {useHistory, useParams} from 'react-router-dom'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'
import {observer} from 'mobx-react'

import {ReactComponent as IconLock} from '../../assets/img/icons/content-lock.svg'
import {ReactComponent as IconUnlock} from '../../assets/img/icons/content-unlock.svg'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import Image from '../../components/Image'
import NavLink from '../../components/NavLink/NavLink'
import {TOKEN_TAB_INDEX} from '../../constants/constants'
import {TOKEN_STATUS} from '../../constants/tokenStatus'
import {storeApi} from '../../services/api'
import {useMst} from '../../store/store'
import {TokenStatus} from '../../types/tokenStatus'
import {IItem, IItemId} from '../../typings/Item'
import {IOwner} from '../../typings/UserInfo'
import {getItemBlockchain} from '../../utils/blockchain'
import myLocalStorage from '../../utils/myLocalStorage'
import {shorterAddress} from '../../utils/shorterAddress'

import PendingStatus from './PendingStatus/PendingStatus'
import ItemSkeleton from './Skeleton/ItemSkeleton'
import Control from './Control'
import NftDetails from './Details'
import Options from './Options'
import Users from './Users'

import styles from './Item.module.scss'
import skeletonStyles from './Skeleton/ItemSkeleton.module.scss'

const Item: React.FC = observer(() => {
  const history = useHistory()
  const {t} = useTranslation()
  const {itemId} = useParams<IItemId>()
  const [activeIndex, setActiveIndex] = useState(0)
  const [item, setItem] = useState<IItem | null>(null)
  const [isOwner, setIsOwner] = useState(false)
  const [navLinks, setNavLinks] = useState<any>()
  const [isUnlockOpened, setIsUnlockOpened] = useState(false)
  const [copyContent, setCopyContent] = useState(false)
  const {user} = useMst()
  const [isMayRemoveFromSale, setMayRemoveFromSale] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [imgSrc, setImgSrc] = useState<string | undefined>()

  const isWrongChain = useMemo(
    () =>
      item &&
      localStorage.kephi_nft_chainName &&
      getItemBlockchain(item.network.name) !== localStorage.kephi_nft_chainName,
    [item],
  )

  const handleUnlock = useCallback(() => {
    setIsUnlockOpened(!isUnlockOpened)
  }, [isUnlockOpened])

  const checkAmount = useCallback(
    (owners: any) => {
      let quantity = 0
      if (isOwner && owners) {
        if (Array.isArray(owners)) {
          quantity = owners.filter((owner: any) => owner.id === user.id)[0]?.quantity
        } else {
          quantity = owners.quantity
        }
      }
      return quantity
    },
    [user.id, isOwner],
  )

  const checkIsOwner = useCallback(() => {
    if (item && item.owners) {
      if (Array.isArray(item.owners))
        setIsOwner(item.owners.some((owner: IOwner) => owner.id === user.id))
      else setIsOwner(user.id === item.owners.id)

      setNavLinks([
        {
          id: TOKEN_TAB_INDEX.INFO,
          label: t('token.nav.info'),
          isVisible: true,
        },
        {
          id: TOKEN_TAB_INDEX.OWNERS,
          label: t('token.nav.owners'),
          isVisible: true,
        },
        {
          id: TOKEN_TAB_INDEX.HISTORY,
          label: t('token.nav.history'),
          isVisible: true,
        },
        {
          id: TOKEN_TAB_INDEX.BIDS,
          label: t('token.nav.bids'),
          isVisible: !!item?.bids.length,
        },
        {
          id: TOKEN_TAB_INDEX.DETAILS,
          label: t('token.nav.details'),
          isVisible: !!item?.details,
        },
      ])
    }
  }, [item, t, user.id])

  const getItem = useCallback(async () => {
    setLoading(true)
    const itemDetails = await storeApi.getNftDetails(+itemId)
    storeApi
      .getToken(itemId)
      .then(({data}) => {
        data.details = itemDetails
        if (data.format !== 'video') {
          const img = document.createElement('img')
          img.src = data.media
          img.onload = () => {
            setImgSrc(img.src)
          }
        } else {
          setImgSrc('_')
        }
        setItem(data)
      })
      .catch(err => {
        history.push('/')
        console.error('error', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [itemId, history])

  const removeFromSale = useCallback(async () => {
    if (itemId && item) {
      const data: {
        price?: null
        minimal_bid?: null
      } = {}
      if (item.standart === 'ERC721' && isOwner) {
        if (item.is_selling) {
          data.price = null
        }
        if (item.is_auc_selling) {
          data.minimal_bid = null
        }
      } else if (item.standart === 'ERC1155' && isOwner) {
        if (item.is_selling && item.sellers.find((seller: any) => seller.id === user.id)) {
          data.price = null
        }
        if (item.is_auc_selling && item.owner_auction.find(seller => seller.id === user.id)) {
          data.minimal_bid = null
        }
      }
      try {
        const {data: result} = await storeApi.removeFromSale(itemId, data.price, data.minimal_bid)
        setItem(result)
      } catch (error) {
        console.log(error, 'remove from sale')
      }
    }
  }, [itemId, item, isOwner, user.id, setItem])

  const handleCopy = (content: string) => {
    copyToClipboard(content)
    setCopyContent(true)
  }

  useEffect(() => {
    getItem()
    const interval = setInterval(getItem, 30000)

    return () => {
      clearInterval(interval)
    }
  }, [getItem])

  useEffect(() => checkIsOwner(), [checkIsOwner])
  useEffect(() => {
    if (item && user.id) {
      setMayRemoveFromSale(
        (item.standart === 'ERC721' && item.selling && isOwner) ||
          (item.standart === 'ERC1155' &&
            (item.sellers.find((seller: any) => seller.id.toString() === user.id.toString()) ||
              item.owner_auction.find(seller => seller.id.toString() === user.id.toString())) &&
            isOwner),
      )
    }
  }, [item, isOwner, user.id])

  const isPending = useCallback((): boolean => {
    const localStorageLabel = `${shorterAddress(user.address, 8)}_${
      TOKEN_STATUS.EXCHANGE_PENDING
    }_${item?.id}`

    if (
      isOwner &&
      item?.status?.toLowerCase() === TOKEN_STATUS.COMMITTED &&
      !!myLocalStorage.get(localStorageLabel)
    ) {
      myLocalStorage.remove(localStorageLabel)
      return false
    }

    if (item?.status?.toLowerCase() === TOKEN_STATUS.EXCHANGE_PENDING) {
      return !!myLocalStorage.get(localStorageLabel)
    }
    return !!(isOwner && item?.status?.toLowerCase().includes('pending'))
  }, [isOwner, item, user.address])

  return (
    <div className={classNames('section')}>
      <div className={classNames('container', styles.itemContainer)}>
        {loading && !item && <ItemSkeleton />}
        {((!loading && !!item) || (loading && !!item)) && (
          <>
            <div className={styles.bg}>
              {imgSrc ? (
                <div className={styles.preview}>
                  <div className={styles.imageWrapper}>
                    <div className={styles.categories}>
                      {item &&
                        !!item.tags.length &&
                        item.tags.map((tag, index) => (
                          <div
                            className={classNames(
                              {'status-black': !(index % 2)},
                              {'status-purple': index % 2},
                              styles.category,
                            )}
                            key={nextId()}>
                            {tag}
                          </div>
                        ))}
                    </div>
                    <div className={classNames(styles.zoomContainer)}>
                      {item?.format !== 'video' && (
                        <Zoom>
                          <Image src={imgSrc || '/images/content/card-pic-6.jpg'} alt="Card" />
                          {item?.format === 'audio' && item.animation && (
                            <audio controls>
                              <source
                                src={item.animation}
                                // type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                              />
                              <track kind="captions" />
                            </audio>
                          )}
                        </Zoom>
                      )}
                      {item?.format === 'video' &&
                        (item.animation ? (
                          <video muted loop controls controlsList="nodownload">
                            <source
                              src={item.animation}
                              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                            />
                            <track kind="captions" />
                          </video>
                        ) : (
                          <Zoom>
                            <Image src={imgSrc || '/images/content/card-pic-6.jpg'} alt="Card" />
                          </Zoom>
                        ))}
                    </div>
                  </div>
                  <Options
                    className={styles.optionsMobile}
                    itemId={itemId}
                    standart={item?.standart}
                    isLiked={item?.is_liked ?? false}
                    isMayRemoveFromSale={isMayRemoveFromSale}
                    removeFromSale={removeFromSale}
                    isOwner={isOwner}
                    isAuction={item?.is_auc_selling || false}
                    isWrongChain={isWrongChain}
                    amount={checkAmount(item?.owners)}
                    name={item?.name}
                    creatorName={item?.creator?.name}
                    updateTokenData={getItem}
                  />
                </div>
              ) : (
                <Skeleton className={skeletonStyles.previewSkeleton} />
              )}
            </div>
            <div className={styles.details}>
              <NftDetails item={item} />
              <div className={styles.navWrapper}>
                <NavLink
                  className={styles.nav}
                  navLinks={navLinks}
                  onClick={(value: any) => setActiveIndex(value)}
                  activeLink={activeIndex}
                />
              </div>
              <Users
                className={styles.users}
                owners={
                  activeIndex === TOKEN_TAB_INDEX.INFO || activeIndex === TOKEN_TAB_INDEX.OWNERS
                    ? item?.owners
                    : undefined
                }
                creator={activeIndex === TOKEN_TAB_INDEX.INFO ? item?.creator : undefined}
                history={activeIndex === TOKEN_TAB_INDEX.HISTORY ? item?.history : undefined}
                bids={activeIndex === TOKEN_TAB_INDEX.BIDS ? item?.bids : undefined}
                details={activeIndex === TOKEN_TAB_INDEX.DETAILS ? item?.details : undefined}
                royalty={activeIndex === TOKEN_TAB_INDEX.INFO ? item?.royalty : undefined}
                collection={activeIndex === TOKEN_TAB_INDEX.INFO ? item?.collection : undefined}
                standart={item?.standart}
              />
              {isOwner && !!item?.digital_key && activeIndex === TOKEN_TAB_INDEX.DETAILS && (
                <div
                  role="button"
                  className={classNames(styles.unlock, {
                    [styles.opened]: isUnlockOpened,
                  })}
                  onClick={isUnlockOpened ? undefined : handleUnlock}
                  onKeyDown={() => {}}
                  tabIndex={0}>
                  <div
                    role="button"
                    className={styles.unlockButton}
                    onClick={isUnlockOpened ? handleUnlock : undefined}
                    onKeyDown={() => {}}
                    tabIndex={0}>
                    {isUnlockOpened ? <IconUnlock /> : <IconLock />}
                    <span>
                      {isUnlockOpened ? t('token.hide') : t('token.get')} {t('token.unlock')}
                    </span>
                  </div>
                  {isUnlockOpened && !!item?.digital_key && (
                    <div className={styles.digitalKey}>
                      {item.digital_key.length > 100
                        ? `${item?.digital_key.slice(0, 100)}...`
                        : item?.digital_key}
                      <Button
                        className={styles.copy}
                        onClick={() => handleCopy(item?.digital_key || '')}
                        onMouseLeave={() => setCopyContent(false)}>
                        <Icon name="copy" size="16" />
                        <div className={styles.tooltip}>
                          <span className={styles.tooltiptext}>
                            {copyContent ? 'Success!' : 'Copy unlockable content'}
                          </span>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {!!item &&
                (item?.is_selling || item?.is_auc_selling || isOwner) &&
                !isPending() &&
                !item?.status?.toLowerCase().includes('pending') && (
                  <Control
                    isWrongChain={isWrongChain}
                    className={styles.control}
                    token={item}
                    updateTokenData={getItem}
                  />
                )}
              {!!item && isPending() && (
                <PendingStatus
                  className={styles.pendingStatus}
                  pendingStatus={item.status.toLocaleLowerCase() as TokenStatus}
                />
              )}
            </div>
            <Options
              className={styles.options}
              itemId={itemId}
              standart={item?.standart}
              isLiked={item?.is_liked ?? false}
              isMayRemoveFromSale={isMayRemoveFromSale}
              removeFromSale={removeFromSale}
              isOwner={isOwner}
              isAuction={item?.is_auc_selling || false}
              isWrongChain={isWrongChain}
              amount={checkAmount(item?.owners)}
              name={item?.name}
              creatorName={item?.creator?.name}
              updateTokenData={getItem}
            />
          </>
        )}
      </div>
    </div>
  )
})

export default Item
