import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useHistory} from 'react-router-dom'
import {notification} from 'antd'
import BigNumber from 'bignumber.js/bignumber'
import classNames from 'classnames'
import {ethers} from 'ethers'
import {observer} from 'mobx-react'

import Bid from '../../../components/Bid'
import Button from '../../../components/Button'
import ErrorPanel from '../../../components/ErrorPanel/ErrorPanel'
import InfoPanel from '../../../components/InfoPanel/InfoPanel'
import LogIn from '../../../components/LogIn/LogIn'
import Modal from '../../../components/Modal'
import SuccessfullyAuctionCompleted from '../../../components/SuccessfullyAuctionCompleted'
import {COIN_KEY, PROVIDER} from '../../../constants/constants'
import {storeApi} from '../../../services/api'
import {useWalletConnectorContext} from '../../../services/walletConnect'
import {Wallet} from '../../../services/walletService'
import {rootStore, useMst} from '../../../store/store'
import {BlockchainType} from '../../../types/blockchain'
import {IItem} from '../../../typings/Item'
import {IOwner} from '../../../typings/UserInfo'
import {getCoinDecimalsByName} from '../../../utils/coins'
import {getContractType} from '../../../utils/contracts'
import {setThousandPoints} from '../../../utils/thousandPoints'

import PaymentMethod from './PaymentMethod/PaymentMethod'
import Checkout from './Checkout'
import PutSale from './PutSale'
import Sellers from './Sellers'
import SuccessfullyPurchased from './SuccessfullyPurchased'

import styles from './Control.module.scss'

interface IControlProps {
  className?: string
  token: IItem
  updateTokenData: () => void
  isWrongChain: boolean
  isLiteVersion?: boolean
}

const MULTI_CHAIN_USD_MIN_PRICE = 30

const Control: React.FC<IControlProps> = observer(
  ({className, token, updateTokenData, isWrongChain, isLiteVersion = false}) => {
    const history = useHistory()
    const {t} = useTranslation()
    const walletConnector = useWalletConnectorContext()
    const [visibleModalPurchase, setVisibleModalPurchase] = useState(false)
    const [visibleModalBid, setVisibleModalBid] = useState(false)
    const [visibleModalSellers, setVisibleModalSellers] = useState(false)
    const [visibleModalSale, setVisibleModalSale] = useState(false)
    const [visiblePaymentMethod, showPaymentMethod] = useState(false)
    const [isOwner, setIsOwner] = useState(false)
    const [endAuc, setEndAuc] = useState(false)
    const [isEndingAuction, setEndingAuction] = useState(false)
    const [auctionCompletedSuccessfully, setAuctionCompletedSuccessfully] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [sellerId, setSellerId] = useState('')
    const [transactionHash, setTransactionHash] = useState<any>('')
    const {user} = useMst()
    const [isMultiChainPurchase, setMultiChainPurchase] = useState(false)
    const [isCreditCardPurchase, setCreditCardPurchase] = useState<boolean>(false)

    const {
      owners,
      bids,
      id,
      standart,
      is_auc_selling,
      price,
      USD_price,
      name,
      creator,
      available,
      currency,
      royalty,
      sellers,
      service_fee,
      minimal_bid,
      minimal_bid_USD,
      highest_bid,
      highest_bid_USD,
      selling,
      media,
      format,
      network,
      collection,
      is_selling,
      owner_auction,
      USD_service_fee,
    } = token

    const isSingle = standart === 'ERC721'
    const isMultiple = standart === 'ERC1155'

    const checkIsOwner = useCallback(() => {
      if (owners) {
        if (Array.isArray(owners)) {
          setIsOwner(
            !!owners.find((owner: IOwner) => {
              return owner.id === user.id
            }),
          )
        } else {
          setIsOwner(user.id === owners.id)
        }
      }
    }, [owners, user.id])

    const handleCheckApproveNft = async () => {
      try {
        const result = await walletConnector.walletService.checkNftTokenAllowance(
          collection.address,
        )
        return result
      } catch (err) {
        console.log(err)
        return false
      }
    }

    const handleApproveNft = async () => {
      try {
        let isAppr: string | boolean = false
        // if (localStorage.kephi_nft_providerName !== PROVIDER.SEQUENCE) {
        isAppr = await handleCheckApproveNft()
        // }
        if (!isAppr || localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE) {
          await walletConnector.walletService.createTransaction(
            'setApprovalForAll',
            [rootStore.contracts.params.EXCHANGE[getContractType()].address, true],
            'NFT',
            false,
            collection.address,
          )
        }
      } catch (err) {
        throw Error
      }
    }

    const handleEndAuc = useCallback(() => {
      storeApi
        .verificateBet(id)
        .then((response: any) => {
          if (response.data.invalid_bet && Object.keys(response.data.invalid_bet).length) {
            notification.error({
              message: 'Highest bid is not correct',
            })
            updateTokenData()
          } else {
            setEndingAuction(true)
            storeApi
              .endAuction(id)
              .then(({data}: any) => {
                // PARCHE - TODO: Delegar en el CreateTransaction
                const formattedValue =
                  localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE
                    ? ethers.utils.parseEther(
                        Wallet.weiToEth(
                          data.initial_tx.value,
                          getCoinDecimalsByName(currency.symbol),
                        ),
                      )
                    : new BigNumber(data.initial_tx.value).toFixed(0)

                walletConnector.walletService
                  .createTransaction(
                    data.initial_tx.method,
                    [
                      data.initial_tx.data.idOrder,
                      data.initial_tx.data.SellerBuyer,
                      data.initial_tx.data.tokenToBuy,
                      data.initial_tx.data.tokenToSell,
                      data.initial_tx.data.fee.feeAddresses,
                      [
                        new BigNumber(data.initial_tx.data.fee.feeAmounts[0]).toFixed(0).toString(),
                        new BigNumber(data.initial_tx.data.fee.feeAmounts[1]).toFixed(0).toString(),
                      ],
                      data.initial_tx.data.signature,
                    ],
                    'BEP20',
                    {
                      gas: data.initial_tx.gas,
                      gasPrice: data.initial_tx.gasPrice,
                      nonce: data.initial_tx.nonce,
                      to: data.initial_tx.to,
                      value: formattedValue,
                    },
                  )
                  .then(() => {
                    setAuctionCompletedSuccessfully(true)
                  })
                  .finally(() => setEndingAuction(false))
              })
              .catch((error: any) => {
                notification.error({
                  message: 'Error',
                  description: 'Something went wrong',
                })
                console.error('error', error)
              })
              .finally(() => setEndAuc(false))
          }
        })
        .catch(() => {
          notification.error({
            message: 'Something went wrong',
          })
        })
        .finally(() => setEndAuc(false))
    }, [id, updateTokenData, currency.symbol, walletConnector.walletService])

    useEffect(() => checkIsOwner(), [checkIsOwner])

    useEffect(() => {
      if (endAuc && isLiteVersion) history.push(`/item/${id}`)
      if (endAuc && !isLiteVersion) handleEndAuc()
    }, [handleEndAuc, endAuc, isLiteVersion, history, id])

    const onPurchase = useCallback(
      (isMultiChain?: boolean, isCreditCard?: boolean) => {
        if (isLiteVersion && token) {
          history.push(`/item/${id}`)
          return
        }

        if (!!isCreditCard !== isCreditCardPurchase) setCreditCardPurchase(!!isCreditCard)
        if (!!isMultiChain !== isMultiChainPurchase) setMultiChainPurchase(!!isMultiChain)

        if (isMultiple) {
          setVisibleModalSellers(true)
        } else {
          setVisibleModalPurchase(true)
        }
      },
      [history, id, isCreditCardPurchase, isLiteVersion, isMultiChainPurchase, isMultiple, token],
    )

    const handleOpenCheckout = useCallback((value: string) => {
      setSellerId(value)
      setVisibleModalPurchase(true)
    }, [])

    const getMaxAvailable = useMemo(() => {
      return sellerId ? sellers.filter((seller: any) => seller.id === sellerId)[0].quantity : 0
    }, [sellerId, sellers])

    const multiChainValidationsAreFulfilled =
      (localStorage?.kephi_nft_chainName as BlockchainType) === 'Binance' &&
      currency.symbol.toUpperCase() === COIN_KEY.BNB.toUpperCase() &&
      USD_price >= MULTI_CHAIN_USD_MIN_PRICE

    return (
      <>
        <div className={classNames(styles.control, className)}>
          {(!!bids.length && !!highest_bid) || !!is_auc_selling ? (
            <div className={styles.head}>
              {!!bids.length && !!highest_bid ? (
                // HIGHEST BID
                <div className={styles.details}>
                  <div className={styles.highestBidDetails}>
                    <div className={styles.avatar}>
                      <img src={highest_bid.bidder_avatar} alt="Avatar" />
                    </div>
                    <div className={styles.info}>
                      {t('token.highest_bid')}{' '}
                      <span>
                        {highest_bid.bidder?.length > 21
                          ? `${highest_bid.bidder.slice(0, 14)}...${highest_bid.bidder.slice(-4)}`
                          : highest_bid.bidder}
                      </span>
                    </div>
                  </div>
                  <div className={styles.cost}>
                    <div className={styles.price}>{t('price')} </div>
                    <div className={styles.priceBold}>
                      {!!highest_bid_USD && `U$S ${setThousandPoints(highest_bid_USD)}`}
                    </div>
                  </div>
                  {/* <div className={styles.cost}>
                    <div className={styles.priceBold}>
                      {new BigNumber(highest_bid.amount).toFixed()}{' '}
                      {highest_bid.currency.symbol.toUpperCase()}
                    </div>
                    {!!highest_bid_USD && (
                      <div className={classNames(styles.price, styles.highestBid)}>
                        U$S {highest_bid_USD}
                      </div>
                    )}
                  </div> */}
                </div>
              ) : (
                // MINIMAL BID
                is_auc_selling && (
                  <div className={styles.details}>
                    <div className={styles.minimalBid}>{t('minimal_bid')} </div>
                    <div className={styles.cost}>
                      <div className={styles.price}>{t('price')} </div>
                      <div className={styles.priceBold}>
                        {!!minimal_bid_USD && `U$S ${setThousandPoints(minimal_bid_USD)}`}
                      </div>
                    </div>
                    {/* <div className={styles.cost}>
                      <div className={styles.priceBold}>
                        {minimal_bid
                          ? `${minimal_bid} ${currency.symbol.toUpperCase()}`
                          : t('token.no_bids')}
                      </div>
                      {!!minimal_bid_USD && (
                        <div className={styles.price}>U$S {minimal_bid_USD}</div>
                      )}
                    </div> */}
                  </div>
                )
              )}
            </div>
          ) : (
            !!price &&
            is_selling && (
              <div className={styles.head}>
                <div className={styles.details}>
                  <div className={styles.cost}>
                    <div className={styles.price}>{t('price')} </div>
                    <div className={styles.priceBold}>
                      {!!USD_price && `U$S ${setThousandPoints(USD_price)}`}
                    </div>
                  </div>
                  {/* <div className={styles.cost}>
                    <div className={styles.priceBold}>
                      {price} {currency.symbol.toUpperCase()}
                    </div>
                    {!!USD_price && <div className={styles.price}>U$S {USD_price}</div>}
                  </div> */}
                </div>
              </div>
            )
          )}

          {/* LOGIN BUTTON */}
          {!user.address && !isLiteVersion && (
            <div className={styles.btns}>
              <LogIn className={classNames('button', styles.button, styles.loginButton)} />
            </div>
          )}

          {/* BUTTONS */}
          {(!isWrongChain || isLiteVersion) &&
          (!!user.address || isLiteVersion) &&
          ((isSingle && !isOwner) ||
            (isMultiple && !isOwner) ||
            (isMultiple &&
              isOwner &&
              (is_selling ? available !== 0 : true) &&
              (is_selling
                ? (sellers.length === 1 && sellers[0].id !== user.id) ||
                  sellers.length > 1 ||
                  owner_auction.length > 1 ||
                  (owner_auction.length === 1 && owner_auction[0].id !== user.id) ||
                  (Array.isArray(owners) && owners.length > 1)
                : Array.isArray(owners) && owners.length > 1))) ? (
            <>
              <div className={styles.btns}>
                {((isSingle && price && is_selling) ||
                  (isMultiple &&
                    ((sellers.length === 1 && sellers[0].id !== user.id) ||
                      sellers.length > 1))) && (
                  <>
                    <Button
                      className={classNames('button', styles.button)}
                      onClick={() => {
                        if (isLiteVersion && token) {
                          history.push(`/item/${id}`)
                        } else showPaymentMethod(true)
                      }}>
                      {t('token.purchase')}
                    </Button>

                    {!!isLiteVersion && (
                      <Link className={styles.outlinedButton} to={`/item/${id}`}>
                        {t('home.hero.view_item')}
                      </Link>
                    )}

                    {!isLiteVersion && multiChainValidationsAreFulfilled && (
                      <Button
                        className={classNames(styles.outlinedButton, styles.betaVersion)}
                        onClick={() => onPurchase(true)}>
                        {t('token.purchaseMultiChain')}
                      </Button>
                    )}
                  </>
                )}
                {(isSingle && is_auc_selling) ||
                (isMultiple &&
                  (owner_auction.length > 1 ||
                    (owner_auction.length === 1 && owner_auction[0].id !== user.id))) ? (
                  <Button
                    className={classNames('button-stroke', styles.button, styles.placeBidButton)}
                    onClick={() => {
                      if (isLiteVersion) {
                        history.push(`/item/${id}`)
                        return
                      }
                      setVisibleModalBid(true)
                    }}>
                    {t('token.place_bid')}
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            ''
          )}

          {/* END AUCTION BUTTON */}
          {!isWrongChain &&
            ((isSingle && is_auc_selling && isOwner && !!bids.length) ||
              (isMultiple &&
                is_auc_selling &&
                isOwner &&
                !!bids.length &&
                owner_auction.find(seller => seller.id === user.id) &&
                highest_bid?.bidder_id !== user.id)) && (
              <div className={styles.btns}>
                <Button
                  className={classNames('button-stroke', styles.button, styles.placeBidButton)}
                  onClick={() => setEndAuc(true)}
                  loading={isEndingAuction}>
                  {t('token.end_auc')}
                </Button>
              </div>
            )}

          {/* SERVICE FEE */}
          {selling && service_fee && (
            <div className={styles.text}>
              {t('service_fee')} <span className={styles.percent}>{service_fee}%</span>
              <span>
                {new BigNumber(price || highest_bid?.amount || minimal_bid || '')
                  .multipliedBy(service_fee)
                  .dividedBy(100)
                  .toString()}{' '}
                {currency.symbol.toUpperCase()}
              </span>{' '}
              <span>{USD_service_fee > 0.01 ? `U$S ${USD_service_fee}` : 'U$S 0.01'}</span>
            </div>
          )}

          {/* PUT ON SALE */}
          {!isWrongChain &&
            ((standart === 'ERC721' && !is_selling && !is_auc_selling && isOwner) ||
              (isMultiple &&
                !sellers.find((seller: any) => seller.id === user.id) &&
                !owner_auction.find(seller => seller.id === user.id) &&
                isOwner)) && (
              <div className={styles.foot}>
                <Button
                  className={classNames('button', styles.button)}
                  onClick={() => setVisibleModalSale(true)}>
                  {t('token.put_on_sale')}
                </Button>
              </div>
            )}

          {/* FOOTER */}
          <div className={styles.footer}>
            <div className={styles.note}>{t('token.sell')}</div>
            <InfoPanel message={t('token.sales').replace('{0}', royalty.toString())} />
            {!!isWrongChain && !!user.address && (
              <ErrorPanel
                className={styles.error}
                message={`${t('token.wrongChainError')}
            ${network.name}`}
              />
            )}
          </div>
        </div>

        {isEndingAuction && <div className={styles.parentDisable} />}

        {/* MODALS */}
        {!isLiteVersion && (
          <>
            <Modal
              visible={visibleModalPurchase}
              onClose={() => {
                setVisibleModalPurchase(false)
              }}
              closeOnRight>
              <Checkout
                isVerified={Boolean(creator.is_verificated)}
                tokenId={id}
                standart={standart}
                sellerId={Array.isArray(owners) ? sellerId : 0}
                fee={service_fee}
                // price={price}
                price={
                  Array.isArray(owners)
                    ? owners?.filter(ownerElement => +ownerElement?.id === +sellerId)[0]?.price ||
                      price
                    : price
                }
                title={name}
                media={media}
                format={format}
                creatorName={creator.name}
                currency={currency && currency.symbol.toUpperCase()}
                setIsSuccess={setIsSuccess}
                close={() => {
                  setVisibleModalPurchase(false)
                }}
                setTransactionHash={setTransactionHash}
                maximum={getMaxAvailable}
                isMultiChainPurchase={isMultiChainPurchase}
                isCreditCardPurchase={isCreditCardPurchase}
                network={network}
              />
            </Modal>
            <Modal
              visible={isSuccess}
              onClose={() => {
                showPaymentMethod(false)
                setVisibleModalSellers(false)
                setVisibleModalPurchase(false)
                updateTokenData()
                setIsSuccess(false)
              }}>
              <SuccessfullyPurchased
                title={name}
                creatorName={creator.name}
                transactionHash={transactionHash}
              />
            </Modal>
            <Modal
              visible={auctionCompletedSuccessfully}
              onClose={() => setAuctionCompletedSuccessfully(false)}>
              <SuccessfullyAuctionCompleted tokenName={name} creatorName={creator.name} />
            </Modal>
            <Modal visible={visibleModalBid} onClose={() => setVisibleModalBid(false)} closeOnRight>
              <Bid
                id={id}
                fee={service_fee}
                title={name}
                available={available}
                creatorName={creator.name}
                price={highest_bid?.amount || minimal_bid || '0'}
                currency={currency && currency.symbol}
                updateTokenData={updateTokenData}
                onClose={() => setVisibleModalBid(false)}
              />
            </Modal>
            <Modal
              visible={visibleModalSellers}
              onClose={() => setVisibleModalSellers(false)}
              closeOnRight>
              <Sellers owners={sellers} openCheckout={handleOpenCheckout} user={user.id} />
            </Modal>
            <Modal
              visible={visibleModalSale}
              onClose={() => setVisibleModalSale(false)}
              closeOnRight>
              <PutSale
                tokenId={id.toString()}
                fee={service_fee}
                currency={currency && currency.symbol.toUpperCase()}
                updateTokenData={updateTokenData}
                onClose={() => setVisibleModalSale(false)}
                approve={handleApproveNft}
                royalty={royalty}
                isCreator={creator.id === user.id}
              />
            </Modal>

            <Modal
              containerClassName={styles.paymentMethodModal}
              visible={visiblePaymentMethod && !isSuccess}
              onClose={() => showPaymentMethod(false)}
              closeOnRight>
              <PaymentMethod onPurchase={onPurchase} />
            </Modal>
          </>
        )}
      </>
    )
  },
)

export default Control
