import nextId from 'react-id-generator'
import cn from 'classnames'
import {t} from 'i18next'

import Icon from '../Icon'
import InfoPanel from '../InfoPanel/InfoPanel'

import styles from './SuccessfullyAuctionCompleted.module.scss'

const socials: Array<{
  title: 'facebook' | 'twitter' | 'instagram' | 'pinterest'
  url: string
}> = [
  {
    title: 'facebook',
    url: 'https://www.facebook.com/KephiGallery/',
  },
  {
    title: 'twitter',
    url: 'https://twitter.com/KephiGallery',
  },
]
interface ISuccessfullyAuctionCompletedProps {
  className?: string
  tokenName?: string
  creatorName?: string
}

const SuccessfullyAuctionCompleted: React.FC<ISuccessfullyAuctionCompletedProps> = ({
  className,
  tokenName,
  creatorName,
}) => {
  return (
    <div className={cn(className, styles.wrap)}>
      <div className={cn('h2', styles.title)}>
        {t('yay')}!{' '}
        <span role="img" aria-label="firework">
          {/* 🎉 */}
          💪
        </span>
      </div>
      <div className={styles.info}>
        {t('token.auctionCompletedSuccessfully')} <span>{tokenName}</span> {t('from')}{' '}
        {!!creatorName && creatorName.length > 21
          ? `${creatorName.slice(0, 14)}...${creatorName.slice(-4)}`
          : creatorName}
      </div>
      <InfoPanel className={styles.note} message={t('token.actionCompletedNote')} />
      <div className={styles.socials}>
        {socials.map(x => (
          <a
            className={styles.social}
            href={x.url}
            target="_blank"
            rel="noopener noreferrer"
            key={nextId()}>
            <Icon name={x.title} size="24" />
          </a>
        ))}
      </div>
    </div>
  )
}

export default SuccessfullyAuctionCompleted
