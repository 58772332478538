import classNames from 'classnames'

import {ReactComponent as CrossCircle} from '../../assets/img/icons/cross-circle.svg'

import styles from './ErrorPanel.module.scss'

interface IErrorPanelProps {
  className?: string
  message: string
}

const ErrorPanel: React.FC<IErrorPanelProps> = ({className, message}) => (
  <div className={classNames(styles.note, className)}>
    <div className={styles.crossIcon}>
      <CrossCircle className={styles.copyIcon} />
    </div>
    {message}
  </div>
)

export default ErrorPanel
