import {COIN_TYPE, COIN_TYPES} from '../constants/constants'
import {Wallet} from '../services/walletService'

import {getCoinDecimalsByName, getCoinDecimalsByType} from './coins'

export const getBalanceByCoinType = (data: any, coinType: COIN_TYPE): string => {
  return data[COIN_TYPES.indexOf(coinType)]?.status === 'fulfilled'
    ? Wallet.weiToEth(data[COIN_TYPES.indexOf(coinType)].value, getCoinDecimalsByType(coinType))
    : '0'
}

export const getBalanceByCoinName = (amountData: string | number, coinName: string): string => {
  return Wallet.weiToEth(amountData, getCoinDecimalsByName(coinName))
}
