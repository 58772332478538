import React, {Suspense, useEffect, useState} from 'react'
import {observer} from 'mobx-react'

import InitialLoader from '../../components/InitialLoader/InitialLoader'
import {useMst} from '../../store/store'

import EmpowerCreativityBanner from './EmpowerCreativityBanner/EmpowerCreativityBanner'

import styles from './Home.module.scss'

const PresentationBanner = React.lazy(() => import('./PresentationBanner/PresentationBanner'))
const Hero = React.lazy(() => import('./Hero'))
const Collections = React.lazy(() => import('./Collections'))
// const Discover = React.lazy(() => import('./Discover'))
const FeaturedArtists = React.lazy(() => import('./FeaturedArtists/FeaturedArtists'))
const OurGalleries = React.lazy(() => import('./OurGalleries/OurGalleries'))

const Home: React.FC = observer(() => {
  const {user} = useMst()
  const [isShow, setIsShow] = useState(user.is_searching)

  useEffect(() => {
    setIsShow(user.is_searching)
  }, [user.is_searching])

  return (
    <Suspense
      fallback={
        <div className={styles.loaderContainer}>
          <InitialLoader className={styles.loader} />
        </div>
      }>
      <PresentationBanner />
      <OurGalleries />
      <FeaturedArtists />
      {!isShow && <Hero />}
      <Collections />
      {/* <Discover /> */}
      <EmpowerCreativityBanner />
    </Suspense>
  )
})

export default Home
