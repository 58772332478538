import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import styles from '../Card.module.scss'

const CardSkeleton = ({className = {}}) => (
  <div className={classNames(styles.card, className)}>
    <Skeleton className={styles.preview} />
    <div className={styles.link}>
      <div className={styles.body}>
        <div className={styles.line}>
          <div className={styles.title}>
            <Skeleton width={80} />
          </div>
          <Skeleton width={40} />
        </div>
        <div className={styles.line}>
          <div className={styles.users}>
            <Skeleton circle width={20} height={20} />
          </div>
          <div className={styles.counter} />
        </div>
      </div>
      <div className={styles.foot}>
        <Skeleton circle width={20} height={20} />
        <div className={styles.likesCounter}>
          <Skeleton width={50} />
        </div>
      </div>
    </div>
  </div>
)

export default CardSkeleton
