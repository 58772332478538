import {IBlockchain} from '../types/blockchain'
import {CoinsPerChain} from '../types/coinsPerChain'
import {IProvider} from '../types/provider'

export const PROFILE_ITEMS_PER_PAGE = 9
export const DISCOVER_ITEMS_PER_PAGE = 8
export const COLLECTION_ITEMS_PER_PAGE = 8
export const GALLERY_ITEMS_PER_PAGE = 8

export const ADVANCED_SEARCH_ITEMS_PER_PAGE = 9

export const ACTIVITY_TAB_INDEX = {
  MY_ACTIVITY: 0,
  FOLLOWING: 1,
  ALL: 2,
}

export const PROFILE_TAB_INDEX = {
  ON_SALE: 0,
  ITEMS: 1,
  CREATED: 2,
  LIKED: 3,
  FOLLOWING: 4,
  FOLLOWERS: 5,
  COLLECTIONS: 6,
}

export const COLLECTION_TAB_INDEX = {
  ALL: 0,
  ON_SALE: 1,
}

export const TOKEN_TAB_INDEX = {
  INFO: 0,
  OWNERS: 1,
  HISTORY: 2,
  BIDS: 3,
  DETAILS: 4,
}

export const REFERRAL_QUERY_PARAM = 'referral'

export const LOCAL_STORAGE = {
  REFERRAL_CODE_SHOWN: 'REFERRAL_CODE_SHOWN',
  REFERRAL_CODE_CONNECT_SHOWN: 'REFERRAL_CODE_CONNECT_SHOWN',
  REFERRAL_CODE: 'REFERRAL_CODE',
}

export const BLOCKCHAIN: IBlockchain = {
  BINANCE: 'Binance',
  KARDIACHAIN: 'KardiaChain',
  POLYGON: 'Polygon',
  ETHEREUM: 'Ethereum',
}

export const PROVIDER: IProvider = {
  METAMASK: 'MetaMask',
  WALLETCONNECT: 'WalletConnect',
  WALLETLINK: 'WalletLink',
  KARDIACHAIN: 'KardiaChain',
  SEQUENCE: 'Sequence',
}

export const COIN_KEY = {
  KPHI: 'kphi',
  BNB: 'bnb',
  WBNB: 'wbnb',
  BUSD: 'busd',
  KAI: 'kai',
  WKAI: 'wkai',
  KUSD: 'k-usdt',
  MATIC: 'matic',
  WMATIC: 'wmatic',
  USDT: 'usdt',
  USDC: 'usdc',
  ETH: 'eth',
  WETH: 'weth',
}

export const COINS_PER_CHAIN: CoinsPerChain[] = [
  {
    chainName: 'Binance',
    coins: [
      {type: 'main', name: COIN_KEY.BNB},
      {type: 'wrap', name: COIN_KEY.WBNB},
      {type: 'usd', name: COIN_KEY.BUSD, decimals: 18},
      {type: 'kphi', name: COIN_KEY.KPHI},
    ],
  },
  {
    chainName: 'KardiaChain',
    coins: [
      {type: 'main', name: COIN_KEY.KAI},
      {type: 'wrap', name: COIN_KEY.WKAI},
      {type: 'usd', name: COIN_KEY.KUSD, decimals: 6},
      {type: 'kphi', name: COIN_KEY.KPHI},
    ],
  },
  {
    chainName: 'Polygon',
    coins: [
      {type: 'main', name: COIN_KEY.MATIC},
      {type: 'wrap', name: COIN_KEY.WMATIC},
      {
        type: 'usd',
        name: COIN_KEY.USDC,
        decimals: (localStorage.kephi_nft_providerName !== PROVIDER.SEQUENCE && 6) || 6,
      },
      {
        type: 'generic',
        name: COIN_KEY.USDT,
        decimals: (localStorage.kephi_nft_providerName !== PROVIDER.SEQUENCE && 6) || 18,
      },
    ],
  },
  {
    chainName: 'Ethereum',
    coins: [
      {type: 'main', name: COIN_KEY.ETH},
      {type: 'wrap', name: COIN_KEY.WETH},
      {type: 'usd', name: COIN_KEY.USDC, decimals: 6},
    ],
  },
]

export type COIN_TYPE = 'main' | 'wrap' | 'usd' | 'kphi' | 'generic'
export const COIN_TYPES: string[] = ['main', 'wrap', 'usd', 'kphi', 'generic']

export const DEFAULT_COIN_DECIMALS = 18
