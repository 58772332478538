import {useState} from 'react'
import nextId from 'react-id-generator'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

import Icon from '../Icon'

import styles from './DropdownWithImage.module.scss'

interface IDropdownWithImageProps {
  className?: string
  value: any
  setValue: (str: any) => void
  options: Array<any>
  customClasses?: {
    user?: string
    head?: string
    avatar?: string
    selection?: string
    arrow?: string
    body?: string
    option?: string
  }
  name?: string
}
const DropdownWithImage: React.FC<IDropdownWithImageProps> = ({
  className,
  value,
  setValue,
  options,
  name,
  customClasses = {},
}) => {
  const [visible, setVisible] = useState(false)
  const handleClick = (str: any) => {
    setValue(str)
    setVisible(false)
  }
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.user, {[styles.active]: visible}, customClasses.user)}
        id={name}>
        <div
          onKeyDown={() => {}}
          tabIndex={0}
          role="button"
          className={cn(styles.head, customClasses.head)}
          onClick={() => setVisible(!visible)}>
          {!!value.image && (
            <div className={cn(styles.avatar, customClasses.avatar)}>
              <img src={value.image} alt="Avatar" />
            </div>
          )}
          <div className={cn(styles.selection, customClasses.selection)}>
            {value.symbol ? value.symbol : value}
          </div>
          <div className={cn(styles.arrow, customClasses.arrow)}>
            <Icon name="arrow-bottom" size="13" />
          </div>
        </div>
        <div className={cn(styles.body, customClasses.body)}>
          {options.map((option: any) => (
            <div
              onKeyDown={() => {}}
              tabIndex={0}
              role="button"
              className={cn(
                styles.option,
                {
                  [styles.selectioned]: option.symbol
                    ? option.symbol.toUpperCase() === value?.symbol?.toUpperCase()
                    : option?.toUpperCase() === value?.toUpperCase(),
                },
                customClasses.option,
              )}
              onClick={() => handleClick(option)}
              key={nextId()}>
              {!!option.image && <img alt="" className={styles.image} src={option.image} />}
              <span className={styles.text}>{option.symbol ? option.symbol : option}</span>
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default DropdownWithImage
