import {useCallback, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import Icon from '../../../../../components/Icon'
import Modal from '../../../../../components/Modal'
import {userApi} from '../../../../../services/api'
import {ICreateForm} from '../../../types/Stepper'

import CreateCollection from './components/CreateCollection/CreateCollection'

import styles from './ChooseCollection.module.scss'

const COLLECTION_ID = 'collectionId'
export type ChooseCollectionForm = {
  collectionId: string
}

const collectionSkeletons = () => (
  <div className={styles.cards}>
    {[1, 2, 3, 4, 5].map((skeletonItem: any) => (
      <div key={skeletonItem} className={styles.card}>
        <Skeleton className={styles.avatar} />
        <Skeleton className={styles.subtitle} width={55} height={10} />
      </div>
    ))}
  </div>
)

const ChooseCollection: React.FC<ICreateForm> = ({
  lastStep,
  nextStep,
  onSubmit,
  currentData,
  isSingle,
}) => {
  const {t} = useTranslation()
  const [visibleModal, setVisibleModal] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [collections, setCollections] = useState<any>([])

  const {
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm<ChooseCollectionForm>({
    defaultValues: {
      collectionId: currentData.collectionId,
    },
  })

  const getCollections = useCallback(() => {
    setLoading(true)
    userApi
      .getSingleCollections()
      .then(({data}) => {
        const collectionsFiltered = data.collections.filter(
          (collectionData: any) =>
            (isSingle && collectionData.standart === 'ERC721') ||
            (!isSingle && collectionData.standart === 'ERC1155'),
        )
        setCollections(collectionsFiltered)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }, [isSingle])

  const handleApply = (data: ChooseCollectionForm) => {
    onSubmit(data)
    nextStep()
  }

  useEffect(() => {
    getCollections()
  }, [getCollections])

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit(handleApply)}>
        <div className={styles.title}>{t('create_form.chooseCollection.title')}</div>
        <div className={styles.option}>
          <div className={styles.box}>
            <div className={styles.flexBox}>
              <div className={styles.category}>{t('create_form.chooseCollection.createNew')}</div>
            </div>
            <button
              className={classNames(styles.card, styles.cardButton)}
              type="button"
              onClick={() => setVisibleModal(true)}>
              <div className={styles.plus}>
                <Icon name="plus" size="20" />
              </div>
            </button>
          </div>
        </div>
        <div className={styles.option}>
          <div className={styles.box}>
            <div className={styles.flexBox}>
              <div className={styles.category}>
                {t('create_form.chooseCollection.existingCollections')}
              </div>
            </div>
            <div className={styles.text}>
              {`${t('create_form.chooseCollection.createMessage')}, `}
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                  getCollections()
                  setValue(COLLECTION_ID, '')
                }}
                className={styles.clickHere}>
                {`${t('clickHere')}.`}
              </span>
            </div>
          </div>
        </div>
        <Controller
          control={control}
          name={COLLECTION_ID}
          rules={{
            required: {
              value: true,
              message: t('validate.collection'),
            },
          }}
          render={({field: {name, value}}) => (
            <>
              <div className={styles.cards}>
                {!isLoading
                  ? !!collections?.length &&
                    collections?.map((collection: any) => (
                      <button
                        key={collection.id}
                        className={classNames(styles.card, {
                          [styles.active]: value === collection.id,
                          [styles.error]: !!errors[name] && !value,
                        })}
                        type="button"
                        onClick={() => {
                          setValue(name, collection.id)
                        }}>
                        <img className={styles.avatar} src={collection.avatar} alt="Collection" />
                        <div className={styles.subtitle}>{collection.name}</div>
                      </button>
                    ))
                  : collectionSkeletons()}
              </div>
              {!!errors[name] && !value && (
                <div className={styles.errorLabel}>{errors[name]?.message}</div>
              )}
            </>
          )}
        />
        <div className={styles.buttonsRow}>
          {lastStep && (
            <button
              className={classNames('button-outlined-gradient', styles.backButton)}
              type="button"
              onClick={() => lastStep()}>
              {t('back')}
            </button>
          )}
          <button className={classNames('button-gradient', styles.nextbutton)} type="submit">
            {t('next')}
          </button>
        </div>
      </form>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)} closeOnRight>
        <CreateCollection
          isSingle={isSingle}
          onCreateSuccess={() => {
            setVisibleModal(false)
            setValue(COLLECTION_ID, '')
            getCollections()
          }}
        />
      </Modal>
    </>
  )
}

export default ChooseCollection
