import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import nextId from 'react-id-generator'
import {useHistory} from 'react-router-dom'
import cn from 'classnames'

import {activityApi} from '../../../services/api'
import ActivityItem from '../ActivityItem'
import ActivityItemSkeleton from '../ActivityItemSkeleton/ActivityItemSkeleton'

import styles from './Items.module.scss'

// TODO: fix any
interface IItemsProps {
  className?: string
  items: any
  activeIndex: number | string
  isLoaded: boolean
}

const ACTIVITY_ITEMS_MIN_LENGTH = 4

const Items: React.FC<IItemsProps> = ({className, items, activeIndex, isLoaded}) => {
  const history = useHistory()
  const {t} = useTranslation()

  const readNotification = useCallback(
    (activity_id: number, method: string, link_id: number | string) => {
      activityApi.readNotification({activity_id, method}).then((data: any) => {
        if (data.status === 200) {
          if (method === 'follow') {
            history.push(`/profile/${link_id}`)
          } else {
            history.push(`/item/${link_id}`)
          }
        }
      })
    },
    [history],
  )

  const openNotification = (method: string, link_id: number | string) => {
    if (method === 'follow') {
      history.push(`/profile/${link_id}`)
    } else {
      history.push(`/item/${link_id}`)
    }
  }

  const renderItems = () => {
    return items.length ? (
      <div className={styles.list}>
        {items.map((x: any) => (
          <ActivityItem
            key={nextId()}
            item={x}
            onClick={
              activeIndex === 0
                ? () => readNotification(x.id, x.method, x.token_id || x.from_id)
                : () => openNotification(x.method, x.token_id || x.from_id)
            }
            activeIndex={activeIndex}
          />
        ))}
      </div>
    ) : (
      <div className={styles.noItemsFound}>
        <span className={cn('h4', styles.noItemsText)}>{t('activity.not_found')}</span>
      </div>
    )
  }

  const activityItemsSkeletons = Array.from(
    {length: ACTIVITY_ITEMS_MIN_LENGTH},
    (_value, index) => index,
  )

  const skeletonItems = () => (
    <div className={styles.list}>
      {activityItemsSkeletons.map(activityItemSkeleton => (
        <ActivityItemSkeleton key={activityItemSkeleton} />
      ))}
    </div>
  )

  return (
    <div className={cn(styles.items, className)}>{isLoaded ? renderItems() : skeletonItems()}</div>
  )
}

export default Items
