import {capitalizeFirstLetter} from '../../../utils/capitalizeFirstLetter'
import {shorterAddress} from '../../../utils/shorterAddress'
import Image from '../../Image'

import styles from './SearchRow.module.scss'

interface ISeachRowProps {
  image: string
  name: string
  address?: string
  url: string
}

const SearchRow: React.FC<ISeachRowProps> = ({image, name, address, url}) => {
  return (
    <a className={styles.rowContainer} href={url}>
      <div className={styles.image}>
        <Image className={styles.image} src={image} alt="Item Image" />
      </div>
      <div className={styles.description}>
        <div className={styles.name}>{capitalizeFirstLetter(name)}</div>
        {!!address && <div className={styles.address}>{shorterAddress(address)}</div>}
      </div>
    </a>
  )
}

export default SearchRow
