import {SignInOption} from '@0xsequence/provider'
import {IConnect, IError} from '@amfi/connect-wallet/dist/interface'
import {sequence} from '0xsequence'
import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import {TransactionReceipt} from 'web3-core'

import {DEFAULT_COIN_DECIMALS, PROVIDER} from '../../constants/constants'
import {BlockchainType} from '../../types/blockchain'
import {ContractParam} from '../../types/contract'
import {ProviderType} from '../../types/provider'
import {IWallet} from '../walletInterface'
import {Web2Wallet} from '../wallets/web2Wallet'
import {Web3Wallet} from '../wallets/web3Wallet'

export class Wallet {
  public walletAddress = ''

  private wallet: IWallet

  constructor(wallet: IWallet) {
    this.wallet = wallet
  }

  public initWalletConnect(
    chainName: BlockchainType,
    providerName: ProviderType,
  ): Promise<boolean> {
    return this.wallet.initWalletConnect(chainName, providerName)
  }

  public connectWallet(
    chainName: BlockchainType,
    providerName: ProviderType,
    signInOption?: SignInOption,
  ): Promise<boolean> {
    return this.wallet.connectWallet(chainName, providerName, signInOption)
  }

  public disconnect(): void {
    this.wallet.disconnect()
  }

  public logOut(): void {
    this.wallet.logOut()
  }

  public Web3(): Web3 {
    return this.wallet.Web3()
  }

  public signMessage(msg: string): Promise<string> {
    return this.wallet.signMessage(msg)
  }

  public getTokenBalance(contractAbi: ContractParam): Promise<string | number> {
    return this.wallet.getTokenBalance(contractAbi)
  }

  public setAccountAddress(address: string): void {
    this.wallet.setAccountAddress(address)
  }

  public checkNftTokenAllowance(collectionAddress: string): Promise<string> {
    return this.wallet.checkNftTokenAllowance(collectionAddress)
  }

  public getAccount(): Promise<IConnect | IError | {address: string}> {
    return this.wallet.getAccount()
  }

  public encodeFunctionCall(abi: any, data: Array<any>): string {
    return this.wallet.encodeFunctionCall(abi, data)
  }

  public createTransaction(
    method: string,
    data: Array<any>,
    contract: ContractParam,
    tx?: any,
    tokenAddress?: string,
    walletAddress?: string,
    value?: any,
  ): Promise<sequence.transactions.TransactionResponse<any> | TransactionReceipt> {
    return this.wallet.createTransaction(
      method,
      data,
      contract,
      tx,
      tokenAddress,
      walletAddress,
      value,
    )
  }

  public sendTransaction(
    transactionConfig: any,
  ): Promise<sequence.transactions.TransactionResponse<any> | TransactionReceipt> {
    return this.wallet.sendTransaction(transactionConfig)
  }

  public totalSupply(
    tokenAddress: string,
    abi: Array<any>,
    tokenDecimals: number,
  ): Promise<number> {
    return this.wallet.totalSupply(tokenAddress, abi, tokenDecimals)
  }

  public checkTokenAllowance(
    contractName: ContractParam,
    amount: number | string,
    tokenDecimals: number,
    exchangeAddress?: string,
    walletAddress?: string,
  ): Promise<boolean> {
    return this.wallet.checkTokenAllowance(
      contractName,
      amount,
      tokenDecimals,
      exchangeAddress,
      walletAddress,
    )
  }

  public approveToken(
    contractName: ContractParam,
    amount: number | string,
    tokenDecimals: number,
    exchangeAddress?: string,
    walletAddress?: string,
  ): Promise<unknown> {
    return this.wallet.approveToken(
      contractName,
      amount,
      tokenDecimals,
      exchangeAddress,
      walletAddress,
    )
  }

  public static calcTransactionAmount(amount: number | string, tokenDecimal: number): string {
    return new BigNumber(amount).times(new BigNumber(10).pow(tokenDecimal)).toString(10)
  }

  public static weiToEth(amount: number | string, decimals = DEFAULT_COIN_DECIMALS): string {
    return new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals)).toString(10)
  }
}

export const getWallet = (provider?: ProviderType): IWallet => {
  return (localStorage.kephi_nft_providerName &&
    localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE) ||
    (provider && provider === PROVIDER.SEQUENCE)
    ? new Web2Wallet()
    : new Web3Wallet()
}
