import {ConnectWallet} from '@amfi/connect-wallet'
import {IConnect, IError} from '@amfi/connect-wallet/dist/interface'
import {sequence} from '0xsequence'
import {notification} from 'antd'
import BigNumber from 'bignumber.js/bignumber'
import Web3 from 'web3'
import {TransactionReceipt} from 'web3-core'

import {connectWallet} from '../../config'
import {LOCAL_STORAGE} from '../../constants/constants'
import {rootStore} from '../../store/store'
import {BlockchainType} from '../../types/blockchain'
import {ContractParam} from '../../types/contract'
import {ProviderType} from '../../types/provider'
import {isAnyOfMainCoin} from '../../utils/coins'
import {getContractType} from '../../utils/contracts'
import myLocalStorage from '../../utils/myLocalStorage'
import {userApi} from '../api'
import {IWallet} from '../walletInterface'
import {Wallet} from '../walletService'

export class Web3Wallet implements IWallet {
  public wallet: ConnectWallet

  public walletAddress = ''

  constructor() {
    this.wallet = new ConnectWallet()
  }

  public async initWalletConnect(
    chainName: BlockchainType,
    providerName: ProviderType,
  ): Promise<boolean> {
    return new Promise(resolve => {
      const {provider, network, settings} = connectWallet(chainName)

      const connecting = this.wallet
        .connect(provider[providerName], network, settings)
        .then((connected: boolean | {}) => {
          if (connected) {
            // this.initializeContracts();
          }
          return connected
        })
        .catch((err: any) => {
          console.log('initWalletConnect providerWallet err: ', err)
        })

      Promise.all([connecting]).then((connect: any) => {
        resolve(connect[0])
      })
    })
  }

  public async connectWallet(
    chainName: BlockchainType,
    providerName: ProviderType,
  ): Promise<boolean> {
    const isConnected = await this.initWalletConnect(chainName, providerName)
    if (isConnected) {
      try {
        const userAccount: any = await this.getAccount()

        if (rootStore.user.address && userAccount.address !== rootStore.user.address) {
          this.disconnect()
          return false
        }

        this.setAccountAddress(userAccount.address)
        if (!localStorage.kephi_nft_token) {
          const metMsg: any = await userApi.getMsg()
          const signedMsg = await this.signMessage(metMsg.data)

          const login: any = await userApi.login({
            address: userAccount.address,
            msg: metMsg.data,
            signedMsg,
            referredBy: myLocalStorage.get(LOCAL_STORAGE.REFERRAL_CODE),
          })

          localStorage.kephi_nft_token = login.data.key
          rootStore.user.setAddress(userAccount.address)
          return window.location.pathname === '/connect-wallet'
        }
        return false
      } catch (error: any) {
        console.log('getAccount wallet connect - get user account err: ', error)
        if (error.code && error.code === 6) {
          console.log('')
        } else {
          this.disconnect()
        }
        notification.error({
          message: error.message.title,
          description: `Wrong Network, please select ${chainName} ${getContractType()} network in your wallet and try again`,
        })
        return false
      }
    }
    return false
  }

  // eslint-disable-next-line class-methods-use-this
  public disconnect(): void {
    rootStore.user.disconnect()
  }

  public logOut(): void {
    this.wallet.resetConect()
  }

  public Web3(): Web3 {
    return this.wallet.currentWeb3()
  }

  public signMessage(msg: string): Promise<string> {
    return this.wallet.signMsg(this.walletAddress, msg)
  }

  public async getTokenBalance(contractAbi: ContractParam): Promise<string | number> {
    if (isAnyOfMainCoin(contractAbi)) {
      return this.wallet.getBalance(rootStore.user.address)
    }
    const contract = this.wallet.getContract({
      address: rootStore.contracts.params[contractAbi][getContractType()].address,
      abi: rootStore.contracts.params[contractAbi][getContractType()].abi,
    })
    return contract.methods.balanceOf(this.walletAddress).call()
  }

  public setAccountAddress(address: string): void {
    this.walletAddress = address
  }

  public async checkNftTokenAllowance(collectionAddress: string): Promise<string> {
    const contract = this.wallet.getContract({
      address: collectionAddress,
      abi: rootStore.contracts.params.NFT[getContractType()].abi,
    })

    const result = await contract.methods
      .isApprovedForAll(
        this.walletAddress,
        rootStore.contracts.params.EXCHANGE[getContractType()].address,
      )
      .call()

    return result
  }

  public getAccount(): Promise<IConnect | IError | {address: string}> {
    return this.wallet.getAccounts()
  }

  static getMethodInterface(abi: Array<any>, methodName: string) {
    return abi.filter(m => {
      return m.name === methodName
    })[0]
  }

  public encodeFunctionCall(abi: any, data: Array<any>): string {
    return this.Web3().eth.abi.encodeFunctionCall(abi, data)
  }

  public async createTransaction(
    method: string,
    data: Array<any>,
    contract: ContractParam,
    tx?: any,
    tokenAddress?: string,
    walletAddress?: string,
    value?: any,
  ): Promise<sequence.transactions.TransactionResponse<any> | TransactionReceipt> {
    const transactionMethod = Web3Wallet.getMethodInterface(
      rootStore.contracts.params[contract][getContractType()].abi,
      method,
    )

    const {baseFeePerGas}: any = await this.Web3().eth.getBlock('latest')
    let signature
    if (transactionMethod) {
      signature = this.encodeFunctionCall(transactionMethod, data)
    }

    if (tx) {
      tx.from = walletAddress || this.walletAddress
      tx.data = signature

      return this.sendTransaction(tx)
    }
    return this.sendTransaction({
      from: walletAddress || this.walletAddress,
      to: tokenAddress || rootStore.contracts.params[contract][getContractType()].address,
      data: signature || '',
      value: value || '',
      // gasPrice: baseFeePerGas * 2,
      type: baseFeePerGas ? '0x2' : '0x0',
    })
  }

  public async sendTransaction(
    transactionConfig: any,
  ): Promise<sequence.transactions.TransactionResponse<any> | TransactionReceipt> {
    return this.Web3().eth.sendTransaction({
      ...transactionConfig,
      from: this.walletAddress,
    })
  }

  public async totalSupply(
    tokenAddress: string,
    abi: Array<any>,
    tokenDecimals: number,
  ): Promise<number> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const contract = this.wallet.getContract({
      address: tokenAddress,
      abi,
    })
    const totalSupply = await contract.methods.totalSupply().call()

    return +new BigNumber(totalSupply).dividedBy(new BigNumber(10).pow(tokenDecimals)).toString(10)
  }

  public async checkTokenAllowance(
    contractName: ContractParam,
    amount: number | string,
    tokenDecimals: number,
    exchangeAddress?: string,
    walletAddress?: string,
  ): Promise<boolean> {
    try {
      let approvedMoneyToSpend: string | number = '0'

      const walletAdr = walletAddress || this.walletAddress || ''
      const contract = this.wallet.getContract({
        address: rootStore.contracts.params[contractName][getContractType()].address,
        abi: rootStore.contracts.params[contractName][getContractType()].abi,
      })

      approvedMoneyToSpend = await contract.methods
        .allowance(
          walletAdr,
          exchangeAddress || rootStore.contracts.params[contractName][getContractType()].address,
        )
        .call()

      approvedMoneyToSpend =
        approvedMoneyToSpend === '0'
          ? ''
          : +new BigNumber(approvedMoneyToSpend)
              .dividedBy(new BigNumber(10).pow(tokenDecimals))
              .toString(10)
      if (
        !!approvedMoneyToSpend &&
        new BigNumber(approvedMoneyToSpend).minus(amount).isPositive()
      ) {
        return true
      }
      return false
    } catch (error) {
      console.log('checkTokenAllowanceError', error)
      return false
    }
  }

  public async approveToken(
    contractName: ContractParam,
    amount: number | string,
    tokenDecimals: number,
    exchangeAddress?: string,
    walletAddress?: string,
  ): Promise<unknown> {
    try {
      const approveMethod = Web3Wallet.getMethodInterface(
        rootStore.contracts.params[contractName][getContractType()].abi,
        'approve',
      )

      const {baseFeePerGas}: any = await this.Web3().eth.getBlock('latest')

      const approveSignature = this.encodeFunctionCall(approveMethod, [
        exchangeAddress || walletAddress || this.walletAddress,
        // Wallet.calcTransactionAmount(90071992000.5474099, tokenDecimals),
        Wallet.calcTransactionAmount(amount, tokenDecimals),
      ])

      return this.sendTransaction({
        from: walletAddress || this.walletAddress,
        to: rootStore.contracts.params[contractName][getContractType()].address,
        data: approveSignature,
        type: baseFeePerGas ? '0x2' : '0x0',
      })
    } catch (error) {
      return error
    }
  }
}
