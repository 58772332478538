import cn from 'classnames'

import styles from './InitialLoader.module.scss'

interface IInitialLoader {
  className?: string
  color?: string
}

const InitialLoader: React.FC<IInitialLoader> = ({className, color}) => {
  return (
    <div
      className={cn(styles.loader, className, {
        [styles.loaderWhite]: color === 'white',
      })}
    />
  )
}

export default InitialLoader
