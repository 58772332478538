const getBaseUrl = () => {
  return process.env.REACT_APP_API_URL || 'https://app-sandbox.kephi.io/api/v1/'
}

const Config = {
  baseUrlApi: getBaseUrl(),
  isProduction: process.env.REACT_APP_NODE_ENV === 'production',
}

export default Config
