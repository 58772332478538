import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import activityItemStyles from '../ActivityItem/ActivityItem.module.scss'

const ActivityItemSkeleton = () => {
  return (
    <div className={activityItemStyles.itemNonread}>
      <Skeleton className={activityItemStyles.preview} circle />
      <div className={activityItemStyles.details}>
        <Skeleton className={classNames(activityItemStyles.subtitle)} width="70%" height={20} />
        <Skeleton className={activityItemStyles.description} width="50%" height={15} />
        <Skeleton className={activityItemStyles.date} width="40%" height={10} />
      </div>
    </div>
  )
}

export default ActivityItemSkeleton
