import {types} from 'mobx-state-tree'

export const Contracts = types
  .model({
    decimals: types.number,
    names: types.array(types.string),
    type: types.enumeration(['testnet', 'mainnet']),
    params: types.frozen(),
  })
  .actions(self => {
    const update = (contractsData: any) => {
      self.decimals = contractsData.decimals
      self.names = contractsData.names
      self.type = contractsData.type
      self.params = contractsData.params
    }

    return {update}
  })
