import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import Zoom from 'react-medium-image-zoom'
import {useParams} from 'react-router-dom'
import cn from 'classnames'
import {observer} from 'mobx-react'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardSkeleton from '../../components/Card/Skeleton/CardSkeleton'
import EmptyState from '../../components/EmptyState/EmptyState'
import NavLink from '../../components/NavLink/NavLink'
import {COLLECTION_ITEMS_PER_PAGE, COLLECTION_TAB_INDEX} from '../../constants/constants'
import {storeApi} from '../../services/api'
import {NavLinkType} from '../../types/navLink'
import {ICollection} from '../../typings/UserInfo'

import styles from './Collection.module.scss'

interface ICollectionId {
  collectionId: string
}

const Collection: React.FC = observer(() => {
  const {t} = useTranslation()
  const {collectionId} = useParams<ICollectionId>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [tabChanged, setTabChanged] = useState(false)
  const [page, setPage] = useState(1)
  const [allPages, setAllPages] = useState<number>(1)
  const [tokens, setTokens] = useState<any>([])
  const [collection, setCollection] = useState<ICollection>()
  const navLinks: NavLinkType[] = [
    {id: COLLECTION_TAB_INDEX.ALL, label: t('collectionPage.all')},
    {id: COLLECTION_TAB_INDEX.ON_SALE, label: t('collectionPage.onSale')},
  ]

  const getCriteria = (activeTabIndex: number) => {
    return activeTabIndex === COLLECTION_TAB_INDEX.ON_SALE ? 'on_sale' : ''
  }

  const fetchCollection = useCallback(
    (pageNumber: number, activeTabIndex: number) => {
      setIsLoading(true)
      storeApi
        .getCollection(collectionId, pageNumber, getCriteria(activeTabIndex))
        .then(({data}: any) => {
          if (pageNumber === 1) {
            setCollection(data)
            setTokens(data.tokens)
          } else {
            setTokens((prev: any) => [...prev, ...data.tokens])
          }
          setAllPages(Math.ceil(data.token_count / COLLECTION_ITEMS_PER_PAGE))
        })
        .catch((err: any) => console.log('error', err))
        .finally(() => {
          setIsLoadingMore(false)
          setIsLoading(false)
          if (tabChanged) setTabChanged(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionId],
  )

  const onChangeTab = (index: number) => {
    setTabChanged(true)
    setActiveIndex(index)
    setPage(1)
    setAllPages(1)
  }

  const onLoadingMore = () => {
    setIsLoadingMore(true)
    setPage(page + 1)
  }

  useEffect(() => {
    fetchCollection(page, activeIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activeIndex])

  const cardSkeletons = Array.from({length: COLLECTION_ITEMS_PER_PAGE}, (_value, index) => index)

  return (
    <div className={styles.page}>
      <div className={cn('section', styles.body)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.top}>
            {collection?.avatar ? (
              <>
                <div className={styles.zoomContainer}>
                  <Zoom>
                    <img src={collection.avatar} alt="avatar" className={styles.avatar} />
                  </Zoom>
                </div>
                <span className={styles.name}>{collection.name}</span>
              </>
            ) : (
              <>
                <Skeleton circle className={styles.avatar} />
                <Skeleton className={styles.name} width={200} />
              </>
            )}
          </div>

          <div className={styles.wrapper}>
            <NavLink
              className={styles.nav}
              navLinks={navLinks}
              onClick={(value: any) => onChangeTab(value)}
              activeLink={activeIndex}
            />
          </div>

          <div className={styles.list}>
            {(!isLoading || isLoadingMore) &&
              !!tokens.length &&
              tokens.map((x: any) => <Card className={styles.card} item={x} key={x.id} />)}
            {(isLoading || isLoadingMore) &&
              cardSkeletons.map(cardSkeleton => (
                <CardSkeleton className={styles.card} key={cardSkeleton} />
              ))}
            {!tokens.length && !isLoading && (
              <div className={styles.notokens}>
                <EmptyState />
              </div>
            )}
          </div>
          {(page < allPages || isLoadingMore) && (
            <div className={styles.btns}>
              {!isLoadingMore && (
                <Button
                  onClick={() => onLoadingMore()}
                  className={cn('button-stroke button-small', styles.button)}>
                  <span>{t('load_more')}</span>
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export default Collection
