import classNames from 'classnames'

import InfoPanel from '../InfoPanel/InfoPanel'

import styles from './Successfully.module.scss'

interface ISuccessfullyProps {
  className?: string
  titleText: string
  subtitleText?: string
  infoText?: string
  closeText: string
  onClose: () => void
}

const Successfully: React.FC<ISuccessfullyProps> = ({
  className,
  titleText,
  subtitleText,
  infoText,
  closeText,
  onClose,
}) => {
  return (
    <div className={classNames(className, styles.wrap)}>
      <img src="/images/logos/happy-face.svg" alt="happyFace" />
      <div className={styles.title}>{titleText}</div>
      {subtitleText && <div className={styles.subtitle}>{subtitleText}</div>}
      {infoText && <InfoPanel className={styles.infoPanel} message={infoText} />}
      <button
        className={classNames('button-outlined-gradient', styles.button)}
        type="button"
        onClick={() => onClose()}>
        {closeText}
      </button>
    </div>
  )
}

export default Successfully
