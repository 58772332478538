import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import styles from './EmpowerCreativityBanner.module.scss'

const EmpowerCreativityBanner = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.section}>
      <div className={classNames('container', styles.container)}>
        <div className={styles.wrapper}>
          <div className={styles.title}>{t('home.empowerCreativity.title')}</div>
          <a
            className={classNames('button-outlined-gradient', styles.button)}
            href="https://kephigallery.medium.com/a-year-ends-a-new-roadmap-begins-ec964d6283ef"
            target="_blank"
            rel="noopener noreferrer">
            {t('home.empowerCreativity.joinOurRoadmap')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default EmpowerCreativityBanner
