import useDarkMode from 'use-dark-mode'

interface IImage {
  className?: string
  src: string
  srcDark?: string
  srcSet?: string
  srcSetDark?: string
  alt: string
}

const Image: React.FC<IImage> = ({className, src, srcSetDark, srcSet, alt}) => {
  const darkMode = useDarkMode(true)

  return (
    <img
      className={className}
      srcSet={darkMode.value ? srcSetDark || src : srcSet || src}
      src={src}
      alt={alt}
    />
  )
}

export default Image
