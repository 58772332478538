import React, {useCallback} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import Dropdown from '../../../../components/Dropdown'
import {FilterFormInputs} from '../Filter/Model/FilterForm'
import * as filterForm from '../Filter/Model/FilterForm'

import styles from './MobileFilter.module.scss'

interface IMobileFilterProps {
  className?: string
  onApply: (data: FilterFormInputs) => void
  onReset: () => void
  currentValues: FilterFormInputs
  filterVisible?: boolean
}

const MobileFilter = React.memo<IMobileFilterProps>(
  ({className, onApply, onReset, currentValues, filterVisible}) => {
    const {t} = useTranslation()
    const dateOptions = useCallback(
      () => [
        t('home.discover.filter.recently_added'),
        t('home.discover.filter.long_added'),
        t('home.discover.filter.highest_price'),
        t('home.discover.filter.the_lowest_price'),
        t('home.discover.filter.most_liked'),
        t('home.discover.filter.least_liked'),
      ],
      [t],
    )

    const {handleSubmit, watch, register, control, reset, getValues} = useForm<FilterFormInputs>({
      defaultValues: currentValues,
    })

    const [minPriceValue, maxPriceValue] = watch(['minPrice', 'maxPrice'])

    const handleApply = (data: FilterFormInputs) => {
      onApply({...data, artType: currentValues.artType})
    }

    return (
      <form
        className={classNames(styles.container, className, {[styles.visible]: filterVisible})}
        onSubmit={handleSubmit(handleApply)}>
        <div className={styles.rowContainer}>
          <div className={styles.columnRows}>
            <div className={styles.rowInputs}>
              <div className={styles.inputContainer}>
                <div className={styles.priceLabel}>{t('modalFilter.minPrice')}</div>
                <div className={classNames('button-outlined', styles.inputWrapper)}>
                  <div className={styles.usd}>U$D</div>
                  <input
                    className={styles.input}
                    type="number"
                    min={0}
                    max={maxPriceValue}
                    placeholder="0"
                    id={filterForm.MIN_PRICE}
                    {...register(filterForm.MIN_PRICE)}
                  />
                </div>
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.priceLabel}>{t('modalFilter.maxPrice')}</div>
                <div className={classNames('button-outlined', styles.inputWrapper)}>
                  <div className={styles.usd}>U$D</div>
                  <input
                    className={styles.input}
                    type="number"
                    min={minPriceValue || 0}
                    // max={1000000}
                    placeholder="1000"
                    id={filterForm.MAX_PRICE}
                    {...register(filterForm.MAX_PRICE)}
                  />
                </div>
              </div>
            </div>
            <Controller
              control={control}
              name={filterForm.ORDER_BY}
              render={({field}) => (
                <Dropdown
                  className={styles.dropdown}
                  value={field.value}
                  name={filterForm.ORDER_BY}
                  setValue={field.onChange}
                  options={dateOptions()}
                  placeholder={`${t('modalFilter.ordering')}...`}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.rowButtons}>
          <button
            type="button"
            className={classNames('button-outlined', styles.button)}
            onClick={() => {
              reset({
                artType: 'ALL ITEMS',
                minPrice: '',
                maxPrice: '',
                orderBy: '',
              })
              handleApply(getValues())
              onReset()
            }}>
            {t('reset')}
          </button>
          <button
            type="submit"
            className={classNames(
              'button-outlined-gradient',
              styles.button,
              styles.buttonGradient,
            )}>
            {t('apply')}
          </button>
        </div>
      </form>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.filterVisible === nextProps.filterVisible &&
      JSON.stringify(prevProps.currentValues) === JSON.stringify(nextProps.currentValues)
    )
  },
)

export default MobileFilter
