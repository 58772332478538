import {useEffect, useState} from 'react'
import nextId from 'react-id-generator'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

import Icon from '../Icon'

import styles from './DropdownLanguage.module.scss'

interface IDropdownLanguageProps {
  className?: string
  value: {key: string; label: string}
  setValue: (str: {key: string; label: string}) => void
  options: Array<{key: string; label: string}>
  name?: string

  customClasses?: {
    head?: string
    selection?: string
    arrow?: string
    body?: string
  }
}

const MAX_WIDTH_MOBILE = 767

const DropdownLanguage: React.FC<IDropdownLanguageProps> = ({
  className,
  value,
  setValue,
  options,
  name,
  customClasses = {},
}) => {
  // const { t } = useTranslation();
  const [visible, setVisible] = useState(false)

  const handleClick = (option: {key: string; label: string}) => {
    setValue(option)
    setVisible(false)
  }

  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.dropdown, className, {[styles.active]: visible})} id={name}>
        <div
          onKeyDown={() => {}}
          tabIndex={0}
          role="button"
          className={cn(styles.head, customClasses.head)}
          onClick={() => setVisible(!visible)}>
          <div className={cn(styles.selection, customClasses.selection)}>
            {isMobileWidthResolution ? value.key.toLowerCase() : value.label}
          </div>
          <div className={cn(styles.arrow, customClasses.arrow)}>
            <Icon name="gradientArrow" size="13" isConstructed />
          </div>
        </div>
        <div className={cn(styles.bodyContainer, customClasses.body)}>
          <div className={styles.body}>
            {options.map(option => (
              <div
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
                className={cn(styles.option, {
                  [styles.selectioned]: option.key === value.key,
                })}
                onClick={() => handleClick(option)}
                key={nextId()}>
                <span className={styles.text}>
                  {isMobileWidthResolution ? option.key.toLowerCase() : option.label}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default DropdownLanguage
