const myLocalStorage = (function _() {
  return {
    set(k: string, value: any) {
      localStorage.setItem(k, JSON.stringify(value))
    },
    get(k: string) {
      const data = localStorage[k]

      return (data && JSON.parse(data)) || undefined
    },
    remove(k: string) {
      localStorage.removeItem(k)
    },
  }
})()

export default myLocalStorage
